import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Route } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { IUser } from '../../types/DTOTypes';
import { userService } from '../../services/UserServices';

interface IProps {
  users: IUser[];
  loading: boolean;
  isSuperUser?: boolean;
  onEdit?: Function;
}

interface IState {}

export class UserTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  activeTemplate = (rowData: IUser) => {
    return <div>{rowData.active ? 'Active' : 'Inactive'}</div>;
  };

  linkTemplate = (rowData: IUser) => {
    if (rowData.resetToken) {
      return (
        <div>
          <Link
            to={'/resetPassword/' + rowData.resetToken}
            style={{ display: 'block' }}
          >
            {window.location.origin}/resetPassword/{rowData.resetToken}
          </Link>
        </div>
      );
    } else {
      return <div>No unexpired tokens for this user.</div>;
    }
  };

  resetButtonTemplate = (rowData: IUser) => {
    return (
      <div>
        <Button
          label="Send Reset Email"
          onClick={async () => {
            await userService.requestResetToken(rowData.email);
            if (this.props.onEdit) {
              this.props.onEdit();
            }
          }}
        />
      </div>
    );
  };

  editButtonTemplate = (rowData: IUser) => {
    return (
      <Route
        render={({ history }) => (
          <Button
            label="Edit"
            onClick={() => {
              history.push('/administration/editUser/' + rowData.id);
            }}
          />
        )}
      />
    );
  };

  permissionsButtonTemplate = (rowData: IUser) => {
    return (
      <Route
        render={({ history }) => (
          <Button
            label="View Permissions"
            onClick={() => {
              history.push('/administration/permissions/' + rowData.id);
            }}
          />
        )}
      />
    );
  };

  activeButtonTemplate = (rowData: IUser) => {
    return (
      <div>
        <Button
          label={rowData.active ? 'Deactivate' : 'Activate'}
          onClick={async () => {
            await userService.setUserActivity(rowData.id, !rowData.active);
            if (this.props.onEdit) {
              this.props.onEdit();
            }
          }}
        />
      </div>
    );
  };

  handleChange = <P extends keyof IState>(prop: P, value: IState[P]) => {
    this.setState({ ...this.state, [prop]: value });
  };

  render() {
    const { loading, users, isSuperUser } = this.props;

    return (
      <>
        {loading ? (
          <ProgressSpinner />
        ) : (
          <>
            <DataTable
              id="userTable"
              value={users}
              paginatorPosition="bottom"
              header={<h1 style={{ display: 'table' }}>Users</h1>}
              paginator
              rows={10}
              responsiveLayout="stack"
              autoLayout
              resizableColumns
              emptyMessage="No records found"
              sortMode="multiple"
              loading={loading}
              filterDisplay="row"
            >
              <Column
                header="First Name"
                field="firstName"
                filter
                filterPlaceholder="Filter by First Name"
                filterMatchMode="contains"
              />
              <Column
                header="Middle Name"
                field="middleName"
                filter
                filterPlaceholder="Filter by Middle Name"
                filterMatchMode="contains"
              />
              <Column
                header="Last Name"
                field="lastName"
                filter
                filterPlaceholder="Filter by Last Name"
                filterMatchMode="contains"
              />
              {isSuperUser && (
                <Column
                  header="Email"
                  field="email"
                  filter
                  filterPlaceholder="Filter by Email"
                  filterMatchMode="contains"
                />
              )}
              {isSuperUser && (
                <Column
                  body={this.linkTemplate}
                  header="Password Reset Link"
                  field="resetToken"
                />
              )}
              {isSuperUser && (
                <Column
                  body={this.activeTemplate}
                  header="User Active?"
                  field="active"
                />
              )}
              {isSuperUser && (
                <Column
                  body={this.resetButtonTemplate}
                  header=""
                  field="resetLinkButton"
                />
              )}
              {isSuperUser && (
                <Column
                  body={this.editButtonTemplate}
                  header=""
                  field="editUserButton"
                />
              )}
              {isSuperUser && (
                <Column
                  body={this.permissionsButtonTemplate}
                  header=""
                  field="editUserButton"
                />
              )}
              {isSuperUser && (
                <Column
                  body={this.activeButtonTemplate}
                  header=""
                  field="activeButton"
                />
              )}
            </DataTable>
          </>
        )}
      </>
    );
  }
}
