import React, { Component } from 'react';
import { ViewCertificatePermission } from '../../types/DTOTypes';
import { PermissionsTable } from '../PermissionsTable';

interface IProps {
  permissions: ViewCertificatePermission[];
  loading?: boolean;
  onRemoveSuccess?: () => any;
  onRemoveFailure?: () => any;
}

interface IState {}

export class ViewCertificatePermissionsTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  typeTemplate = (rowData: ViewCertificatePermission) => {
    console.log(rowData);
    if (rowData.providerId !== null) {
      return <div style={{ textAlign: 'center' }}>Provider</div>;
    }
    return <div style={{ textAlign: 'center' }}>Department</div>;
  };

  nameTemplate = (rowData: ViewCertificatePermission) => {
    if (rowData.providerId !== null) {
      return (
        <div style={{ textAlign: 'center' }}>
          {rowData.providerFullNameWDegrees}
        </div>
      );
    }
    return (
      <div style={{ textAlign: 'center' }}>
        {rowData.departmentName} at {rowData.siteName} (
        {rowData.institutionName})
      </div>
    );
  };

  render() {
    const { loading, permissions, onRemoveSuccess, onRemoveFailure } =
      this.props;

    return (
      <PermissionsTable
        id="viewCertificate"
        header="ViewCertificate Permissions"
        permissions={permissions}
        nameTemplate={this.nameTemplate}
        typeTemplate={this.typeTemplate}
        onRemoveSuccess={onRemoveSuccess}
        onRemoveFailure={onRemoveFailure}
        loading={loading}
      />
    );
  }
}
