import { ACConfig } from './ACConfig';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IDepartment, IInstitution, ISite } from '../types/DTOTypes';

export const institutionService = {
  addDepartment,
  addDocumentType,
  addInstitution,
  addSite,
  fetchDocumentTypes,
  getDepartment,
  getDepartments,
  getDepartmentsForInstitution,
  getInstitutionById,
  getInstitutions,
  getSite,
  getSites,
  getSitesForInstitution,
  updateDepartment,
  updateInstitution,
  updateSite,
};

async function addDepartment(name: string, institution: IInstitution) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  const departmentDTO = {
    name: name,
    institutionId: institution.id,
  };
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + x.token,
    },
    data: JSON.stringify(departmentDTO),
  };

  try {
    await axios(ACConfig.apiUrl + '/ArchiveCore/addDepartment', requestOptions);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function addDocumentType(
  name: string,
  shortName: string
): Promise<AxiosResponse<string>> {
  const user = JSON.parse(sessionStorage.getItem('user') || '');

  const documentTypeDTO = {
    name: name,
    shortName: shortName,
  };

  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.token,
    },
    data: JSON.stringify(documentTypeDTO),
  };

  const response = await axios(
    ACConfig.apiUrl + '/ArchiveCore/addDocumentType',
    requestOptions
  ).catch((error: AxiosError) => {
    console.error('/ArchiveCore/addDocumentType', error);
    if (!error.response) {
      throw error;
    }
    return error.response;
  });

  return response;
}

async function addInstitution(name: string, taxID: string) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  const institutionDto = {
    name: name,
    taxID: taxID,
  };
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + x.token,
    },
    data: JSON.stringify(institutionDto),
  };

  return await axios(
    ACConfig.apiUrl + '/ArchiveCore/institutions',
    requestOptions
  ).catch((error: AxiosError) => {
    console.error(error);
    return error.response ? error.response : null;
  });
}

async function addSite(
  name: string,
  state: string,
  city: string,
  zipcode: string,
  institution: IInstitution
) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  const departmentDTO = {
    name: name,
    state: state,
    city: city,
    zipCode: zipcode,
    institutionId: institution.id,
  };
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + x.token,
    },
    data: JSON.stringify(departmentDTO),
  };

  try {
    await axios(ACConfig.apiUrl + '/ArchiveCore/addSite', requestOptions);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function fetchDocumentTypes() {
  let user = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'get',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + user.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/fetchDocumentTypes',
    });

    return response.data;
  } catch (error) {
    return false;
  }
}

async function getDepartment(id: string | number) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/departments/' + id,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function getDepartments() {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/departments',
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getDepartmentsForInstitution(id: string | number) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/institutions/' + id + '/departments',
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getInstitutions() {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/institutions',
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getInstitutionById(id: string | number) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'get',
      headers: {
        mode: 'cors',
        'Access-control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/institutions/' + id,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function getSite(id: string | number) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/sites/' + id,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function getSites() {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/sites',
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getSitesForInstitution(id: string | number) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/institutions/' + id + '/sites',
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function updateDepartment(department: IDepartment) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + x.token,
    },
    data: JSON.stringify(department),
  };

  try {
    await axios(
      ACConfig.apiUrl + '/ArchiveCore/updateDepartment',
      requestOptions
    );
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function updateInstitution(institution: IInstitution) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  const requestOptions: AxiosRequestConfig = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + x.token,
    },
    data: JSON.stringify(institution),
  };

  return await axios(
    ACConfig.apiUrl + '/ArchiveCore/institutions',
    requestOptions
  ).catch((error: AxiosError) => {
    if (error.response) {
      return error.response;
    }
    return null;
  });
}

async function updateSite(site: ISite) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + x.token,
    },
    data: JSON.stringify(site),
  };

  try {
    await axios(ACConfig.apiUrl + '/ArchiveCore/updateSite', requestOptions);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
