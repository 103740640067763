import {
  AutoComplete,
  AutoCompleteChangeParams,
} from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Component, RefObject } from 'react';
import { authService } from '../../services/AuthServices';
import {
  documentSigningService,
  DOCUMENT_SIGNING_COMPLETED,
} from '../../services/DocumentSigningServices';
import { providerService } from '../../services/ProviderServices';
import { DocumentType, IProvider } from '../../types/DTOTypes';
import { IRegisterForm } from '../../types/RegisterForm';
import { ISiteDeptPair, NO_SITE_DEPT } from '../../types/SiteDeptPair';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';

interface IProps {
  ref?: RefObject<CompleteForm>;
  documentTypeList: DocumentType[];
  registerFormList: IRegisterForm[];
  selectedSiteDeptPair?: ISiteDeptPair | null;
  handleFormChange: <P extends keyof IRegisterForm>(
    form: IRegisterForm,
    keyValuePairs: { [key in P]: IRegisterForm[P] },
    callback?: () => void
  ) => void;
  onContinue: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
}

interface IState {
  providerAutoFill?: IProvider[];
  expandedRows: DataTableExpandedRows;
  providerList?: IProvider[];
}

export class CompleteForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      providerAutoFill: undefined,
      expandedRows: {},
      providerList: undefined,
    };
  }

  componentDidMount = async () => {
    const { selectedSiteDeptPair } = this.props;
    const selectedSite =
      !selectedSiteDeptPair?.site || selectedSiteDeptPair.site.id < 0
        ? null
        : selectedSiteDeptPair.site;
    const providerList: IProvider[] =
      (await providerService.getProvidersToRegister(selectedSite)) ?? [];
    // if there's exactly one available provider, autofill all the provider fields with that provider
    if (providerList && providerList.length === 1) {
      const provider = providerList[0];
      this.onProviderChange(
        { value: provider } as AutoCompleteChangeParams,
        this.props.registerFormList[0]
      );
    }
    this.setState(
      {
        providerList,
      },
      () => this.onToggleExpansion()
    );
  };

  initiateDocumentSigning = (form: IRegisterForm) => {
    this.props.handleFormChange(form, { envelopeId: '' }, () => {
      documentSigningService.getSigningLink(form.file).then((response) => {
        this.props.handleFormChange(form, {
          envelopeId: response['item1'],
          envelopeLink: response['item2'],
        });
      });
    });
  };

  cancelDocumentSigning = (form: IRegisterForm) => {
    this.props.handleFormChange(form, {
      envelopeId: null,
      envelopeLink: null,
      isSignatureComplete: false,
    });
  };

  updateEnvelopes = async () => {
    const { registerFormList, handleFormChange } = this.props;
    for (const form of registerFormList) {
      if (!form.envelopeId) {
        continue;
      }

      const response = await documentSigningService.getEnvelope(
        form.envelopeId
      );

      const completed = response.status === DOCUMENT_SIGNING_COMPLETED;

      form.isSignatureComplete = completed;
      handleFormChange(form, {
        isSignatureComplete: completed,
      });
    }
  };

  /// uses recursion to update every registerFormList with new provider
  /// index: the current index of registerFormList to update (increases)
  /// provider: the provider to update with
  /// replaceProviders: whether or not to replace existing provider data, if it exists
  ///     - if true, will ignore existing data and enter new provider for any form
  ///     - if false, will simply skip over forms where provider info exists
  recursiveUpdateProvider = (
    index: number,
    provider: IProvider,
    replaceProviders: boolean
  ) => {
    const form = this.props.registerFormList[index];
    if (!form) {
      return;
    }
    if (form.selectedProvider && !replaceProviders) {
      this.recursiveUpdateProvider(index + 1, provider, replaceProviders);
    }
    this.props.handleFormChange(
      form,
      {
        selectedProvider: provider,
        selectedDegree: provider.degrees[0],
        selectedPosition: provider.position,
      },
      () => this.recursiveUpdateProvider(index + 1, provider, replaceProviders)
    );
  };

  onProviderChange = (e: AutoCompleteChangeParams, data: IRegisterForm) => {
    const { handleFormChange } = this.props;
    const provider: IProvider = e.value;

    // Set provider for this form and all forms where provider is undefined
    // (in case user wants to upload multiple documents for the same provider)
    if (provider.id !== undefined) {
      this.recursiveUpdateProvider(0, provider, false);
    } else {
      handleFormChange(data, {
        selectedProvider: provider,
        selectedDegree: null,
        selectedPosition: null,
      });
    }
  };

  // Autocomplete functionality for selecting providers
  providerAutoComplete = (event: { query: string }) => {
    const { providerList } = this.state;

    if (providerList === undefined) {
      console.warn('Missing this.state.providerList data');
      return;
    }

    const query: string = event.query;

    // filter the providerlist by user input
    const results = providerList.filter((provider: IProvider) => {
      return (
        provider.fullNameWDegrees.toLowerCase().includes(query.toLowerCase()) ||
        provider.npi?.includes(query) ||
        provider.uuid.includes(query)
      );
    });

    // set the autofill options to the providers filtered
    this.setState({
      providerAutoFill: results,
    });
  };

  // check all forms, returning true only if all of them are complete
  checkAllFormsFilledOut = () => {
    if (
      this.props.selectedSiteDeptPair === undefined ||
      this.props.registerFormList.length === 0
    ) {
      return false;
    }
    // Try to find a form which is not complete
    const unfilledForm = this.props.registerFormList.find((form) => {
      return (
        !form.selectedProvider ||
        !form.selectedPosition ||
        !form.selectedDegree ||
        !form.selectedDocumentType ||
        (form.envelopeId !== null && !form.isSignatureComplete)
      );
    });
    // If there is a form which is not complete, return false
    return !unfilledForm;
  };

  documentSigningTemplate = (data: IRegisterForm) => {
    if (!data.file.name.endsWith('.pdf')) {
      return;
    }
    return (
      <>
        <Button
          label={data.isSignatureComplete ? 'Signed' : 'Sign'}
          icon={
            data.envelopeId === '' ? 'pi pi-spin pi-spinner' : 'pi pi-pencil'
          }
          iconPos="right"
          onClick={() => {
            this.initiateDocumentSigning(data);
          }}
          disabled={data.envelopeId !== null}
          tooltip="Click to begin signing process"
        />
        {data.envelopeId && !data.isSignatureComplete && (
          <Button
            className="p-button-secondary"
            label="Open Link"
            icon="pi pi-external-link"
            iconPos="left"
            disabled={!data.envelopeLink}
            onClick={() => {
              if (!data.envelopeLink) {
                return;
              }
              window.open(data.envelopeLink, '_blank');
            }}
            tooltip="Click to open the signing link in another tab"
          />
        )}
        {data.envelopeId && !data.isSignatureComplete && (
          <Button
            className="p-button-secondary"
            label="Cancel"
            icon="pi pi-times"
            iconPos="left"
            onClick={() => {
              this.cancelDocumentSigning(data);
            }}
            tooltip="Click to cancel the signing process for this document"
          />
        )}
      </>
    );
  };

  onToggleExpansion = () => {
    const isAllRowsExpanded =
      Object.keys(this.state.expandedRows).length ===
      this.props.registerFormList.length;
    const newRows: DataTableExpandedRows = {};
    if (!isAllRowsExpanded) {
      for (const form of this.props.registerFormList) {
        newRows[form.file.name] = true;
      }
    }
    this.setState({ expandedRows: newRows });
  };

  toggleExpansionButton = () => {
    const isAllRowsExpanded =
      Object.keys(this.state.expandedRows).length ===
      this.props.registerFormList.length;
    return (
      <Button
        className="p-button-primary"
        label={isAllRowsExpanded ? 'Collapse All' : 'Expand All'}
        icon={isAllRowsExpanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'}
        iconPos="left"
        onClick={() => this.onToggleExpansion()}
      />
    );
  };

  // A template for the expanded row of a CompleteProviders table
  rowExpansionTemplateCompleteProviders = (data: IRegisterForm) => {
    const {
      selectedProvider,
      selectedDegree,
      selectedPosition,
      selectedDocumentType,
      description,
    } = data;
    const { providerAutoFill, providerList } = this.state;
    const { documentTypeList, handleFormChange } = this.props;
    const disabled = data.selectedProvider?.id === undefined;

    return (
      <div className="grid p-fluid" style={{ margin: '10px' }}>
        <div className="grid">
          <div className="col-4 dataHeader">Provider: </div>
          <div className="col-8 dataBody">
            <AutoComplete
              id="provider"
              value={selectedProvider}
              onChange={(e) => {
                this.onProviderChange(e, data);
              }}
              suggestions={providerAutoFill || []}
              completeMethod={this.providerAutoComplete}
              field="fullNameWDegrees"
              delay={50}
              tooltip="Provider"
              placeholder="Name/NPI/UUID"
              style={{ marginBottom: '15px', marginRight: '10px' }}
              disabled={
                providerList !== undefined &&
                providerList.length <= 1 &&
                selectedProvider !== null
              }
            />
          </div>
          <div className="col-4 dataHeader">NPI: </div>
          <div className="col-8 dataBody">{selectedProvider?.npi ?? 'N/A'}</div>
          <div className="col-4 dataHeader">Degree: </div>
          <div className="col-8 dataBody">
            <Dropdown
              optionLabel="degreeName"
              options={selectedProvider?.degrees}
              dataKey="id"
              value={selectedDegree}
              disabled={disabled}
              placeholder="Select a Degree"
              onChange={(e) =>
                handleFormChange(data, { selectedDegree: e.target.value })
              }
            />
          </div>
          <div className="col-4 dataHeader">Position: </div>
          <div className="col-8 dataBody">
            <InputText
              id="position"
              value={selectedPosition}
              disabled={disabled}
              placeholder="Select a Position"
              onChange={(e) =>
                handleFormChange(data, { selectedPosition: e.target.value })
              }
            />
          </div>
          <div className="col-4 dataHeader">
            Document Type:{' '}
            <Tooltip
              target=".document-type-tooltip"
              style={{ maxWidth: '20rem' }}
            />
            <i
              className="document-type-tooltip pi pi-question-circle"
              data-pr-tooltip="
                If you need a document type that
                isn't on this list, please email 
                us at solutions@archivecore.com
                to let us know.
              "
              data-pr-position="right"
              data-pr-my="left center-2"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="col-8 dataBody">
            <Dropdown
              id="docType"
              optionLabel="name"
              options={documentTypeList}
              value={selectedDocumentType}
              disabled={disabled}
              placeholder="Select Document Type"
              onChange={(e) =>
                handleFormChange(data, {
                  selectedDocumentType: e.target.value,
                })
              }
            />
          </div>
          <div className="col-4 dataHeader">
            Description:{' '}
            <Tooltip
              target=".description-tooltip"
              style={{ maxWidth: '20rem' }}
            />
            <i
              className="description-tooltip pi pi-question-circle"
              data-pr-tooltip='
                Adding a description can be helpful if you have multiple documents of the
                same type. For example, if you are adding a PROCEDURE LOG, you might add "Central
                Lines May 2023". For LIFE SUPPORT TRAINING, you might add "PALS May 2023" or "ACLS
                May 2023".
              '
              data-pr-position="right"
              data-pr-my="left center-2"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="col-8 dataBody">
            <InputText
              id="description"
              value={description}
              disabled={disabled}
              placeholder="Enter a Description (optional)"
              onChange={(e) =>
                handleFormChange(data, { description: e.target.value })
              }
            />
          </div>
          <div className="col-4 dataHeader">
            Sign Document?{' '}
            <Tooltip target=".sign-tooltip" style={{ maxWidth: '20rem' }} />
            <i
              className="sign-tooltip pi pi-question-circle"
              data-pr-tooltip='
                This feature is only available for PDF documents. If you choose to 
                add a signature to a document, this will take you to a DocuSign link in another tab. 
                After signing, return and click the "Refresh" button below.
              '
              data-pr-position="right"
              data-pr-my="left center-2"
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="col-8 dataBody">
            {this.documentSigningTemplate(data)}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { registerFormList, selectedSiteDeptPair, onCancel, onContinue } =
      this.props;
    const { expandedRows, providerList } = this.state;

    const headerText =
      selectedSiteDeptPair !== NO_SITE_DEPT
        ? `Register Documents for ${selectedSiteDeptPair?.name}`
        : 'Register Documents Not Associated With Any Site';

    if (providerList === undefined) {
      return (
        <div className="user-card card">
          <ProgressSpinner />
        </div>
      );
    }

    return (
      <div className="user-card card">
        <h1>{headerText}</h1>
        {(!providerList || providerList.length <= 0) && (
          <>There are no available providers at this Site!</>
        )}
        {providerList && providerList.length > 0 && (
          <DataTable
            value={registerFormList}
            sortMode="multiple"
            emptyMessage="No files found!"
            expandedRows={expandedRows}
            onRowExpand={(e) => {
              this.setState({
                expandedRows: { [e.data.file.name]: true },
              });
            }}
            rowExpansionTemplate={this.rowExpansionTemplateCompleteProviders}
            dataKey="file.name"
          >
            <Column
              expander
              style={{ width: '2em', paddingRight: '2em' }}
              header={<this.toggleExpansionButton />}
            />
            <Column
              field="file.name"
              header="Name"
              sortable
              filter
              filterPlaceholder="Filter by filename"
              filterMatchMode="contains"
            />
          </DataTable>
        )}
        <br />
        <Button
          className="p-button-secondary"
          label="Back"
          icon="pi pi-arrow-left"
          iconPos="left"
          onClick={onCancel}
        />
        <Button
          label="Continue"
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={onContinue}
          disabled={!this.checkAllFormsFilledOut()}
        ></Button>
        <Button
          label="Refresh"
          icon="pi pi-sync"
          iconPos="right"
          onClick={() => {
            this.updateEnvelopes();
          }}
          tooltip="Click to update the signing process"
        ></Button>
      </div>
    );
  }
}
