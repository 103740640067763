import React, { FunctionComponent } from 'react';
import LinkButton, { Icons } from '../LinkButton';

const AdminBackButton: FunctionComponent = () => (
  <LinkButton
    to={'/administration'}
    label={'Back To Administration'}
    icon={Icons.arrowLeft}
  />
);

export default AdminBackButton;
