import React, { createRoot } from 'react-dom/client';
import AppWrapper from './app/AppWrapper';
import { BrowserRouter as Router } from 'react-router-dom';
//import * as serviceWorker from './serviceWorker';
import 'babel-polyfill';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'font-awesome/css/font-awesome.css';
import 'react-datetime/css/react-datetime.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Router>
    <AppWrapper />
  </Router>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
