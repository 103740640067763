import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { providerService } from '../../services/ProviderServices';
import { FileUpload, FileUploadHandlerParam } from 'primereact/fileupload';
import { AddProvidersResult, IProvider } from '../../types/DTOTypes';
import { archiveCoreService } from '../../services/ArchiveCoreServices';
import { ProviderTable } from '../ProviderTable';
import { AxiosResponse } from 'axios';

interface IProps {
  onSuccess?: Function;
  onFailure?: Function;
}

interface IState {
  statusCode?: number;
  providerList: IProvider[];
  errorList: {
    inputLine: string;
    errorMessage: string;
  }[];
}

export class AddProviderCSV extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      providerList: [],
      errorList: [],
    };
  }

  fileUploadComponent: any;

  handleChange = <P extends keyof IState>(prop: P, value: IState[P]) => {
    this.setState({ ...this.state, [prop]: value });
  };

  handleSendTemplate = () => {
    const file = new File(
      [
        'FirstName,MiddleName,LastName,NPI,Position,Degree\nJane,Lynn,Doe,0123456789,Attending,DO\nJack,,Smith,,Attending,DO',
      ],
      'example.csv',
      { type: 'text/csv;charset=utf-8;' }
    );
    archiveCoreService.sendFile(file, file.name);
  };

  handleUpload = async (event: FileUploadHandlerParam) => {
    console.assert(
      event.files.length === 1,
      'We expect exactly one CSV File for upload.'
    );

    const response: AxiosResponse<AddProvidersResult> =
      await providerService.addProvidersFromCSV(event.files[0]);

    this.fileUploadComponent.clear();

    if (response.status !== 200) {
      console.error('providerService.addProvidersFromCSV()', response);

      const errorMessage: string =
        response?.data?.toString?.() ??
        'Make sure all lines of input follow this format: FirstName,MiddleName,LastName,NPI,Position,Degree';

      this.setState({
        providerList: [],
        errorList: [
          {
            inputLine: 'ERROR WITH FILE:',
            errorMessage,
          },
        ],
      });
      return;
    }

    const providersResult: AddProvidersResult = response.data;
    const providerList: IProvider[] = providersResult.newProviders;
    const errorList: {
      inputLine: string;
      errorMessage: string;
    }[] = providersResult.errors;

    if (errorList.length > 0) {
      this.props.onFailure?.();
    } else if (providerList.length > 0) {
      this.props.onSuccess?.();
    } else {
      this.props.onFailure?.();
    }

    this.setState({
      providerList,
      errorList,
    });
  };

  ErrorTable = () => {
    const rows: JSX.Element[] = [];

    this.state.errorList.forEach((error) => {
      rows.push(
        <React.Fragment key={error.inputLine}>
          <div className="p-col-4 dataHeader">{error.inputLine}</div>
          <div className="p-col-8 dataBody">{error.errorMessage}</div>
        </React.Fragment>
      );
    });

    return (
      <div className="card">
        <h2>⚠️ Uploading from spreadsheet generated the following errors ⚠️</h2>
        {rows}
      </div>
    );
  };

  render() {
    return (
      <>
        <div style={{ display: 'inline-block' }}>
          <FileUpload
            name="csv"
            mode="basic"
            accept=".csv,.txt"
            auto
            chooseLabel="Upload Providers from a Spreadsheet"
            ref={(ref) => {
              this.fileUploadComponent = ref;
            }}
            customUpload={true}
            uploadHandler={this.handleUpload}
          />
        </div>
        <div style={{ display: 'inline-block' }}>
          <Button
            label="Download Example Spreadsheet"
            icon="pi pi-download"
            onClick={this.handleSendTemplate}
          />
        </div>
        {this.state.errorList.length > 0 && <this.ErrorTable />}
        {this.state.providerList.length > 0 && (
          <ProviderTable
            providers={this.state.providerList}
            header={
              <h1 style={{ display: 'table' }}>Providers Added From CSV:</h1>
            }
          />
        )}
      </>
    );
  }
}
