import { Fieldset } from 'primereact/fieldset';
import { Component } from 'react';
import { FloatLabel } from '../FloatLabel';
import { Message } from 'primereact/message';
import { BirthDateCalendar } from '../BirthDateCalendar';
import { InputText } from 'primereact/inputtext';
import { ISite } from '../../types/DTOTypes';
import { MultiSelect } from 'primereact/multiselect';

export interface ProviderData {
  NPI: string;
  position: string;
  degree: string;
  birthDate: Date | Date[] | undefined;
  sites: ISite[];
  valid: boolean;
}

export const EmptyProviderData: ProviderData = {
  NPI: '',
  position: '',
  degree: '',
  birthDate: undefined,
  sites: [],
  valid: false,
};

interface IProps {
  providerData: ProviderData | null;
  sites: ISite[];
  setProviderData: (providerData: ProviderData | null) => void;
}

interface IState {
  npiError: string;
}

export class AddProviderDetails extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      npiError: '',
    };
  }

  componentDidMount(): void {
    this.onExpand();
  }

  validate = (providerData: ProviderData): boolean => {
    this.setState({ npiError: '' });
    if (!providerData) {
      return false;
    }
    const NPI = providerData.NPI.trim();
    const position = providerData.position.trim();
    const degree = providerData.degree.trim();
    if (NPI.length > 0 && NPI.length !== 10) {
      this.setState({ npiError: 'NPI must be 10 digits!' });
      return false;
    }
    if (position.length <= 0 || degree.length <= 0) {
      return false;
    }
    if (!(providerData.birthDate instanceof Date)) {
      return false;
    }
    return true;
  };

  handleChange = (
    name: keyof ProviderData,
    value: ProviderData[keyof ProviderData]
  ) => {
    if (!this.props.providerData) {
      return;
    }
    const providerData: ProviderData = {
      ...this.props.providerData,
      [name]: value,
    };
    providerData.valid = this.validate(providerData);
    this.props.setProviderData(providerData);
  };

  onExpand = () => {
    this.props.setProviderData(EmptyProviderData);
  };

  onCollapse = () => {
    this.props.setProviderData(null);
  };

  render() {
    const providerData = this.props.providerData ?? EmptyProviderData;
    return (
      <Fieldset
        style={{ display: 'block', marginTop: '20px' }}
        legend={
          providerData ? 'Remove Provider Details' : 'Add Provider Details'
        }
        toggleable
        onExpand={this.onExpand}
        onCollapse={this.onCollapse}
      >
        <FloatLabel label="NPI (optional)">
          <InputText
            id="NPI"
            aria-label="NPI (optional)"
            value={providerData?.NPI}
            onChange={(event) => this.handleChange('NPI', event.target.value)}
            keyfilter={'pint'}
            maxLength={10}
          />
        </FloatLabel>
        <FloatLabel label="Position">
          <InputText
            id="position"
            aria-label="Position"
            value={providerData?.position}
            onChange={(event) =>
              this.handleChange('position', event.target.value)
            }
          />
        </FloatLabel>
        <FloatLabel label="Degree">
          <InputText
            id="degree"
            aria-label="Degree"
            value={providerData?.degree}
            onChange={(event) =>
              this.handleChange('degree', event.target.value)
            }
          />
        </FloatLabel>
        <FloatLabel label="Birth Date">
          <BirthDateCalendar
            id="birthDate"
            aria-label="Birth Date"
            value={providerData?.birthDate ?? undefined}
            onChange={(event) => this.handleChange('birthDate', event.value)}
          />
        </FloatLabel>
        <div style={{ display: 'inline-block' }}>
          {this.state.npiError && (
            <Message
              severity="error"
              text={this.state.npiError}
              style={{ marginLeft: '8px' }}
            />
          )}
        </div>
        <br />
        <MultiSelect
          filter
          filterBy="name"
          dataKey="id"
          display="chip"
          tooltip="Site(s)"
          placeholder="Select Site(s)"
          options={this.props.sites}
          optionLabel="name"
          style={{ maxWidth: '46.5rem' }}
          value={providerData.sites}
          onChange={(e) => this.handleChange('sites', e.value)}
        />
      </Fieldset>
    );
  }
}
