import { ACConfig } from './ACConfig';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';
//@ts-ignore
import CryptoJS from 'crypto-js';
import {
  IProvider,
  ISite,
  ChainData,
  ProcessInputFileResult,
  UserRegistrationInput,
} from '../types/DTOTypes';

export const archiveCoreService = {
  fetchTopTenTransactions,
  fetchVerificationFile,
  fileHashing,
  getChain,
  getFile,
  getTransactionStatus,
  listingObjectsAsync,
  processInputFile,
  pushToBlockChain,
  queryBlockChainInfo,
  registrationHashing,
  removeDocumentStorage,
  sendFile,
  uploadDocument,
};

/*==========================================================================*/

async function listingObjectsAsync(providerId: number): Promise<string[]> {
  console.assert(
    Number.isInteger(providerId) && providerId > 0,
    '🔢 we expect a provider ID (positive integer)',
    providerId
  );

  const user = JSON.parse(sessionStorage.getItem('user') || '');

  const response = await axios({
    method: 'get',
    headers: { Authorization: 'Bearer ' + user.token },
    url: ACConfig.apiUrl + '/ArchiveCore/listingObjectsAsync/' + providerId,
  });

  const data = response.data;

  console.assert(
    Array.isArray(data),
    '✨ we expect the data from the server to be an array',
    data
  );
  console.assert(
    data.every((item: any) => typeof item === 'string'),
    '✨ we expect the array from the server to contain strings',
    data
  );

  return data;
}

/*==========================================================================*/

function getFile(key: string) {
  const user = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    return axios({
      method: 'get',
      headers: { Authorization: 'Bearer ' + user.token },
      url: ACConfig.apiUrl + '/ArchiveCore/getFile/' + key,
    });
  } catch (error) {
    return false;
  }
}

/*==========================================================================*/

async function uploadDocument(key: string, file: string | Blob) {
  let user = JSON.parse(sessionStorage.getItem('user') || '');
  const formData = new FormData();
  formData.append('file', file);
  formData.append('key', key);

  return await axios({
    url: ACConfig.apiUrl + '/ArchiveCore/uploadDocument/',
    headers: {
      Authorization: 'Bearer ' + user.token,
    },
    method: 'post',
    data: formData,
  }).catch((error: AxiosError) => {
    if (!error.response) {
      return null;
    }
    return error.response;
  });
}

/*==========================================================================*/

async function fileHashing(file: File): Promise<string> {
  try {
    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onloadend = (event) => {
        var data = event?.target?.result;

        var hashIt: CryptoJS.lib.WordArray = CryptoJS.SHA256(
          CryptoJS.enc.Latin1.parse(data)
        );

        resolve(hashIt.toString());
      };
      reader.readAsBinaryString(file);
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/*==========================================================================*/

function registrationHashing(fileHash: string): string {
  try {
    // const hash = crypto.createHash('sha256');
    // hash.update(fileHash);
    //
    // return hash.digest('hex');
    return CryptoJS.SHA256(fileHash).toString(CryptoJS.enc.Hex);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/*==========================================================================*/

async function removeDocumentStorage(key: string) {
  const user = JSON.parse(sessionStorage.getItem('user') || '');

  const formData = new FormData();
  formData.append('key', key);
  const requestOptions: AxiosRequestConfig = {
    method: 'DELETE',
    headers: { Authorization: 'Bearer ' + user.token },
    data: formData,
  };

  return await axios(
    ACConfig.apiUrl + `/ArchiveCore/files`,
    requestOptions
  ).catch((error: AxiosError) => {
    console.error(error);
    if (error.response === undefined) {
      throw error;
    }
    return error.response;
  });
}

/*==========================================================================*/

async function processInputFile(
  file: File,
  thisProvider: IProvider,
  userInput: UserRegistrationInput,
  regVersion: string,
  site: ISite | null,
  selectedPosition: string
): Promise<ProcessInputFileResult> {
  try {
    const user = JSON.parse(sessionStorage.getItem('user') || '');
    const file_hash = await fileHashing(file);
    const current_datetime = new Date(Date.now());
    const formatted_date = current_datetime.toISOString();
    const documentShort = userInput.documentType.name.replace(/\s/g, '');
    const parts = file.name.split('.');
    const ext = parts[parts.length - 1];
    const middleName = thisProvider.middleName ? thisProvider.middleName + '_' : '';
    const npi = thisProvider.npi ? thisProvider.npi + '_' : '';

    const newFileName =
      thisProvider.lastName + '_' +
      thisProvider.firstName + '_' +
      middleName +
      npi +
      formatted_date + '_' +
      selectedPosition + '_' +
      documentShort + '.' +
      ext;

    const registration_inf =
      file_hash +
      regVersion +
      thisProvider.fullNameWDegrees +
      thisProvider.position +
      user.firstName +
      ' ' +
      user.lastName +
      (site?.name ?? 'NO-SITE') +
      newFileName +
      userInput.documentType.name +
      userInput.description +
      formatted_date;

    const regHash = registrationHashing(registration_inf);

    const fileSpecsOut: ProcessInputFileResult = {
      newFileName: newFileName,
      currentTimeStamp: formatted_date,
      fileHash: file_hash,
      registrationHash: regHash.toString(),
      registrationString: registration_inf,
    };

    return fileSpecsOut;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/*==========================================================================*/

async function sendFile(file: File, thisFileName: string) {
  try {
    fileDownload(file, thisFileName);
    return true;
  } catch (error) {
    return false;
  }
}

/*==========================================================================*/

export interface IBlockChainInput {
  providerId: number;
  submitterId: number;
  siteId: number | undefined;
  fileName: string;
  documentTypeId: number;
  fileHash: string;
  submitTime: string;
  registrationHash: string;
  description: string;
  version: string;
  site: ISite | undefined;
  degreeId: number;
  departmentId: number | undefined;
  providerPosition: string;
  registrationString: string;
}

async function pushToBlockChain(
  blockChainInput: IBlockChainInput,
  email: string
) {
  const user = JSON.parse(sessionStorage.getItem('user') || '');
  const response = await axios({
    method: 'post',
    headers: {
      mode: 'cors',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + user.token,
    },
    url: ACConfig.apiUrl + '/ArchiveCore/pushToBlockChain',
    data: {
      description: blockChainInput.description,
      documentTypeId: blockChainInput.documentTypeId,
      fileHash: blockChainInput.fileHash,
      fileName: blockChainInput.fileName,
      providerId: blockChainInput.providerId,
      registrationHash: blockChainInput.registrationHash,
      siteId: blockChainInput.siteId,
      submitTime: blockChainInput.submitTime,
      submitterId: blockChainInput.submitterId,
      version: blockChainInput.version,
      degreeId: blockChainInput.degreeId,
      departmentId: blockChainInput.departmentId,
      providerPosition: blockChainInput.providerPosition,
      registrationString: blockChainInput.registrationString,
      email: email,
    },
  });
  return response.data;
}

/*==========================================================================*/

async function fetchVerificationFile(file: File) {
  try {
    const user = JSON.parse(sessionStorage.getItem('user') || '');
    var file_hash: any = '';

    try {
      file_hash = await fileHashing(file);
    } catch (error) {
      console.log(error);
      return false;
    }

    let fileName = file.name;

    const response = await axios({
      method: 'post',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + user.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/fetchVerificationFile',
      data: {
        fileName: fileName,
        fileHash: file_hash,
      },
    });

    return response.data;
  } catch (error) {
    return false;
  }
}

/*==========================================================================*/

async function queryBlockChainInfo(transHash: string) {
  try {
    const user = JSON.parse(sessionStorage.getItem('user') || '');

    const response = await axios({
      method: 'post',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + user.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/queryBlockChainAsync',
      data: {
        transactionHash: transHash,
      },
    });
    return response.data;
  } catch (error: any) {
    return error.response.status;
  }
}

/*==========================================================================*/

async function getTransactionStatus(transHash: string) {
  try {
    const user = JSON.parse(sessionStorage.getItem('user') || '');

    const response = await axios({
      method: 'post',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + user.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/getTransactionStatus',
      data: {
        transactionHash: transHash,
      },
    });
    return response.data;
  } catch (error: any) {
    return error.response.status;
  }
}

/*==========================================================================*/

async function fetchTopTenTransactions() {
  const user = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'get',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + user.token,
      },
      url: ACConfig.apiUrl + '/ArchiveCore/fetchTopTenTransactions',
      data: {
        Id: user.id,
      },
    });

    return response.data;
  } catch (error) {
    return false;
  }
}

/*==========================================================================*/

async function getChain(): Promise<ChainData> {
  try {
    const response = await axios({
      method: 'GET',
      url: ACConfig.apiUrl + '/ArchiveCore/getChain',
    });

    const chainName: string = response.data;

    const isMainNet = chainName.toLowerCase().trim() === 'mainnet';

    const chainData: ChainData = {
      chainName,
      isMainNet,
    };

    return chainData;
  } catch (error) {
    console.error('/ArchiveCore/getChain', error);
    throw error;
  }
}
