import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { RouteComponentProps } from 'react-router-dom';
import { authService } from '../../services/AuthServices';
import { ProgressSpinner } from 'primereact/progressspinner';
import NotFound from '../NotFound';
import {
  DocumentType,
  IDepartment,
  IInstitution,
  ISite,
} from '../../types/DTOTypes';
import { institutionService } from '../../services/InstitutionServices';
import { AddSite } from '../../components/AddSite';
import AdminBackButton from '../../components/AdminBackButton';
import { AddDepartment } from '../../components/AddDepartment';
import { AddDocumentType } from '../../components/AddDocumentType';
import { DepartmentTable } from '../../components/DepartmentTable';
import { SiteTable } from '../../components/SiteTable';
import { DocumentTypeTable } from '../../components/DocumentTypeTable';
import { Toast } from 'primereact/toast';

interface IProps extends RouteComponentProps<MatchParams> {}

interface MatchParams {
  institutionId: string | undefined;
}

interface IState {
  sites: ISite[];
  departments: IDepartment[];
  documentTypes: DocumentType[];
  institution?: IInstitution;
  updatedInstitution?: IInstitution;
  loading: boolean;
  style: Object;
}

export class EditInstitution extends Component<IProps, IState> {
  toast: Toast | null;

  constructor(props: IProps) {
    super(props);

    this.state = {
      departments: [],
      sites: [],
      documentTypes: [],
      loading: true,
      style: {
        display: 'block',
        marginTop: '20px',
      },
    };
    this.toast = null;
  }

  async componentDidMount() {
    await this.refreshData();
  }

  async refreshData() {
    this.setState({
      loading: true,
    });
    const { institutionId } = this.props.match.params;

    const sites: ISite[] = await institutionService.getSitesForInstitution(
      institutionId ?? ''
    );
    const departments: IDepartment[] =
      await institutionService.getDepartmentsForInstitution(
        institutionId ?? ''
      );
    const institution: IInstitution =
      await institutionService.getInstitutionById(institutionId ?? '');
    const documentTypes: DocumentType[] =
      await institutionService.fetchDocumentTypes();

    this.setState({
      institution,
      departments,
      sites,
      documentTypes,
      loading: false,
    });
  }

  handleInstitutionFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof IInstitution
  ) => {
    const { updatedInstitution } = this.state;
    if (!updatedInstitution) {
      return;
    }
    this.setState({
      updatedInstitution: {
        ...updatedInstitution,
        [field]: e.target.value,
      },
    });
  };

  institutionTitleTemplate = () => {
    const { institution, updatedInstitution, loading, style } = this.state;
    if (!updatedInstitution) {
      const taxIdString = institution?.taxID
        ? ` (TaxID: ${institution?.taxID})`
        : '';
      const nameString = `Sites and departments for ${institution?.name}${taxIdString}:`;
      const pending = loading || !institution;
      return <h1>{pending ? 'Loading...' : nameString}</h1>;
    }
    return (
      <>
        <h1>Sites and departments for</h1>
        <span className="p-float-label">
          <InputText
            style={style}
            id="name"
            value={updatedInstitution.name}
            onChange={(e) => {
              this.handleInstitutionFieldChange(e, 'name');
            }}
          ></InputText>
          <label htmlFor="name">Name</label>
        </span>
        <span className="p-float-label">
          <InputText
            style={style}
            id="taxID"
            value={updatedInstitution.taxID ?? undefined}
            keyfilter="pint"
            maxLength={9}
            onChange={(e) => {
              this.handleInstitutionFieldChange(e, 'taxID');
            }}
          ></InputText>
          <label htmlFor="taxID">{'TaxID (9-digit number)'}</label>
        </span>
      </>
    );
  };

  checkEditInstitution = () => {
    const { institution, updatedInstitution } = this.state;
    // null
    if (!updatedInstitution || !institution) {
      return false;
    }
    // length
    if (
      updatedInstitution.name.trim().length <= 0 ||
      (updatedInstitution.taxID && updatedInstitution.taxID.trim().length !== 9)
    ) {
      return false;
    }
    // dirty
    if (
      institution.name.trim() === updatedInstitution.name.trim() &&
      institution.taxID?.trim() === updatedInstitution.taxID?.trim()
    ) {
      return false;
    }
    return true;
  };

  editInstitutionButtonTemplate = () => {
    const { institution, updatedInstitution } = this.state;
    if (!updatedInstitution) {
      return (
        <Button
          label="Edit Institution"
          onClick={() => {
            if (!institution) {
              return;
            }
            this.setState({
              updatedInstitution: {
                ...institution,
              },
            });
          }}
        />
      );
    }
    return (
      <Button
        label="Save"
        disabled={!this.checkEditInstitution()}
        onClick={async () => {
          const result = await institutionService.updateInstitution({
            ...updatedInstitution,
            name: updatedInstitution.name.trim(),
            taxID: updatedInstitution.taxID?.trim() ?? null,
          });
          if (!result || result.status !== 200) {
            this.toast?.show({
              severity: 'error',
              summary: 'Error Updating Institution',
              detail:
                result?.data ??
                "An error occurred while updating the institution's name",
            });
          } else {
            this.toast?.show({
              severity: 'success',
              summary: 'Institution Updated',
              detail: "Institution's name has successfully been updated",
            });
          }
          this.setState({
            updatedInstitution: undefined,
          });
          this.refreshData();
        }}
      />
    );
  };

  cancelEditInstitutionButtonTemplate = () => {
    return (
      <Button
        label="Cancel Editing"
        disabled={!this.state.updatedInstitution}
        onClick={() => {
          this.setState({
            updatedInstitution: undefined,
          });
        }}
      />
    );
  };

  addDocumentOnSuccess = () => {
    this.toast?.show({
      severity: 'success',
      summary: 'Document Type Added',
      detail: 'The new site has successfully been added!',
    });
    this.refreshData();
  };

  addDocumentOnFailure = (message: string) => {
    this.toast?.show({
      severity: 'error',
      summary: 'Error Adding Document Type',
      detail:
        message ||
        'An error occurred and the document type could not be added!',
    });
  };

  render() {
    const isSuperUser = authService.isAuthorized('SuperUser');
    const { institution, departments, sites, documentTypes, loading } =
      this.state;

    return (
      <div>
        {isSuperUser && (
          <div className="flexi-grid">
            <div className="p-col-12">
              <div className="card">
                <Toast ref={(el) => (this.toast = el)} />
                <AdminBackButton />
                <this.institutionTitleTemplate />
                <this.editInstitutionButtonTemplate />
                <this.cancelEditInstitutionButtonTemplate />
                {loading ? (
                  <ProgressSpinner />
                ) : (
                  <>
                    <AddDepartment
                      institution={
                        institution ?? {
                          id: -1,
                          name: '',
                          taxID: '',
                        }
                      }
                      onSuccess={() => {
                        this.toast?.show({
                          severity: 'success',
                          summary: 'Department Added',
                          detail:
                            'The new department has successfully been added!',
                        });
                        this.refreshData();
                      }}
                      onFailure={() => {
                        this.toast?.show({
                          severity: 'error',
                          summary: 'Error Adding Department',
                          detail:
                            'An error occurred and the department could not be added!',
                        });
                      }}
                    />
                    <DepartmentTable
                      loading={loading}
                      departments={departments}
                      isSuperUser={isSuperUser}
                      onSuccess={() => {
                        this.toast?.show({
                          severity: 'success',
                          summary: 'Department Updated',
                          detail:
                            'The department has successfully been updated!',
                        });
                        this.refreshData();
                      }}
                      onFailure={() => {
                        this.toast?.show({
                          severity: 'error',
                          summary: 'Error Updating Department',
                          detail:
                            'An error occurred and the department could not be updated!',
                        });
                      }}
                    />
                    <AddSite
                      institution={
                        institution ?? {
                          id: -1,
                          name: '',
                          taxID: '',
                        }
                      }
                      onSuccess={() => {
                        this.toast?.show({
                          severity: 'success',
                          summary: 'Site Added',
                          detail: 'The new site has successfully been added!',
                        });
                        this.refreshData();
                      }}
                      onFailure={() => {
                        this.toast?.show({
                          severity: 'error',
                          summary: 'Error Adding Site',
                          detail:
                            'An error occurred and the site could not be added!',
                        });
                      }}
                    />
                    <SiteTable
                      loading={loading}
                      sites={sites}
                      isSuperUser={isSuperUser}
                      onSuccess={() => {
                        this.toast?.show({
                          severity: 'success',
                          summary: 'Site Updated',
                          detail: 'The site has successfully been updated!',
                        });
                        this.refreshData();
                      }}
                      onFailure={() => {
                        this.toast?.show({
                          severity: 'error',
                          summary: 'Error Updating Site',
                          detail:
                            'An error occurred and the site could not be updated!',
                        });
                      }}
                    />
                    <AddDocumentType
                      onSuccess={this.addDocumentOnSuccess}
                      onFailure={this.addDocumentOnFailure}
                    />
                    <DocumentTypeTable
                      loading={loading}
                      documentTypes={documentTypes}
                      isSuperUser={isSuperUser}
                      onSuccess={() => {
                        this.toast?.show({
                          severity: 'success',
                          summary: 'Document Type Updated',
                          detail:
                            'The document type has successfully been updated!',
                        });
                        this.refreshData();
                      }}
                      onFailure={() => {
                        this.toast?.show({
                          severity: 'error',
                          summary: 'Error Updating Document Type',
                          detail:
                            'An error occurred and the document type could not be updated!',
                        });
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {!isSuperUser && <NotFound />}
      </div>
    );
  }
}
