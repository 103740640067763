import React, { Component } from 'react';

import classNames from 'classnames';
import { AppTopbar } from './AppTopbar';
import { AppMenu } from './AppMenu';
import { AppInlineProfile } from './AppInlineProfile';
import { authService } from '../services/AuthServices';

import { ScrollPanel } from 'primereact/scrollpanel';
import './App.css';

class ArchCor extends Component {
  constructor() {
    super();
    this.state = {
      layoutMode: 'horizontal',
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      darkMenu: true,
      menuActive: false,
      profileMode: 'inline',
      grouped: true,
      menuSource: [],
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
    this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
  }

  async componentDidMount() {
    await authService.setPermissionsSessionStorage();

    var menus = [
      { menu_id: 1, label: 'Home', icon: 'pi pi-fw pi-home', to: '/' },
    ];
    if (
      authService.isAuthorized('ViewCertificate') ||
      authService.isAuthorized('Register')
    ) {
      menus.push({
        menu_id: 2,
        label: 'Providers',
        icon: 'pi pi-users',
        to: '/providers',
      });
    }
    if (
      authService.isAuthorized('Register') ||
      authService.isAuthorized('Register Self-Upload')
    ) {
      menus.push({
        menu_id: 3,
        label: 'Register',
        icon: 'fa fa-address-card',
        to: '/register',
      });
    }
    if (
      authService.isAuthorized('SuperUser') ||
      (authService.isAuthorized('ViewCertificate') &&
        !authService.isAuthorized('Register Self-Upload'))
    ) {
      menus.push({
        menu_id: 4,
        label: 'Verify',
        icon: 'fa fa-shield',
        to: '/verify',
      });
    }
    if (authService.isAuthorized('SuperUser')) {
      menus.push({
        menu_id: 5,
        label: 'Administration',
        icon: 'fa fa-wrench',
        to: '/administration',
      });
    }
    if (
      authService.isAuthorized('SuperUser') ||
      !authService.isAuthorized('Register Self-Upload')
    ) {
      menus.push({
        menu_id: 6,
        label: 'Blockchain',
        icon: 'fa fa-link',
        to: '/blockchain',
      });
    }
    this.setState({ menuSource: menus });
  }

  onMenuClick(event) {
    this.menuClick = true;
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.setState({
      topbarMenuActive: false,
    });

    if (this.state.layoutMode === 'overlay') {
      if (this.isDesktop())
        this.setState({ overlayMenuActive: !this.state.overlayMenuActive });
      else
        this.setState({
          staticMenuMobileActive: !this.state.staticMenuMobileActive,
        });
    } else {
      if (this.isDesktop())
        this.setState({
          staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive,
        });
      else
        this.setState({
          staticMenuMobileActive: !this.state.staticMenuMobileActive,
        });
    }

    event.preventDefault();
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onTopbarItemClick(event) {
    this.topbarItemClick = true;

    if (event.item === 'logout') {
      this.props.childProps.handleLogout();
    }

    if (this.state.activeTopbarItem === event.item)
      this.setState({ activeTopbarItem: null });
    else this.setState({ activeTopbarItem: event.item });

    event.originalEvent.preventDefault();
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.hideOverlayMenu();
    }
    if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
      this.setState({
        menuActive: false,
      });
    }

    if (event.item.items && !this.isHorizontal() && this.layoutMenuScroller) {
      setTimeout(() => {
        this.layoutMenuScroller.moveBar();
      }, 500);
    }
  }

  onRootMenuItemClick(event) {
    this.setState({
      menuActive: !this.state.menuActive,
    });

    if (event.item.label !== 'Dashboard') event.originalEvent.preventDefault();
  }

  onDocumentClick(event) {
    if (!this.topbarItemClick) {
      this.setState({
        activeTopbarItem: null,
        topbarMenuActive: false,
      });
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.setState({
          menuActive: false,
        });
      }

      this.hideOverlayMenu();
    }

    this.topbarItemClick = false;
    this.menuClick = false;
  }

  hideOverlayMenu() {
    this.setState({
      overlayMenuActive: false,
      staticMenuMobileActive: false,
    });
  }

  isTablet() {
    let width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isOverlay() {
    return this.state.layoutMode === 'overlay';
  }

  isHorizontal() {
    return this.state.layoutMode === 'horizontal';
  }

  isSlim() {
    return this.state.layoutMode === 'slim';
  }

  render() {
    const layoutClassName = classNames('layout-wrapper', {
      'layout-horizontal': this.state.layoutMode === 'horizontal',
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-slim': this.state.layoutMode === 'slim',
      'layout-static-inactive': this.state.staticMenuDesktopInactive,
      'layout-mobile-active': this.state.staticMenuMobileActive,
      'layout-overlay-active': this.state.overlayMenuActive,
      'layout-menu-dark': this.state.darkMenu,
      'layout-menu-light': !this.state.darkMenu,
    });

    var menus = this.state.menuSource;

    return (
      <div className={layoutClassName} onClick={this.onDocumentClick}>
        <AppTopbar
          topbarMenuActive={this.state.topbarMenuActive}
          activeTopbarItem={this.state.activeTopbarItem}
          onMenuButtonClick={this.onMenuButtonClick}
          onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
          onTopbarItemClick={this.onTopbarItemClick}
          profileMode={this.state.profileMode}
          horizontal={this.isHorizontal()}
        />

        <div className="layout-menu-container" onClick={this.onMenuClick}>
          <div className="layout-menu-logo">
            <button className="p-link">
              <img
                id="layout-menu-logo"
                src="assets/layout/images/logo2.png"
                alt="ArchiveCore Logo"
              />
            </button>
          </div>
          <div className="layout-menu-wrapper">
            <ScrollPanel
              ref={(el) => (this.layoutMenuScroller = el)}
              style={{ height: '100%' }}
            >
              <div className="menu-scroll-content">
                {this.state.profileMode === 'inline' &&
                  this.state.layoutMode !== 'horizontal' && (
                    <AppInlineProfile />
                  )}

                <AppMenu
                  model={menus}
                  onMenuItemClick={this.onMenuItemClick}
                  onRootMenuItemClick={this.onRootMenuItemClick}
                  layoutMode={this.state.layoutMode}
                  active={this.state.menuActive}
                />
              </div>
            </ScrollPanel>
          </div>
        </div>
        <div className="layout-main"></div>
        {this.state.staticMenuMobileActive && (
          <div className="layout-mask"></div>
        )}
      </div>
    );
  }
}

export default ArchCor;
