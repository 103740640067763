import { Component } from 'react';
import { archiveCoreService } from '../../services/ArchiveCoreServices';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileUpload } from 'primereact/fileupload';
import { RegistrationStatus } from '../../components/RegistrationStatus';
import { IUser, Registration } from '../../types/DTOTypes';
import { authService } from '../../services/AuthServices';
import NotFound from '../NotFound';

interface IProps {}

interface IState {
  expandedRows: any[] | DataTableExpandedRows;
  fileList: File[];
  isRegistrationRecordsLoading: boolean;
  registrationRecordList: Registration[];
  userFullName: string;
}

export class Verify extends Component<IProps, IState> {
  fileUploadComponent: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      expandedRows: [],
      fileList: [],
      isRegistrationRecordsLoading: false,
      registrationRecordList: [],
      userFullName: '',
    };
  }

  componentDidMount = () => {
    // Get the user from session storage and add their name to state
    let user = sessionStorage.getItem('user');
    if (!user) {
      return;
    }
    let userData: IUser = JSON.parse(user);
    this.setState({
      userFullName: userData.firstName + ' ' + userData.lastName,
    });
  };

  fileUploadHandler = (e: { files: File[] }) => {
    this.setState(
      {
        fileList: e.files, // set the fileList
        registrationRecordList: [], // reset the registration records
        isRegistrationRecordsLoading: true, // tell the UI that the records are loading
      },
      () => {
        // this block runs after state is set above
        this.fileUploadComponent?.clear(); // clear the uploader
        archiveCoreService
          .fetchVerificationFile(this.state.fileList[0]) // get the registration records
          .then((response) => {
            // runs after the records have been gotten
            if (!response) {
              response = [];
            }
            // default to row expanded
            let expandedRows = [];
            for (let i = 0; i < response.length; i++) {
              expandedRows[response[i].providerId] = true;
            }
            this.setState({
              registrationRecordList: response, // set the registration records
              expandedRows, // set the expanded rows
              isRegistrationRecordsLoading: false, // tell the UI that we are done loading
            });
          });
      }
    );
  };

  dataTableHeader = () => {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col p-sm-12 p-lg-10" style={{ textAlign: 'left' }}>
          {this.state.registrationRecordList.length > 0 && (
            <span>
              There are {this.state.registrationRecordList.length} fingerprint{' '}
              matches.
              <br />
            </span>
          )}
          {this.state.registrationRecordList.length <= 0 &&
            !this.state.isRegistrationRecordsLoading && (
              <span>
                There are no fingerprint matches. Your file may be enqueued for{' '}
                blockchain submission, but still waiting. Check back in a few{' '}
                minutes!
                <br />
              </span>
            )}
        </div>
      </div>
    );
  };

  dataTableRowExpansionTemplate = (rowData: Registration) => {
    return (
      <RegistrationStatus
        requester={this.state.userFullName}
        certInfo={rowData}
      ></RegistrationStatus>
    );
  };

  render() {
    // set const from state, so we don't have to type this.state._____
    const {
      registrationRecordList,
      fileList,
      isRegistrationRecordsLoading,
      expandedRows,
    } = this.state;

    if (
      authService.isAuthorized('Register Self-Upload') &&
      !authService.isAuthorized('SuperUser')
    ) {
      return <NotFound />;
    }

    return (
      <div className="user-card card">
        <FileUpload
          id="fileUpload"
          name="fileUpload"
          mode="basic"
          auto
          chooseLabel="Click to choose a file to verify"
          uploadLabel="Verify file"
          customUpload
          uploadHandler={this.fileUploadHandler}
          ref={(ref) => {
            this.fileUploadComponent = ref;
          }}
        />
        {fileList.length > 0 && (
          <DataTable
            id="registrationRecordTable"
            value={registrationRecordList}
            dataKey="providerId"
            emptyMessage="No records found for this file"
            rows={10}
            responsiveLayout="scroll"
            resizableColumns
            header={<this.dataTableHeader></this.dataTableHeader>}
            loading={isRegistrationRecordsLoading}
            expandedRows={expandedRows}
            onRowToggle={(e) => {
              this.setState({ expandedRows: e.data });
            }}
            rowExpansionTemplate={this.dataTableRowExpansionTemplate}
          >
            <Column
              expander
              style={{ width: '2em', paddingRight: '2em' }}
            ></Column>
            <Column
              field="site"
              header="Site"
              body={(rowData) => {
                return rowData.site ?? 'NO SITE';
              }}
            />
            <Column field="providerFullNameWTitle" header="Provider" />
            <Column field="filename" header="Filename" />
            <Column field="documentType" header="Document Type" />
            <Column field="submitTime" header="Timestamp" />
            <Column
              body={(rowData: Registration) => {
                return (
                  <>
                    {rowData.isSelfUpload ? (
                      <i
                        className="fa fa-user"
                        aria-hidden="true"
                        title="Self-Uploaded"
                      ></i>
                    ) : (
                      ''
                    )}
                  </>
                );
              }}
              header=""
              style={{ textAlign: 'center', width: '4%' }}
            />
          </DataTable>
        )}
      </div>
    );
  }
}
