import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IDepartment } from '../../types/DTOTypes';
import { institutionService } from '../../services/InstitutionServices';
import { InputText } from 'primereact/inputtext';

interface IProps {
  departments: IDepartment[];
  loading: boolean;
  isSuperUser?: boolean;
  onSuccess?: Function;
  onFailure?: Function;
}

interface IState {
  targetDepartment: IDepartment;
}

export class DepartmentTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      targetDepartment: { id: -1, name: '', institutionId: -1 },
    };
  }

  departmentNameTemplate = (rowData: IDepartment) => {
    if (rowData.id === this.state.targetDepartment.id) {
      return (
        <InputText
          id="name"
          value={this.state.targetDepartment.name}
          onChange={(e) => {
            var { targetDepartment } = this.state;
            this.setState({
              targetDepartment: {
                id: targetDepartment.id,
                name: e.target.value,
                institutionId: targetDepartment.institutionId,
              },
            });
          }}
        ></InputText>
      );
    } else {
      return <div>{rowData.name}</div>;
    }
  };

  cancelEditDepartmentButtonTemplate = (rowData: IDepartment) => {
    if (rowData.id === this.state.targetDepartment.id) {
      return (
        <Button
          label="Cancel Editing"
          onClick={() => {
            this.setState({
              targetDepartment: { id: -1, name: '', institutionId: -1 },
            });
          }}
        />
      );
    } else {
      return <Button disabled label="Cancel Editing" />;
    }
  };

  editDepartmentButtonTemplate = (rowData: IDepartment) => {
    const { targetDepartment } = this.state;
    if (rowData.id === this.state.targetDepartment.id) {
      return (
        <Button
          label="Save"
          disabled={
            targetDepartment.name.trim().length <= 0 ||
            targetDepartment.name.trim() === rowData.name.trim()
          }
          onClick={async () => {
            const result = await institutionService.updateDepartment({
              id: targetDepartment.id,
              name: targetDepartment.name.trim(),
              institutionId: targetDepartment.institutionId,
            });

            this.setState({
              targetDepartment: {
                id: -1,
                name: targetDepartment.name.trim(),
                institutionId: targetDepartment.institutionId,
              },
            });

            if (result) {
              if (this.props.onSuccess) {
                this.props.onSuccess();
              }
            } else {
              if (this.props.onFailure) {
                this.props.onFailure();
              }
            }
          }}
        />
      );
    }
    return (
      <Button
        label="Edit Department"
        onClick={() => {
          this.setState({
            targetDepartment: {
              id: rowData.id,
              name: rowData.name,
              institutionId: rowData.institutionId,
            },
          });
        }}
      />
    );
  };

  render() {
    const { loading, departments, isSuperUser } = this.props;

    return (
      <>
        {loading ? (
          <ProgressSpinner />
        ) : (
          <>
            <DataTable
              id="departments"
              value={departments}
              paginatorPosition="bottom"
              header={() => {
                return <h1 style={{ display: 'table' }}>Departments</h1>;
              }}
              paginator
              rows={10}
              responsiveLayout="scroll"
              resizableColumns
              emptyMessage="No records found"
              sortMode="multiple"
              loading={loading}
              filterDisplay={'row'}
            >
              <Column
                body={this.departmentNameTemplate}
                header="Department Name"
                field="name"
                filter
                filterPlaceholder="Filter by Name"
                filterMatchMode="contains"
              />
              {isSuperUser && (
                <Column
                  body={this.editDepartmentButtonTemplate}
                  header=""
                  field="editDeparmentButton"
                />
              )}
              {isSuperUser && (
                <Column
                  body={this.cancelEditDepartmentButtonTemplate}
                  header=""
                  field="cancelEditDepartmentButton"
                />
              )}
            </DataTable>
          </>
        )}
      </>
    );
  }
}
