import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import NotFound from '../NotFound';
import { authService } from '../../services/AuthServices';
import { GrantViewCertificateProviderPermission } from '../../components/GrantViewCertificateProviderPermission';
import { GrantRegisterDeptSitePermission } from '../../components/GrantRegisterDeptSitePermission';
import {
  IUser,
  Permission,
  RegisterPermission,
  ViewCertificatePermission,
} from '../../types/DTOTypes';
import { userService } from '../../services/UserServices';
import { Fieldset } from 'primereact/fieldset';
import AdminBackButton from '../../components/AdminBackButton';
import { ViewCertificatePermissionsTable } from '../../components/ViewCertificatePermissionsTable';
import { RegisterPermissionsTable } from '../../components/RegisterPermissionsTable';
import { OtherPermissionsTable } from '../../components/OtherPermissionsTable';
import { Dropdown } from 'primereact/dropdown';
import { GrantDownloadPermission } from '../../components/GrantDownloadPermission';
import { GrantViewCertificateDeptSitePermission } from '../../components/GrantViewCertificateDeptSitePermission';
import { GrantRegisterProviderPermission } from '../../components/GrantRegisterProviderPermission';

interface MatchParams {
  id: string | undefined;
}

interface IProps extends RouteComponentProps<MatchParams> {}

interface IState {
  user?: IUser;
  loading: boolean;
  registerPermissions: RegisterPermission[];
  viewCertificatePermissions: ViewCertificatePermission[];
  otherPermissions: Permission[];
  permissionTypes: string[];
  currPermissionType?: string;
}

export class Permissions extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      registerPermissions: [],
      viewCertificatePermissions: [],
      otherPermissions: [],
      permissionTypes: ['View Certificate (Provider)', 'View Certificate (Department/Site)', 'Register (Provider)', 'Register (Department/Site)', 'Download'],
    };
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    if (id === undefined) {
      console.error('Permissions Component ID Prop is Undefinded!!');
      return;
    }
    const userId = parseInt(id);

    this.setState({ loading: true });
    const user = await userService.getUserFromId(userId);
    if (!user) {
      console.error('Permissions Component Could Not Obtain User!!');
      return;
    }

    let registerPermissions: RegisterPermission[] = [];
    let viewCertificatePermissions: ViewCertificatePermission[] = [];
    let otherPermissions: Permission[] = [];
    user.permissions.forEach((permission) => {
      if (permission.permissionTypeName === 'Register') {
        registerPermissions.push(permission as RegisterPermission);
      } else if (permission.permissionTypeName === 'ViewCertificate') {
        viewCertificatePermissions.push(
          permission as ViewCertificatePermission
        );
      } else {
        otherPermissions.push(permission);
      }
    });

    this.setState({
      user,
      registerPermissions,
      viewCertificatePermissions,
      otherPermissions,
      loading: false,
    });
  }

  render() {
    const isSuperUser = authService.isAuthorized('SuperUser');
    const {
      user,
      registerPermissions,
      viewCertificatePermissions,
      otherPermissions,
      loading,
      permissionTypes,
      currPermissionType,
    } = this.state;

    if (!isSuperUser) {
      return <NotFound />;
    }

    return (
      <div className="user-card card">
        <AdminBackButton />
        <h1>
          Permissions for {!loading && user?.firstName + ' ' + user?.lastName}:
        </h1>
        <div style={{ display: 'flex', minHeight: '17.4rem' }}>
          <ViewCertificatePermissionsTable
            permissions={viewCertificatePermissions}
            loading={loading}
            onRemoveSuccess={() => {
              this.componentDidMount();
            }}
          />
          <RegisterPermissionsTable
            permissions={registerPermissions}
            loading={loading}
            onRemoveSuccess={() => {
              this.componentDidMount();
            }}
          />
          <OtherPermissionsTable
            permissions={otherPermissions}
            loading={loading}
            onRemoveSuccess={() => {
              this.componentDidMount();
            }}
          />
        </div>
        <br />
        <Fieldset
          legend="Add Permissions"
          toggleable
          collapsed
          style={{ marginBottom: '1rem' }}
          onCollapse={() => this.setState({ currPermissionType: undefined })}
        >
          <Dropdown
            options={permissionTypes}
            value={currPermissionType}
            onChange={(e) => this.setState({ currPermissionType: e.value })}
            placeholder="Permission Type"
          />
          {currPermissionType === 'View Certificate (Provider)' && (
            <GrantViewCertificateProviderPermission
              user={user}
              onSuccess={() => {
                this.componentDidMount();
              }}
            />
          )}
          {currPermissionType === 'View Certificate (Department/Site)' && (
            <GrantViewCertificateDeptSitePermission
              user={user}
              onSuccess={() => {
                this.componentDidMount();
              }}
            />
          )}
          {currPermissionType === 'Register (Provider)' && (
            <GrantRegisterProviderPermission
              user={user}
              onSuccess={() => {
                this.componentDidMount();
              }}
            />
          )}
          {currPermissionType === 'Register (Department/Site)' && (
            <GrantRegisterDeptSitePermission
              user={user}
              onSuccess={() => {
                this.componentDidMount();
              }}
            />
          )}
          {currPermissionType === 'Download' && (
            <GrantDownloadPermission
              user={user}
              onSuccess={() => {
                this.componentDidMount();
              }}
            />
          )}
        </Fieldset>
      </div>
    );
  }
}
