import React, { Component } from 'react';
import NotFound from '../NotFound';
import { authService } from '../../services/AuthServices';
import { Route, RouteComponentProps } from 'react-router-dom';
import { archiveCoreService } from '../../services/ArchiveCoreServices';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { providerService } from '../../services/ProviderServices';
import { IDegree, IProvider, ISite, Registration } from '../../types/DTOTypes';
import { RegistrationTable } from '../../components/RegistrationTable';
import { AxiosResponse } from 'axios';
import { ProviderDetails } from '../../components/ProviderDetails';
import { institutionService } from '../../services/InstitutionServices';

interface MatchParams {
  id: string | undefined;
}

interface IProps extends RouteComponentProps<MatchParams> {}

interface IState {
  provider?: IProvider | null;
  registrations?: Registration[] | null;
  documents?: string[];
  errorCode?: number;
  sites: ISite[] | null;
}

export class ViewProvider extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      registrations: undefined,
      documents: undefined,
      errorCode: undefined,
      sites: null,
    };
  }

  async componentDidMount() {
    const sites = await institutionService.getSites();
    this.setState({ sites });

    const providerId = this.providerId();

    const provider: AxiosResponse = await providerService.getProvider(
      providerId
    );

    const registrations: AxiosResponse =
      await providerService.getRegistrationsByProvider(providerId);

    if (provider.status === 200 && registrations.status === 200) {
      this.setState({
        provider: provider.data,
        registrations: registrations.data,
      });
    } else {
      const status = Math.max(provider.status, registrations.status);
      this.setState({
        errorCode: status,
        provider: null,
        registrations: null,
      });
    }

    if (
      authService.canViewHcp(providerId) ||
      authService.isAuthorized('Register')
    ) {
      const documents = await archiveCoreService.listingObjectsAsync(
        providerId
      );
      this.setState({ documents: documents });
    }
  }

  providerId = () => {
    const id = this.props.match.params.id;
    if (!id) {
      const msg = 'NO ID FROM MATCH PARAMS';
      console.error(msg);
      throw msg;
    }
    const providerId = parseInt(id);

    return providerId;
  };

  loadDocuments = async () => {
    const providerId = this.providerId();

    if (providerId) {
      const documents = await archiveCoreService.listingObjectsAsync(
        providerId
      );
      this.setState({ documents: documents });
    }
  };

  arrayOfDTOsToString = (arrayOfDtos: IDegree[]) => {
    let string = '';
    for (var i = 0; i < arrayOfDtos.length; i++) {
      if (arrayOfDtos[i].hasOwnProperty('degreeName')) {
        string += arrayOfDtos[i].degreeName + ', ';
      }
    }
    return string.slice(0, -2);
  };

  render() {
    const { provider, errorCode, registrations, documents } = this.state;
    const isAuthRegister = authService.isAuthorized('Register');
    const isAuthViewOrRegister =
      authService.canViewHcp(provider?.id) || isAuthRegister;
    const isAuthZ = isAuthViewOrRegister && errorCode !== 403;

    if (!isAuthZ) {
      return <NotFound />;
    }

    if (errorCode || provider === null) {
      return (
        <div className="user-card card">
          <p style={{ fontWeight: 'bold' }}>
            <i
              className="pi pi-times"
              style={{ fontSize: '2em', color: 'red' }}
            ></i>
            We're sorry, an error occurred, please try again in a few minutes.
          </p>
          <p>
            If this issue persists, contact support and provide these codes.
          </p>
          <ul>
            <li>Error code: {errorCode}</li>
          </ul>
        </div>
      );
    }

    const loading =
      provider === undefined ||
      registrations === undefined ||
      documents === undefined;

    if (loading) {
      return <ProgressSpinner />;
    }

    return (
      <div className="p-col-12">
        <div className="user-card card">
          <BackButton />
          <ProviderDetails
            provider={provider}
            sites={this.state.sites ?? []}
            onSuccessfulEdit={() => this.componentDidMount()}
          />
        </div>
        <div className="user-card card">
          <h1 style={{ textAlign: 'left' }}>
            Documents registered for {provider?.fullNameWDegrees}
          </h1>
          {isAuthRegister && provider && RegisterButton(provider.id)}
          <RegistrationTable
            registrations={registrations}
            documents={documents}
            onRemove={this.loadDocuments}
          />
        </div>
      </div>
    );
  }
}

const BackButton = () => (
  <Route
    render={({ history }) => (
      <Button
        label="Back to Providers"
        icon="pi pi-arrow-left"
        onClick={() => {
          history.push('/providers');
        }}
      />
    )}
  />
);

const RegisterButton = (id: string | number) => (
  <Route
    render={({ history }) => (
      <Button
        style={{ marginBottom: '10px' }}
        label="Register a new document"
        icon="pi pi-upload"
        onClick={() => {
          history.push(`/register`);
        }}
      />
    )}
  />
);
