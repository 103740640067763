import React, { Component, ReactNode } from 'react';

export interface FloatLabelProps {
  children?: ReactNode;
  label: string;
}

interface IState {}

export class FloatLabel extends Component<FloatLabelProps, IState> {
  constructor(props: FloatLabelProps) {
    super(props);
    this.state = {};
  }
  render(): React.ReactNode {
    return (
      <div style={{ display: 'inline-block' }}>
        <span className="p-float-label">
          {this.props.children}
          <label>{this.props.label}</label>
        </span>
      </div>
    );
  }
}
