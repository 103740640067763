import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { IUser } from '../../types/DTOTypes';
import { userService } from '../../services/UserServices';
import { institutionService } from '../../services/InstitutionServices';
import { InfoDialog } from '../InfoDialog';
import { AxiosResponse } from 'axios';

interface IProps {
  user?: IUser;
  onSuccess?: Function;
}

interface IState {
  failure: boolean;
  success: boolean;
  exists: boolean;
}

export class GrantDownloadPermission extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      failure: false,
      success: false,
      exists: false,
    };
  }

  componentDidMount = async () => {
    const institutions = await institutionService.getInstitutions();
    this.setState({});
  };

  onHide = () => {
    this.setState({
      failure: false,
      success: false,
      exists: false,
    });
  };

  submit = async () => {
    const { user } = this.props;
    if (!user) {
      return;
    }
    const result: AxiosResponse | null =
      await userService.addDownloadPermission(user.id);
    if (!result || result.status >= 300) {
      if (result?.status === 409) {
        this.setState({ exists: true });
        return;
      }
      this.setState({ failure: true });
      return;
    } else {
      this.setState({ success: true });
      this.props.onSuccess?.();
      return;
    }
  };

  render() {
    return (
      <div>
        <h3>Grant Download Permission</h3>
        <p>
          Grant a user permission download documents from viewable registrations
        </p>
        <div style={{ display: 'block' }}>
          <Button
            style={{ marginTop: '1rem', display: 'block' }}
            label="Apply"
            icon="pi pi-check"
            onClick={this.submit}
            disabled={!this.props.user}
          />
        </div>
        <InfoDialog
          header="Success!"
          message="Permissions Successfully Updated"
          buttonText="Ok"
          onButtonClick={this.onHide}
          visible={this.state.success}
          closeOnEscape
          onHide={this.onHide}
        />
        <InfoDialog
          header="Already Exists!"
          message={`User already has permission to download`}
          buttonText="Ok"
          onButtonClick={this.onHide}
          visible={this.state.exists}
          closeOnEscape
          onHide={this.onHide}
        />
        <InfoDialog
          header="Error!"
          message="An error occurred, permissions were not updated"
          buttonText="Ok"
          onButtonClick={this.onHide}
          visible={this.state.failure}
          closeOnEscape
          onHide={this.onHide}
        />
      </div>
    );
  }
}
