import { Calendar, CalendarProps } from 'primereact/calendar';
import { Component } from 'react';

export interface BirthDateCalendarProps extends CalendarProps {}

interface IState {}

export class BirthDateCalendar extends Component<
  BirthDateCalendarProps,
  IState
> {
  constructor(props: BirthDateCalendarProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { ...passedInProps } = this.props;

    return (
      <Calendar
        minDate={new Date('January 1, 1900 00:00:00')} // before the oldest person alive was born
        maxDate={new Date()} // today
        dateFormat="yy-mm-dd" // translates to YYYY-MM-DD format
        mask="9999-99-99" // mask so they don't need to input dashes
        selectionMode="single"
        showIcon
        {...passedInProps}
      />
    );
  }
}
