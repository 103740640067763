import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ArchCor from '../../app/ArchCor';

export default ({ component: C, props: cProps, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      cProps.isAuthenticated ? (
        <div>
          <ArchCor childProps={cProps} />
          <C {...props} {...cProps} />
        </div>
      ) : (
        <Redirect
          to={`/login?redirect=${props.location.pathname}${props.location.search}`}
        />
      )
    }
  />
);
