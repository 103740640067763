import React, { Component } from 'react';
import DashboardHolder from '../../dashboardholder.png';
import { BrowserView } from 'react-device-detect';

interface IProps {}

interface IState {}

export class Dashboard extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div className="flexi-grid">
        <div className="p-col-12">
          <div className="card">
            <BrowserView>
              <img src={DashboardHolder} alt="dash" />
            </BrowserView>
          </div>
        </div>
      </div>
    );
  }
}
