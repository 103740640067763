import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { userService } from '../../services/UserServices';
import { IProvider, ISite } from '../../types/DTOTypes';
import { AxiosResponse } from 'axios';
import { providerService } from '../../services/ProviderServices';
import { NameData, AddNameDetails, EmptyNameData } from './AddNameDetails';
import { AddUserDetails, EmptyUserData, UserData } from './AddUserDetails';
import {
  AddProviderDetails,
  EmptyProviderData,
  ProviderData,
} from './AddProviderDetails';
import { ProviderTable } from '../ProviderTable';
import { DataTableFilterMeta } from 'primereact/datatable';

interface IProps {
  onSuccess: (message: string) => void;
  onFailure: (message: string) => void;
  sites: ISite[];
}

interface IState {
  nameData: NameData;
  userData: UserData | null;
  providerData: ProviderData | null;
  providers: IProvider[];
  loading: boolean;
}

export class AddUsersAndProviders extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      nameData: EmptyNameData,
      userData: null,
      providerData: null,
      providers: [],
      loading: true,
    };
  }

  async componentDidMount() {
    this.loadProviders();
  }

  loadProviders() {
    // set loading to true with callback that updates providers/loading
    this.setState({ loading: true }, async () => {
      var providers = await providerService.getProvidersToView();
      this.setState({
        providers: providers,
        loading: false,
      });
    });
  }

  checkComplete = (): boolean => {
    const { nameData, userData, providerData } = this.state;
    // must be either a user or provider
    if (!userData && !providerData) {
      return false;
    }

    // check required fields
    if (!nameData?.valid) {
      return false;
    }
    if (userData && !userData.valid) {
      return false;
    }
    if (providerData && !providerData.valid) {
      return false;
    }
    return true;
  };

  postData = async (
    nameData: NameData,
    userData: UserData | null,
    providerData: ProviderData | null
  ): Promise<{ response: AxiosResponse; postType: string }> => {
    if (userData && providerData) {
      const postType = 'User & Provider';
      const response = await userService.addUserProvider(
        nameData.firstName.trim(),
        nameData.middleName.trim(),
        nameData.lastName.trim(),
        userData.email.trim(),
        userData.password.trim(),
        userData.site?.id ?? -1,
        providerData.NPI.trim(),
        providerData.position.trim(),
        providerData.degree.trim(),
        providerData.birthDate as Date,
        providerData.sites
      );
      return { response, postType };
    }
    if (userData) {
      const postType = 'User';
      const response = await userService.postUser(
        nameData.lastName.trim(),
        nameData.firstName.trim(),
        nameData.middleName.trim(),
        userData.email.trim(),
        userData.password.trim(),
        userData.site?.id ?? -1,
        null
      );
      return { response, postType };
    }
    if (providerData) {
      const postType = 'Provider';
      console.assert(
        providerData.birthDate instanceof Date,
        'birthDate was not a valid Date!'
      );
      const response = await providerService.addProvider(
        nameData.firstName.trim(),
        nameData.middleName.trim(),
        nameData.lastName.trim(),
        providerData.NPI.trim(),
        providerData.position.trim(),
        providerData.degree.trim(),
        null,
        providerData.birthDate as Date,
        providerData.sites
      );
      return { response, postType };
    }
    throw 'INVALID STATE! userData and providerData both NULL in postData!';
  };

  handleSubmit = async () => {
    const { nameData, userData, providerData } = this.state;

    // send POST
    const { response, postType } = await this.postData(
      nameData,
      userData,
      providerData
    );

    // handle response
    if (response && response.status === 200) {
      this.props.onSuccess(postType + ' has successfully been added!');
      this.setState({
        nameData: EmptyNameData,
        userData: userData ? EmptyUserData : null,
        providerData: providerData ? EmptyProviderData : null,
      });
    } else {
      this.props.onFailure(response?.data);
    }
    this.loadProviders();
  };

  handleChangeNameData = (nameData: NameData) => {
    this.setState({ nameData });
  };

  handleChangeUserData = (userData: UserData | null) => {
    this.setState({ userData });
  };

  handleChangeProviderData = (providerData: ProviderData | null) => {
    this.setState({ providerData });
  };

  render() {
    const { nameData, userData, providerData, providers, loading } = this.state;
    const dateString =
      providerData?.birthDate instanceof Date
        ? providerData?.birthDate.toISOString().substring(0, 10)
        : undefined;
    const filters: DataTableFilterMeta = {
      firstName: { matchMode: 'contains', value: nameData.firstName },
      lastName: { matchMode: 'contains', value: nameData.lastName },
      npi: { matchMode: 'contains', value: providerData?.NPI },
      birthDate: { matchMode: 'contains', value: dateString },
    };
    return (
      <>
        <AddNameDetails
          nameData={nameData}
          setNameData={this.handleChangeNameData}
        />
        <AddUserDetails
          sites={this.props.sites}
          userData={userData}
          setUserData={this.handleChangeUserData}
        />
        <AddProviderDetails
          sites={this.props.sites}
          providerData={providerData}
          setProviderData={this.handleChangeProviderData}
        />
        <Button
          style={{ display: 'block', marginTop: '20px' }}
          className="p-button-icon"
          icon="pi pi-check"
          disabled={!this.checkComplete()}
          onClick={this.handleSubmit}
          label="Submit"
        />
        <ProviderTable
          providers={providers}
          loading={loading}
          header={
            <h1 style={{ display: 'table' }}>Potential Duplicate Providers</h1>
          }
          globalFilterFields={['firstName', 'lastName', 'npi', 'birthDate']}
          filters={filters}
          hideFilters={true}
        />
      </>
    );
  }
}
