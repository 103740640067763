import React, { Component } from 'react';

interface IProps {}
interface IState {}

export class SigningComplete extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }
  render() {
    return (
      <div className="exception-body notfound">
        <div className="exception-panel" />
        <div className="exception-content">
          <img src={'assets/layout/images/logo.png'} alt="babylon-layout" />
          <h1>
            <span className="exception-name">SIGNING </span>COMPLETE
          </h1>
          <p>It is safe to close this page.</p>
          <a href="/#">Back to Dashboard</a>
        </div>
      </div>
    );
  }
}
