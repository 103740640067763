import { Button } from 'primereact/button';
import { Component } from 'react';
import { ConfirmationDialog } from '../../components/ConfirmationDialog';
import { RegistrationTable } from '../../components/RegistrationTable';
import { Registration } from '../../types/DTOTypes';
import { ISiteDeptPair, NO_SITE_DEPT } from '../../types/SiteDeptPair';

interface IProps {
  selectedSiteDeptPair?: ISiteDeptPair | null;
  registrationList: Registration[];
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onContinue: React.MouseEventHandler<HTMLButtonElement>;
}

interface IState {
  showConfirm: boolean;
  isConfirmed: boolean;
}

export class Review extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showConfirm: false,
      isConfirmed: false,
    };
  }

  rowExpansionTemplate = (data: Registration) => {
    const etherscanLink =
      data.txLink && data.transactionHash
        ? data.txLink + data.transactionHash
        : undefined;
    return (
      <div className="p-grid p-fluid" style={{ margin: '10px' }}>
        <div className="p-grid">
          <div className="p-col-4 dataHeader">Filename: </div>
          <div className="p-col-8 dataBody">{data.filename}</div>
          <div className="p-col-4 dataHeader">Site: </div>
          <div className="p-col-8 dataBody">{data.site ?? 'NO SITE'}</div>
          <div className="p-col-4 dataHeader">Submitted By: </div>
          <div className="p-col-8 dataBody">{data.submittedBy}</div>
          <div className="p-col-4 dataHeader">Submit Date: </div>
          <div className="p-col-8 dataBody">{data.submitTime}</div>
          <div className="p-col-4 dataHeader">Description: </div>
          <div className="p-col-8 dataBody">
            {data.description || '<empty>'}
          </div>
          <div className="p-col-4 dataHeader">Status: </div>
          <div className="p-col-8 dataBody">{data.status || 'Pending...'}</div>
          <div className="p-col-4 dataHeader">Version: </div>
          <div className="p-col-8 dataBody">{data.version}</div>
          <div className="p-col-4 dataHeader">File Hash: </div>
          <div className="p-col-8 dataBody">{data.fileHash}</div>
          <div className="p-col-4 dataHeader">Registration Hash: </div>
          <div className="p-col-8 dataBody">{data.registrationHash}</div>
          <div className="p-col-4 dataHeader">Sender Address: </div>
          <div className="p-col-8 dataBody">
            {data.senderAddress || 'Pending...'}
          </div>
          <div className="p-col-4 dataHeader">Receiver Address: </div>
          <div className="p-col-8 dataBody">
            {data.receiverAddress || 'Pending...'}
          </div>
          <div className="p-col-4 dataHeader">Transaction Hash: </div>
          <div className="p-col-8 dataBody">
            <a href={etherscanLink} target="_blank" rel="noopener noreferrer">
              {data.transactionHash || 'Pending...'}
              <i
                style={{ marginLeft: '3px' }}
                className="fa fa-external-link fa-lg"
              ></i>
            </a>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { selectedSiteDeptPair, registrationList, onCancel, onContinue } =
      this.props;

    const headerText =
      selectedSiteDeptPair !== NO_SITE_DEPT
        ? `Confirm Registration of Documents to ${selectedSiteDeptPair?.department.name} at ${selectedSiteDeptPair?.site.name}`
        : 'Confirm Registration of Documents';

    return (
      <div className="user-card card">
        <h1>{headerText}</h1>
        <RegistrationTable
          registrations={registrationList}
          documents={[]}
          customExpansionTemplate={this.rowExpansionTemplate}
        />
        <Button
          className="p-button-secondary"
          label="Back"
          icon="pi pi-arrow-left"
          iconPos="left"
          onClick={onCancel}
        />
        <Button
          label="Continue"
          icon="pi pi-arrow-right"
          iconPos="right"
          onClick={() => {
            this.setState({
              showConfirm: true,
            });
          }}
        />
        <ConfirmationDialog
          visible={this.state.showConfirm}
          header={'Confirm Registration for all documents uploaded?'}
          confirmLabel="Confirm Registration"
          onHide={() => this.setState({ showConfirm: false })}
          onConfirm={async (event: any) => {
            if (this.state.isConfirmed) {
              return;
            }
            this.setState({ isConfirmed: true });
            onContinue(event);
          }}
          onCancel={() => this.setState({ showConfirm: false })}
        >
          Are you sure you want to register this document? Clicking "Confirm
          Registration" will create a permanent record.
        </ConfirmationDialog>
      </div>
    );
  }
}
