import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface IProps {
  header: string;
  confirmLabel?: string;
  cancelLabel?: string;
  visible?: boolean;
  closeOnEscape?: boolean;
  children?: React.ReactNode;
  onConfirm(e: React.MouseEvent<HTMLElement>): void;
  onCancel(e: React.MouseEvent<HTMLElement>): void;
  onHide(): any;
}

interface IState {}

export class ConfirmationDialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const {
      header,
      confirmLabel,
      cancelLabel,
      onConfirm,
      onCancel,
      visible,
      closeOnEscape,
      onHide,
      children,
    } = this.props;

    return (
      <Dialog
        header={header}
        footer={
          <>
            <Button
              className="p-button-secondary"
              label={cancelLabel ?? 'Cancel'}
              icon="pi pi-times"
              onClick={onCancel}
            />
            <Button
              label={confirmLabel ?? 'Confirm'}
              icon="pi pi-check"
              onClick={onConfirm}
            />
          </>
        }
        visible={visible}
        closeOnEscape={closeOnEscape}
        onHide={onHide}
      >
        {children}
      </Dialog>
    );
  }
}
