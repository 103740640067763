import { IDepartment, ISite } from './DTOTypes';

export interface ISiteDeptPair {
  site: ISite;
  department: IDepartment;
  name: string;
}

export const NO_SITE_DEPT: ISiteDeptPair = {
  site: {
    id: -1,
    institutionId: -1,
    name: '',
    city: '',
    state: '',
    zipCode: '',
  },
  department: {
    id: -1,
    name: '',
    institutionId: -1,
  },
  name: 'Not Associated With Site or Department',
};
