import React, { Component } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import UnauthenticatedRoute from '../components/UnauthenticatedRoute';
import AuthenticatedRoute from '../components/AuthenticatedRoute';

import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import { Dashboard } from '../pages/Dashboard';
import { Verify } from '../pages/Verify';
import { Administration } from '../pages/Administration';
import { BlockChain } from '../pages/BlockChain';
import { EditUser } from '../pages/EditUser';
import { Permissions } from '../pages/Permissions';
import { ViewProvider } from '../pages/ViewProvider';
import { Providers } from '../pages/Providers';
import { authService } from '../services/AuthServices';
import { EditInstitution } from '../pages/EditInstitution';
import { RegisterDocument } from '../pages/RegisterDocument';
import { SigningComplete } from '../pages/SigningComplete';

class AppWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      fields: {},
      organizationName: '',
      userPermissions: [],
    };
  }

  async componentDidMount() {
    try {
      if (await authService.authUser()) {
        this.userHasAuthenticated(true);
      }
    } catch (e) {
      alert(e);
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = () => {
    authService.logout();

    this.userHasAuthenticated(false);
    this.setState({ disableMenu: false });
    this.props.history.push('/login');
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      handleChange: this.handleChange,
      fields: this.state.fields,
      handleLogout: this.handleLogout,
    };

    return (
      !this.state.isAuthenticating && (
        <Switch>
          <UnauthenticatedRoute
            path="/login"
            exact
            component={Login}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/"
            exact
            component={Dashboard}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/verify"
            exact
            component={Verify}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/register"
            exact
            component={RegisterDocument}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/administration"
            exact
            component={Administration}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/administration/:activeIndex"
            exact
            component={Administration}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/blockchain"
            exact
            component={BlockChain}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/administration/permissions/:id"
            exact
            component={Permissions}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/administration/editUser/:userId"
            exact
            component={EditUser}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/providers"
            exact
            component={Providers}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/providers/details/:id"
            exact
            component={ViewProvider}
            props={childProps}
          />
          <AuthenticatedRoute
            path="/administration/editInstitution/:institutionId"
            exact
            component={EditInstitution}
            props={childProps}
          />
          <Route
            path="/register/returning"
            exact
            component={SigningComplete}
            props={childProps}
          />
          <Route
            path="/resetPassword/:token"
            exact
            component={ResetPassword}
            props={childProps}
          />
          <Route
            path="/forgotPassword/"
            exact
            component={ForgotPassword}
            props={childProps}
          />

          {/* Finally, catch all unmatched routes */}
          <Route component={NotFound} />
        </Switch>
      )
    );
  }
}

export default withRouter(AppWrapper);
