import React, { Component } from 'react';
import { DataTable, DataTableProps } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Permission } from '../../types/DTOTypes';
import { Button } from 'primereact/button';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { userService } from '../../services/UserServices';

interface PermissionsTableProps extends DataTableProps {
  permissions: Permission[];
  nameTemplate: (rowData: any) => JSX.Element;
  typeTemplate?: (rowData: any) => JSX.Element;
  onRemoveSuccess?: () => any;
  onRemoveFailure?: () => any;
}

interface PermissionsTableState {
  currentPermission?: Permission;
  showDialog: boolean;
}

export class PermissionsTable extends Component<
  PermissionsTableProps,
  PermissionsTableState
> {
  constructor(props: PermissionsTableProps) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }

  removeTemplate = (rowData: Permission) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <Button icon="pi pi-times" onClick={() => this.showDialog(rowData)} />
      </div>
    );
  };

  removePermission = async () => {
    const { currentPermission } = this.state;
    const { onRemoveSuccess, onRemoveFailure } = this.props;
    if (!currentPermission) {
      onRemoveFailure?.();
      return;
    }
    const respose = await userService.removePermission(currentPermission?.id);
    if (!respose || respose.status >= 300) {
      onRemoveFailure?.();
      return;
    }
    this.hideDialog();
    onRemoveSuccess?.();
  };

  showDialog = (currentPermission: Permission): void => {
    this.setState({ showDialog: true, currentPermission });
  };

  hideDialog = (): void => {
    this.setState({ showDialog: false, currentPermission: undefined });
  };

  render() {
    const { showDialog } = this.state;

    const {
      permissions,
      typeTemplate,
      nameTemplate,
      ...passedInProps
    }: PermissionsTableProps = this.props;

    const dataTableProps: DataTableProps = {
      id: 'permissions',
      value: permissions ?? [],
      paginator: permissions.length > 5,
      paginatorPosition: 'bottom',
      rows: 5,
      responsiveLayout: 'stack',
      autoLayout: true,
      resizableColumns: true,
      emptyMessage: 'No Permissions',
      sortMode: 'multiple',
      header: 'ViewCertificate Permissions',
      style: { margin: '0.25rem' },
      ...passedInProps,
    };

    return (
      <>
        <ConfirmationDialog
          visible={showDialog}
          header={`Delete ${this.state.currentPermission?.permissionTypeName} Permission? `}
          onConfirm={(e) => this.removePermission()}
          onCancel={(e) => this.hideDialog()}
          onHide={() => this.hideDialog()}
        />
        <DataTable {...dataTableProps}>
          {typeTemplate && <Column header="Type" body={typeTemplate} />}
          <Column header="Name" body={nameTemplate} />
          <Column body={this.removeTemplate} />
        </DataTable>
      </>
    );
  }
}
