import { Component, RefObject } from 'react';
import { authService } from '../../services/AuthServices';
import {
  archiveCoreService,
  IBlockChainInput,
} from '../../services/ArchiveCoreServices';
import { DropdownChangeParams } from 'primereact/dropdown';
import { FileUploadHandlerParam } from 'primereact/fileupload';
import {
  DocumentType,
  IInstitution,
  IUser,
  ProcessInputFileResult,
  Registration,
} from '../../types/DTOTypes';
import { institutionService } from '../../services/InstitutionServices';
import { documentSigningService } from '../../services/DocumentSigningServices';
import NotFound from '../NotFound';
import { CertificateAccordion } from '../../components/CertificateAccordion';
import { FileSelection } from './FileSelection';
import { IRegisterForm } from '../../types/RegisterForm';
import { CompleteForm } from './CompleteForm';
import { Review } from './Review';
import { ISiteDeptPair, NO_SITE_DEPT } from '../../types/SiteDeptPair';
import { ProgressSpinner } from 'primereact/progressspinner';

// Enum which represents the state of the register component
enum Step {
  FileSelection,
  CompleteInfo,
  Review,
  Result,
}

interface IProps {
  ref?: RefObject<RegisterDocument>;
}

interface IState {
  currentStep: Step;
  allowedDocumentTypeList?: DocumentType[];
  registerFormList?: IRegisterForm[];
  registrationVersion: string;
  selectedSiteDeptPair?: ISiteDeptPair | null;
  selectedSiteDeptInstitution?: IInstitution;
  siteDeptPairList?: ISiteDeptPair[] | null;
  user: IUser;
}

export class RegisterDocument extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const userJSON = sessionStorage.getItem('user');
    const user = userJSON ? JSON.parse(userJSON) : undefined;
    this.state = {
      currentStep: Step.FileSelection,
      allowedDocumentTypeList: undefined,
      registerFormList: undefined,
      registrationVersion: 'V1.0', // TODO: This was hard coded in the previous register page, and might be used in registrationHash
      selectedSiteDeptPair: undefined,
      selectedSiteDeptInstitution: undefined,
      siteDeptPairList: undefined,
      user,
    };
  }

  componentDidMount = async () => {
    try {
      const siteDeptPairList = await authService.authorizedSiteDeptPairs();

      this.setState({
        siteDeptPairList,
      });
    } catch (ex) {
      console.warn(ex);
      this.setState({
        siteDeptPairList: null,
      });
    }
  };

  copyAndUpdateRegisterFormList = <P extends keyof IRegisterForm>(
    registerFormList: IRegisterForm[],
    index: number,
    keyValuePairs: { [key in P]: IRegisterForm[P] }
  ) => {
    const tempList = [...registerFormList];

    tempList[index] = {
      ...tempList[index],
      ...keyValuePairs,
    };

    return tempList;
  };

  handleFormChange = async <P extends keyof IRegisterForm>(
    form: IRegisterForm,
    keyValuePairs: { [key in P]: IRegisterForm[P] },
    callback?: () => void
  ) => {
    const { registerFormList } = this.state;

    if (registerFormList === undefined) {
      throw new Error(`registerFormList is missing data for handleFormChange`);
    }

    const index = registerFormList.findIndex((el) => {
      return el.file.name === form.file.name;
    });

    const tempList = this.copyAndUpdateRegisterFormList(
      registerFormList,
      index,
      keyValuePairs
    );

    this.setState({ registerFormList: tempList }, callback);
  };

  handleDeptSiteChange = async (e: DropdownChangeParams) => {
    const allowedDocumentTypeList: DocumentType[] =
      await institutionService.fetchDocumentTypes();

    const selectedSiteDeptPair = e.value as ISiteDeptPair;

    if (!selectedSiteDeptPair || selectedSiteDeptPair === NO_SITE_DEPT) {
      this.setState({
        allowedDocumentTypeList,
        selectedSiteDeptPair: null,
      });
      return;
    }

    const institutionId: number = selectedSiteDeptPair.site.institutionId;

    const selectedSiteDeptInstitution =
      await institutionService.getInstitutionById(institutionId);

    this.setState({
      allowedDocumentTypeList,
      selectedSiteDeptPair,
      selectedSiteDeptInstitution,
    });
  };

  chooseFilesToRegister = (event: FileUploadHandlerParam) => {
    if (event.files.length <= 0) {
      console.warn('User tried to upload empty file list!');
      return;
    }
    // init the registerFormList
    const registerFormList: IRegisterForm[] = [];
    // Assign values based on files in the list
    for (const file of event.files) {
      registerFormList.push({
        file: file,
        certInfo: null,
        description: '',
        isRegisterFailure: true,
        isSignatureComplete: false,
        isUploadSuccess: false,
        isUploadFailure: false,
        uploadFailureMessage: file.name + ' could not be uploaded!',
        envelopeId: null,
        envelopeLink: null,
        selectedDegree: null,
        selectedDocumentType: null,
        selectedProvider: null,
      });
    }
    // Update the state
    this.setState({
      registerFormList,
      currentStep: Step.CompleteInfo,
    });
  };

  processFileToRegister = async (
    form: IRegisterForm,
    registrationVersion: string,
    selectedSiteDeptPair?: ISiteDeptPair | null
  ): Promise<IRegisterForm | undefined> => {
    // Perform nullish checking
    if (
      !form.selectedProvider ||
      selectedSiteDeptPair === undefined ||
      !form.selectedDocumentType
    ) {
      const msg = `Parts of file input were undefined for file: ${form.file.name}`;
      console.error(msg);
      throw new Error(msg);
    }

    // Handles completed DocuSign files
    if (form.envelopeId && form.isSignatureComplete) {
      // Get and replace document's file with the signed version
      const response: Blob = await documentSigningService.downloadDocument(
        form.envelopeId,
        '1' // A constant value that matches the value from DocumentSigningService.cs
      );
      // replace the file on this for with the signed one
      form.file = new File([response], form.file.name, {
        type: response.type,
      });
    }

    // Send the file to be processed by ArchiveCoreService.
    const data: ProcessInputFileResult =
      await archiveCoreService.processInputFile(
        form.file,
        form.selectedProvider,
        {
          documentType: form.selectedDocumentType,
          description: form.description,
        },
        registrationVersion,
        selectedSiteDeptPair?.site ?? null,
        form.selectedPosition ?? form.selectedProvider.position
      );

    if (!data) {
      console.error(
        `Input file was not processed correctly! ${form.file.name}`,
        data
      );
      return;
    }

    // ArchiveCoreService returns some data which we attach to the registerForm
    form.currentTimeStamp = data.currentTimeStamp;
    form.fileHash = data.fileHash;
    form.newFileName = data.newFileName;
    form.registrationHash = data.registrationHash;
    form.registrationString = data.registrationString;

    return form;
  };

  // Used to process uploaded files before registration
  processFilesToRegister = async () => {
    const { selectedSiteDeptPair, registrationVersion, registerFormList } =
      this.state;

    if (registerFormList === undefined) {
      throw new Error(
        `registerFormList is missing data for processFilesToRegister`
      );
    }

    const that = this;

    const promises: Promise<IRegisterForm | undefined>[] = registerFormList.map(
      function (form: IRegisterForm) {
        return new Promise<IRegisterForm | undefined>(async (resolve) => {
          resolve(
            await that.processFileToRegister(
              form,
              registrationVersion,
              selectedSiteDeptPair
            )
          );
        });
      }
    );

    return Promise.all(promises).then((promiseData) => {
      const forms: IRegisterForm[] = [];

      promiseData.forEach((form) => {
        if (form === undefined) {
          return;
        }
        forms.push(form);
      });

      that.setState({
        registerFormList: forms,
        currentStep: Step.Review,
      });
    });
  };

  registerDocument = async (form: IRegisterForm) => {
    const { user, selectedSiteDeptPair, registrationVersion } = this.state;

    function CheckValid<Type>(name: string, value: Type): NonNullable<Type> {
      if (value === undefined || value == null) {
        throw new Error(`registerDocument is missing data for '${name}'`);
      }
      return value as NonNullable<Type>;
    }

    const degreeId = CheckValid('degreeId', form.selectedDegree?.id);
    const departmentId = selectedSiteDeptPair?.department?.id ?? undefined;
    const documentTypeId = CheckValid(
      'documentTypeId',
      form.selectedDocumentType?.id
    );
    const fileHash = CheckValid('fileHash', form.fileHash);
    const fileName = CheckValid('fileName', form.newFileName);
    const providerId = CheckValid('providerId', form.selectedProvider?.id);
    const providerPosition = CheckValid(
      'providerPosition',
      form.selectedPosition
    );
    const registrationHash = CheckValid(
      'registrationHash',
      form.registrationHash
    );
    const registrationString = CheckValid(
      'registrationString',
      form.registrationString
    );
    const site = selectedSiteDeptPair?.site ?? undefined;
    const siteId = selectedSiteDeptPair?.site.id ?? undefined;
    const submitterId = CheckValid('submitterId', user?.id);
    const submitTime = CheckValid('submitTime', form.currentTimeStamp);

    // TODO: ArchiveCoreService expects this data structure,
    // but ideally we just give it the relevant data and it constructs this itself
    // https://archivecore.atlassian.net/browse/AD-104
    const blockChainInput: IBlockChainInput = {
      degreeId,
      departmentId,
      description: form.description,
      documentTypeId,
      fileHash,
      fileName,
      providerId,
      providerPosition,
      registrationHash,
      registrationString,
      site,
      siteId,
      submitterId,
      submitTime,
      version: registrationVersion,
    };

    // try pushing the data above to the blockchain
    await archiveCoreService.pushToBlockChain(blockChainInput, user?.email);
  };

  uploadDocument = async (form: IRegisterForm) => {
    // Create the key used to store this file
    const key = `${form.selectedProvider?.uuid}/${form.fileHash}`;

    // upload this file to ArchiveCoreService
    const response = await archiveCoreService.uploadDocument(key, form.file);
    const uploadFailureMessage =
      response?.status === 413
        ? `${form.file.name} is too big to be stored; after you leave this page, it cannot be downloaded!`
        : `${form.file.name} could not be registered!`;

    // Mark this file as being successfully registered
    this.handleFormChange(form, {
      isRegisterFailure: false,
      isUploadFailure: response?.status !== 200,
      isUploadSuccess: response?.status === 200,
      uploadFailureMessage,
    });
  };

  buildStatusJSX = () => {
    const { registerFormList } = this.state;
    const statusJSX: JSX.Element[] = [];

    if (registerFormList === undefined) {
      throw new Error(`registerFormList is missing data for buildStatusJSX`);
    }

    for (const form of registerFormList) {
      if (form.isRegisterFailure || form.isUploadFailure) {
        statusJSX.push(
          <p style={{ fontWeight: 'bold' }} key={form.file.name}>
            <i
              className="pi pi-times"
              style={{ fontSize: '2em', color: 'red' }}
            ></i>
            {form.isRegisterFailure && (
              <>{form.file.name} could not be registered!</>
            )}
            {form.isUploadFailure && form.uploadFailureMessage}
          </p>
        );
      }
    }
    if (statusJSX.length > 0) {
      return statusJSX;
    }
    return [
      <p style={{ fontWeight: 'bold' }} key="success">
        <i
          className="pi pi-check"
          style={{ fontSize: '2em', color: 'green' }}
        ></i>
        Success! All documents were successfully registered in the ArchiveCore
        System
      </p>,
    ];
  };

  buildRegistrationList = () => {
    function CheckValid<Type>(name: string, value: Type): NonNullable<Type> {
      if (value === undefined || value === null) {
        const msg = `buildRegistrationList is missing data for '${name}'`;
        console.warn(msg);
        throw new Error(msg);
      }
      return value as NonNullable<Type>;
    }

    const {
      user,
      selectedSiteDeptInstitution,
      registrationVersion,
      registerFormList,
    } = this.state;

    if (this.state.selectedSiteDeptPair === undefined) {
      const msg = `buildRegistrationList is missing data for 'selectedSiteDeptPair'`;
      console.warn(msg);
      throw new Error(msg);
    }
    const selectedSiteDeptPair = this.state.selectedSiteDeptPair;

    if (registerFormList === undefined) {
      throw new Error(
        `registerFormList is missing data for buildRegistrationList`
      );
    }

    const reviewList: Registration[] = [];
    for (const form of registerFormList) {
      const selectedProvider = CheckValid(
        'form.selectedProvider',
        form.selectedProvider
      );
      const selectedDocumentType = CheckValid(
        'form.selectedDocumentType',
        form.selectedDocumentType
      );
      const selectedDegree = CheckValid(
        'form.selectedDegree',
        form.selectedDegree
      );
      const fileHash = CheckValid('form.fileHash', form.fileHash);
      const currentTimeStamp = CheckValid(
        'form.currentTimeStamp',
        form.currentTimeStamp
      );
      const registrationHash = CheckValid(
        'form.registrationHash',
        form.registrationHash
      );

      const reg: Registration = {
        submitterId: user.id,
        submittedBy: user.firstName + ' ' + user.lastName,
        providerId: selectedProvider.id,
        providerFullNameWTitle: selectedProvider.fullNameWDegrees,
        siteId: selectedSiteDeptPair?.site.id,
        site: selectedSiteDeptPair?.site.name,
        filename: form.file.name,
        documentTypeId: selectedDocumentType.id,
        documentType: selectedDocumentType.name,
        documentTypeShort: selectedDocumentType.shortName,
        version: registrationVersion,
        fileHash: fileHash,
        submitTime: currentTimeStamp,
        registrationHash: registrationHash,
        description: form.description,
        providerPosition: selectedProvider.position,
        degreeId: selectedDegree.id,
        departmentId: selectedSiteDeptPair?.department?.id,
        institutionId: selectedSiteDeptPair?.department.institutionId,
        institutionName: selectedSiteDeptInstitution?.name,
        email: user.email,
        isSelfUpload: user.providerId === selectedProvider.id,
      };
      reviewList.push(reg);
    }
    return reviewList;
  };

  // Render function for this whole component. Uses the currentStep as a state machine to display several pages dynamically
  render() {
    if (
      !authService.isAuthorized('Register') &&
      !authService.isAuthorized('Register Self-Upload')
    ) {
      return <NotFound />;
    }

    function CheckValid<Type>(name: string, value: Type): NonNullable<Type> {
      if (value === undefined || value === null) {
        const msg = `render is missing data for '${name}'`;
        console.warn(msg);
        throw new Error(msg);
      }
      return value as NonNullable<Type>;
    }

    const {
      currentStep,
      registerFormList,
      siteDeptPairList,
      selectedSiteDeptPair,
      allowedDocumentTypeList,
    } = this.state;

    if (siteDeptPairList === undefined) {
      console.warn('siteDeptPairList is missing data for render');
      return <ProgressSpinner />;
    } else if (siteDeptPairList == null) {
      return '⚠ Unable to get Site Department Pair List';
    }

    switch (currentStep) {
      case Step.FileSelection:
        // Step 1: file selection
        return (
          <FileSelection
            siteDeptPairList={siteDeptPairList}
            selectedSiteDeptPair={selectedSiteDeptPair}
            onChange={this.handleDeptSiteChange}
            uploadHandler={this.chooseFilesToRegister}
          />
        );
      case Step.CompleteInfo:
        // Step 2: complete registration info
        return (
          <CompleteForm
            documentTypeList={CheckValid(
              'allowedDocumentTypeList',
              allowedDocumentTypeList
            )}
            registerFormList={CheckValid('registerFormList', registerFormList)}
            selectedSiteDeptPair={selectedSiteDeptPair}
            handleFormChange={this.handleFormChange}
            onContinue={this.processFilesToRegister}
            onCancel={() => {
              this.setState({
                currentStep: Step.FileSelection,
                registerFormList: [],
              });
            }}
          />
        );
      case Step.Review:
        // Step 3: review and confirm registration info
        return (
          <Review
            selectedSiteDeptPair={selectedSiteDeptPair}
            registrationList={this.buildRegistrationList()}
            onCancel={() => {
              this.setState({
                currentStep: Step.CompleteInfo,
              });
            }}
            onContinue={async (event: any) => {
              event.target.disabled = true;
              for (const form of CheckValid(
                'registerFormList',
                registerFormList
              )) {
                await this.registerDocument(form);
                await this.uploadDocument(form);
              }
              this.setState({
                currentStep: Step.Result,
              });
            }}
          />
        );
      case Step.Result:
        // Step 4: view result of registration
        return (
          <div className="user-card card">
            {this.buildStatusJSX()}
            <CertificateAccordion
              fileList={CheckValid('registerFormList', registerFormList).map(
                (e) => {
                  return e.file;
                }
              )}
            />
          </div>
        );
      default:
        console.error(
          'Current Step State did not fall within expected values',
          currentStep
        );
        return <NotFound />;
    }
  }
}
