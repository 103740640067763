import React, { Component } from 'react';
import { archiveCoreService } from '../../services/ArchiveCoreServices';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ChainData, Registration } from '../../types/DTOTypes';
import { authService } from '../../services/AuthServices';
import NotFound from '../NotFound';

interface IState {
  loading: boolean;
  dataTableValue: Registration[];
  totalRecords: number;
  chainData?: ChainData;
  expandedRows: any[];
}

interface IProps {}

export class BlockChain extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      dataTableValue: [],
      totalRecords: 0,
      chainData: undefined,
      expandedRows: [],
    };
  }

  async componentDidMount() {
    let user = sessionStorage.getItem('user');
    if (user) {
      this.setState({ loading: true });
      const blockChainInfo = await archiveCoreService.fetchTopTenTransactions();

      const chainData: ChainData = await archiveCoreService.getChain();

      this.setState({
        dataTableValue: blockChainInfo,
        totalRecords: blockChainInfo.length,
        chainData,
        loading: false,
      });
    }
  }

  DateTime = (rowData: Registration) => {
    let date = new Date(rowData.submitTime);
    return <div>{date.toLocaleString()}</div>;
  };

  rowExpansionTemplate = (data: Registration) => {
    /* NOTE:
      Most of the details that are not displayed are redacted in the backend service method 
      because any user that logs in can see this page
    */
    const txLink =
      data.txLink && data.transactionHash
        ? data.txLink + data.transactionHash
        : undefined;
    return (
      <div className="p-grid" style={{ margin: '0.5em' }}>
        <div className="p-col-2 dataHeader">Submit Date: </div>
        <div className="p-col-10 dataBody">{data.submitTime}</div>
        {
          //These are hardcoded, I'm leaving them so that when they actually work they're already here
          /* <div className="p-col-2 dataHeader">Status: </div>
                    <div className="p-col-8 dataBody">{data.status}</div>
                    <div className="p-col-2 dataHeader">Version: </div>
                    <div className="p-col-8 dataBody">{data.version}</div> */
        }
        <div className="p-col-2 dataHeader">File Hash: </div>
        <div
          className="p-col-10 dataBody"
          style={{ fontFamily: 'Lucida Console, Monaco, monospace' }}
        >
          0x{data.fileHash}
        </div>
        <div className="p-col-2 dataHeader">Registration Hash: </div>
        <div
          className="p-col-10 dataBody"
          style={{ fontFamily: 'Lucida Console, Monaco, monospace' }}
        >
          0x{data.registrationHash}
        </div>
        <div className="p-col-2 dataHeader">Sender Address: </div>
        <div
          className="p-col-10 dataBody"
          style={{ fontFamily: 'Lucida Console, Monaco, monospace' }}
        >
          {data.senderAddress}
        </div>
        <div className="p-col-2 dataHeader">Receiver Address: </div>
        <div
          className="p-col-10 dataBody"
          style={{ fontFamily: 'Lucida Console, Monaco, monospace' }}
        >
          {data.receiverAddress}
        </div>
        <div className="p-col-2 dataHeader">Transaction Hash: </div>
        <div
          className="p-col-10 dataBody"
          style={{ fontFamily: 'Lucida Console, Monaco, monospace' }}
        >
          <a href={txLink} target="_blank" rel="noopener noreferrer">
            {data.transactionHash}
            <i
              style={{ marginLeft: '3px' }}
              className="fa fa-external-link fa-lg"
            ></i>
          </a>
        </div>
      </div>
    );
  };

  /*===============================================================================================================*/
  render() {
    const { loading, dataTableValue, chainData } = this.state;

    const chainName = chainData?.chainName || '❓';

    if (
      authService.isAuthorized('Register Self-Upload') &&
      !authService.isAuthorized('SuperUser')
    ) {
      return <NotFound />;
    }

    return (
      <div className="flexi-grid">
        <div className="p-col-12">
          {loading && <ProgressSpinner />}
          <div className="user-card card" style={{ margin: '1em' }}>
            <h2>10 Most Recent Document Registrations on Blockchain</h2>
            <h3>Ethereum ({chainName})</h3>
            {dataTableValue ? (
              <DataTable
                id="fingerPrintMatches"
                value={dataTableValue}
                paginator={false}
                responsiveLayout="scroll"
                resizableColumns={true}
                sortMode="multiple"
                rowExpansionTemplate={this.rowExpansionTemplate}
                expandedRows={this.state.expandedRows}
                onRowToggle={(e: { data: any[] }) =>
                  this.setState({ expandedRows: e.data })
                }
                dataKey="id"
              >
                <Column
                  expander={true}
                  style={{ width: '2em', paddingRight: '2em' }}
                />
                <Column
                  style={{ fontFamily: 'Lucida Console, Monaco, monospace' }}
                  field="submitTime"
                  header="Submit Date"
                  sortable={true}
                  body={this.DateTime}
                />
                <Column
                  style={{ fontFamily: 'Lucida Console, Monaco, monospace' }}
                  field="senderAddress"
                  header="From"
                  sortable={true}
                />
                <Column
                  style={{ fontFamily: 'Lucida Console, Monaco, monospace' }}
                  field="receiverAddress"
                  header="To"
                  sortable={true}
                />
                <Column
                  style={{ fontFamily: 'Lucida Console, Monaco, monospace' }}
                  field="transactionHash"
                  header="Transaction Hash"
                  sortable={true}
                />
              </DataTable>
            ) : (
              <div>No Transactions at this time. Please check back later.</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
