import { ACConfig } from './ACConfig';
import axios from 'axios';

export const DOCUMENT_SIGNING_COMPLETED = 'completed';

export const documentSigningService = {
  getSigningLink,
  getEnvelope,
  listDocuments,
  downloadDocument,
};

/*==========================================================================*/

async function getSigningLink(file: File) {
  const userSession = sessionStorage.getItem('user');
  if (!userSession) {
    console.error('User Session NULL in getSigningLink!');
    return;
  }
  const user = JSON.parse(userSession);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('email', user.email);
  formData.append('name', user.firstName + ' ' + user.lastName);

  try {
    const response = await axios({
      url: ACConfig.apiUrl + '/DocumentSigning/envelopes',
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      method: 'POST',
      data: formData,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function getEnvelope(envelopeId: string) {
  try {
    const userSession = sessionStorage.getItem('user');
    if (!userSession) {
      console.error('User Session NULL in getEnvelope!');
      return;
    }
    const user = JSON.parse(userSession);

    const response = await axios({
      url: `${ACConfig.apiUrl}/DocumentSigning/envelopes/${envelopeId}`,
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function listDocuments(envelopeId: string) {
  try {
    const userSession = sessionStorage.getItem('user');
    if (!userSession) {
      console.error('User Session NULL in listDocuments!');
      return;
    }
    const user = JSON.parse(userSession);

    const response = await axios({
      url: `${ACConfig.apiUrl}/DocumentSigning/envelopes/${envelopeId}/documents`,
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      method: 'GET',
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

async function downloadDocument(envelopeId: string, documentId: string) {
  try {
    const userSession = sessionStorage.getItem('user');
    if (!userSession) {
      console.error('User Session NULL in downloadDocument!');
      return;
    }
    const user = JSON.parse(userSession);

    const response = await axios({
      url: `${ACConfig.apiUrl}/DocumentSigning/envelopes/${envelopeId}/documents/${documentId}`,
      headers: {
        Authorization: 'Bearer ' + user.token,
      },
      method: 'GET',
      responseType: 'blob',
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
