import React, { Component, ReactNode } from 'react';
import { IProvider, ISite } from '../../types/DTOTypes';
import { providerService } from '../../services/ProviderServices';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { BirthDateCalendar } from '../BirthDateCalendar';
import { authService } from '../../services/AuthServices';
import { MultiSelect } from 'primereact/multiselect';
import { Chip } from 'primereact/chip';
import _ from 'lodash';

interface ProviderDetailsProps {
  provider: IProvider;
  sites: ISite[];
  onSuccessfulEdit?: Function;
  onFailedEdit?: Function;
}

interface ProviderDetailsState {
  editProvider: IProvider | null;
}

export class ProviderDetails extends Component<
  ProviderDetailsProps,
  ProviderDetailsState
> {
  constructor(props: ProviderDetailsProps) {
    super(props);
    this.state = {
      editProvider: null,
    };
  }

  handleChange = <P extends keyof IProvider>(
    keyValuePairs: { [key in P]: IProvider[P] },
    callback?: () => void
  ) => {
    if (!this.state.editProvider) {
      return;
    }
    const editProvider: IProvider = {
      ...this.state.editProvider,
      ...keyValuePairs,
    };
    this.setState({ editProvider }, callback);
  };

  startEdit = () => {
    const editProvider: IProvider = {
      ...this.props.provider,
      degrees: [...this.props.provider.degrees],
    };
    this.setState({ editProvider });
  };

  cancelEdit = () => {
    this.setState({ editProvider: null });
  };

  saveEdit = async () => {
    const { editProvider } = this.state;
    if (!editProvider) {
      return;
    }

    const response = await providerService.updateProvider(editProvider);
    if (response?.status === 200) {
      this.props.onSuccessfulEdit?.();
      this.cancelEdit();
    } else {
      this.props.onFailedEdit?.();
      console.error('Failed to edit provider!', response);
    }
  };

  /// https://stackoverflow.com/a/16436975
  private arraysEqual(a: any[], b: any[]) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (var i = 0; i < a.length; ++i) {
      if (!_.isEqual(a[i], b[i])) return false;
    }
    return true;
  }

  checkSaveEligible = (): boolean => {
    const { provider } = this.props;
    const { editProvider } = this.state;
    if (!editProvider) {
      return false;
    }

    // check invalid fields
    if (
      !editProvider.firstName ||
      !editProvider.lastName ||
      !editProvider.position
    ) {
      return false;
    }

    // check empty fields
    if (
      editProvider.firstName.trim().length <= 0 ||
      editProvider.lastName.trim().length <= 0 ||
      editProvider.position.trim().length <= 0 ||
      (editProvider.middleName && editProvider.middleName.trim().length <= 0) ||
      (editProvider.npi && editProvider.npi.trim().length !== 10)
    ) {
      return false;
    }

    // check equality
    if (
      provider.firstName?.trim() !== editProvider.firstName.trim() ||
      provider.middleName?.trim() !== editProvider.middleName?.trim() ||
      provider.lastName?.trim() !== editProvider.lastName.trim() ||
      provider.position?.trim() !== editProvider.position.trim() ||
      provider.birthDate?.trim() !== editProvider.birthDate?.trim() ||
      !this.arraysEqual(provider.sites, editProvider.sites)
    ) {
      return true;
    }

    // default
    return false;
  };

  firstNameTemplate = (): ReactNode => {
    const { provider } = this.props;
    const { editProvider } = this.state;
    if (!editProvider) {
      return provider.firstName;
    }
    return (
      <InputText
        id="firstName"
        value={editProvider.firstName}
        onChange={(e) => this.handleChange({ firstName: e.target.value })}
      />
    );
  };

  middleNameTemplate = (): ReactNode => {
    const { provider } = this.props;
    const { editProvider } = this.state;
    if (!editProvider) {
      return provider.middleName ?? <i>N/A</i>;
    }
    return (
      <InputText
        id="middleName"
        value={editProvider.middleName ?? undefined}
        onChange={(e) => this.handleChange({ middleName: e.target.value })}
      />
    );
  };

  lastNameTemplate = (): ReactNode => {
    const { provider } = this.props;
    const { editProvider } = this.state;
    if (!editProvider) {
      return provider.lastName;
    }
    return (
      <InputText
        id="lastName"
        value={editProvider.lastName}
        onChange={(e) => this.handleChange({ lastName: e.target.value })}
      />
    );
  };

  degreeTemplate = (): ReactNode => {
    const { provider } = this.props;
    const degrees = provider.degrees;
    let string = '';
    for (var i = 0; i < degrees.length; i++) {
      if (degrees[i].hasOwnProperty('degreeName')) {
        string += degrees[i].degreeName + ', ';
      }
    }
    return string.slice(0, -2);
  };

  positionTemplate = (): ReactNode => {
    const { provider } = this.props;
    const { editProvider } = this.state;
    if (!editProvider) {
      return provider.position;
    }
    return (
      <InputText
        id="position"
        value={editProvider.position}
        onChange={(e) => this.handleChange({ position: e.target.value })}
      />
    );
  };

  dateStrToObj = (dateStr: string | null): Date | undefined => {
    if (!dateStr) {
      return undefined;
    }
    if (dateStr === '0001-01-01T00:00:00') {
      return undefined;
    }
    return new Date(dateStr);
  };

  birthDateTemplate = (): ReactNode => {
    const { provider } = this.props;
    const { editProvider } = this.state;

    if (editProvider) {
      return (
        <BirthDateCalendar
          id="birthDate"
          aria-label="Birth Date"
          value={this.dateStrToObj(editProvider.birthDate)}
          onChange={(event) =>
            this.handleChange({
              birthDate: (event.target.value as Date).toISOString(),
            })
          }
        />
      );
    }

    // ignore null and empty date
    if (!provider.birthDate || provider.birthDate === '0001-01-01T00:00:00') {
      return 'Unknown';
    }
    return provider.birthDate.substring(0, 10);
  };

  npiTemplate = (): ReactNode => {
    const { provider } = this.props;
    if (!provider.npi) {
      return <i>N/A</i>;
    }
    return (
      <a
        href={
          'https://npiregistry.cms.hhs.gov/registry/provider-view/' +
          provider.npi
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#0c546a' }}
      >
        {provider.npi}
        <i
          style={{ marginLeft: '3px' }}
          className="fa fa-external-link fa-lg"
        ></i>
      </a>
    );
  };

  sitesTemplate = (): ReactNode => {
    const { provider, sites } = this.props;
    const { editProvider } = this.state;
    if (editProvider) {
      return (
        <MultiSelect
          filter
          filterBy="name"
          dataKey="id"
          display="chip"
          options={sites}
          optionLabel="name"
          value={editProvider.sites}
          onChange={(e) => this.handleChange({ sites: e.value })}
        />
      );
    }
    if (!provider.sites || provider.sites.length === 0) {
      return <i>N/A</i>;
    }
    const chipList: ReactNode[] = [];
    provider.sites.forEach((site) => {
      chipList.push(<Chip label={site.name} />);
    });
    return chipList;
  };

  editButtons = () => {
    const { editProvider } = this.state;
    if (!editProvider) {
      return <Button label="Edit" onClick={this.startEdit} />;
    }
    return (
      <>
        <Button
          label="Save"
          onClick={this.saveEdit}
          disabled={!this.checkSaveEligible()}
        />
        <Button label="Cancel" onClick={this.cancelEdit} />
      </>
    );
  };

  render() {
    const { provider } = this.props;
    const isSuperUser = authService.isAuthorized('SuperUser');
    return (
      <>
        <h1>
          {`${provider.lastName}, ${provider.firstName} ${
            provider.middleName ?? ''
          }`}
        </h1>
        {isSuperUser && this.editButtons()}
        <div className="p-grid p-fluid" style={{ margin: '10px' }}>
          <div className="p-grid">
            <div className="p-col-4 dataHeader">First Name: </div>
            <div className="p-col-8 dataBody">{this.firstNameTemplate()}</div>
            <div className="p-col-4 dataHeader">Middle Name: </div>
            <div className="p-col-8 dataBody">{this.middleNameTemplate()}</div>
            <div className="p-col-4 dataHeader">Last Name: </div>
            <div className="p-col-8 dataBody">{this.lastNameTemplate()}</div>
            <div className="p-col-4 dataHeader">Degree: </div>
            <div className="p-col-8 dataBody">{this.degreeTemplate()}</div>
            <div className="p-col-4 dataHeader">Position: </div>
            <div className="p-col-8 dataBody">{this.positionTemplate()}</div>
            <div className="p-col-4 dataHeader">Birthdate: </div>
            <div className="p-col-8 dataBody">{this.birthDateTemplate()}</div>
            <div className="p-col-4 dataHeader">NPI: </div>
            <div className="p-col-8 dataBody">{this.npiTemplate()}</div>
            <div className="p-col-4 dataHeader">Sites: </div>
            <div className="p-col-8 dataBody">{this.sitesTemplate()}</div>
          </div>
        </div>
      </>
    );
  }
}
