import React, { Component } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DocumentType } from '../../types/DTOTypes';

interface IProps {
  documentTypes: DocumentType[];
  loading: boolean;
  isSuperUser?: boolean;
  onSuccess?: Function;
  onFailure?: Function;
}

interface IState {}

export class DocumentTypeTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading, isSuperUser, documentTypes } = this.props;

    return (
      <>
        {loading ? (
          <ProgressSpinner />
        ) : (
          <>
            <DataTable
              id="documentTypes"
              value={documentTypes}
              paginatorPosition="bottom"
              header={() => {
                return <h1 style={{ display: 'table' }}>Document Types</h1>;
              }}
              paginator
              rows={10}
              responsiveLayout="scroll"
              resizableColumns
              emptyMessage="No records found"
              sortMode="multiple"
              loading={loading}
              filterDisplay={'row'}
            >
              <Column
                header="Document Name"
                field="name"
                filter
                filterPlaceholder="Filter by Name"
                filterMatchMode="contains"
              />
              <Column
                header="Short Name"
                field="shortName"
                filter
                filterPlaceholder="Filter by Short Name"
                filterMatchMode="contains"
              />
            </DataTable>
          </>
        )}
      </>
    );
  }
}
