import { ACConfig } from './ACConfig';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { AddProvidersResult, IProvider, ISite } from '../types/DTOTypes';

export const providerService = {
  addProvider,
  addProvidersFromCSV,
  getProvidersToRegister,
  getProvidersToView,
  getProvider,
  getRegistrationsByProvider,
  updateProvider,
};

async function addProvider(
  firstName: string,
  middleName: string,
  lastName: string,
  npi: string,
  position: string,
  degree: string,
  departmentId: number | null,
  birthDate: Date,
  sites: ISite[]
): Promise<AxiosResponse<number>> {
  const userData = JSON.parse(sessionStorage.getItem('user') || '');

  const providerDTO = {
    firstName,
    middleName,
    lastName,
    npi,
    position,
    degrees: [{ degreeName: degree }],
    departments: [{ id: departmentId }],
    birthDate: birthDate.toJSON(),
    sites,
  };

  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + userData.token,
    },
    data: JSON.stringify(providerDTO),
  };

  const response: AxiosResponse<number> = await axios(
    ACConfig.apiUrl + '/Providers',
    requestOptions
  ).catch((error: AxiosError) => {
    if (!error.response) {
      throw error;
    }
    return error.response;
  });

  return response;
}

async function addProvidersFromCSV(
  file: File
): Promise<AxiosResponse<AddProvidersResult>> {
  const userData = JSON.parse(sessionStorage.getItem('user') || '');

  const formData = new FormData();
  formData.append('file', file);

  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + userData.token,
    },
    data: formData,
  };

  const response: AxiosResponse<AddProvidersResult> = await axios(
    ACConfig.apiUrl + '/Providers/csv',
    requestOptions
  ).catch((error: AxiosError) => {
    console.error(error);
    if (error.response === undefined) {
      throw error;
    }
    return error.response;
  });

  const data = response.data;

  console.assert(
    'newProviderDTOList' in data,
    "📃 we expect data from server to have 'newProviderDTOList'",
    data
  );

  console.assert(
    'errorList' in data,
    "📃 we expect data from server to have 'errorList'",
    data
  );

  console.assert(
    data['errors'].every(
      (el: any) => 'inputLine' in el && 'errorMessage' in el
    ),
    "📃 we expect the errorList to contain objects with 'inputLine' and 'errorMessage'",
    data
  );

  console.assert(
    Array.isArray(data['newProviders']),
    "📃 we expect 'newProviderDTOList' from server to be an array",
    data
  );

  return response;
}

async function getProvidersToView() {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'get',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/Providers/view',
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function getProvidersToRegister(site: ISite | null) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      url: ACConfig.apiUrl + `/Providers/register/${site?.id ?? -1}`,
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function getProvider(id: string | number): Promise<AxiosResponse> {
  let userData = JSON.parse(sessionStorage.getItem('user') || '');

  const response = await axios({
    method: 'GET',
    headers: {
      mode: 'cors',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + userData.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    url: ACConfig.apiUrl + '/Providers/' + id,
  }).catch((error: AxiosError) => {
    console.error(error);
    if (error.response === undefined) {
      throw error;
    }
    return error.response;
  });

  return response;
}

async function getRegistrationsByProvider(
  id: string | number
): Promise<AxiosResponse> {
  let userData = JSON.parse(sessionStorage.getItem('user') || '');

  const response = await axios({
    method: 'GET',
    headers: {
      mode: 'cors',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + userData.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    url: ACConfig.apiUrl + '/Providers/' + id + '/registrations',
  }).catch((error: AxiosError) => {
    console.error(error);
    if (error.response === undefined) {
      throw error;
    }
    return error.response;
  });

  return response;
}

async function updateProvider(providerDTO: IProvider) {
  let x = JSON.parse(sessionStorage.getItem('user') || '');

  const requestOptions: AxiosRequestConfig = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + x.token,
    },
    data: JSON.stringify(providerDTO),
  };

  const response = await axios(
    ACConfig.apiUrl + `/Providers`,
    requestOptions
  ).catch((error: AxiosError) => {
    console.error(`/ArchiveCore/providers`, error);
    if (error.response === undefined) {
      throw error;
    }
    return error.response;
  });

  return response;
}
