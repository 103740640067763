import React, { Component } from 'react';
import { authService } from '../../services/AuthServices';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import { BrowserView } from 'react-device-detect';
import { archiveCoreService } from '../../services/ArchiveCoreServices';
import { Link } from 'react-router-dom';
import { Toast, ToastMessageType } from 'primereact/toast';
import { ChainData } from '../../types/DTOTypes';

interface IProps {
  userHasAuthenticated: (isAuthenticated: boolean) => void;
}

interface IState {
  username?: string;
  usernameError?: string;
  password?: string;
  passwordError?: string;
  authenticationError?: string;

  loading: boolean;
  dirty: boolean;

  chainData?: ChainData;
}

export default class Login extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      dirty: false,
    };
    this.showError = this.showError.bind(this);
  }

  async componentDidMount() {
    const chainData: ChainData = await archiveCoreService.getChain();

    this.setState({
      chainData,
    });
  }

  toast?: Toast | null;

  handleChange = <P extends keyof IState>(prop: P, value: IState[P]) => {
    this.setState({
      ...this.state,
      [prop]: value,
      dirty: true,
    });
  };

  handleFocus = <P extends keyof IState>(prop: P, value: IState[P]) => {
    this.setState({
      ...this.state,
      [prop]: value,
      authenticationError: undefined,
    });
  };

  validateUsername = () => {
    const { username } = this.state;

    if (!username) {
      this.setState({ usernameError: 'Invalid Login: Not Defined' });
      return false;
    }

    // TODO: The frontend should not reject any non-malicious input,
    // and simply pass login info to the backend to check against DB
    if (username.length < 5) {
      this.setState({ usernameError: 'Invalid Login: Too Short' });
      return false;
    }

    if (username.indexOf('@') === -1) {
      this.setState({ usernameError: 'Invalid Login: Use Email' });
      return false;
    }

    return true;
  };

  validatePassword = () => {
    const password = this.state.password?.trim();

    if (!password || password.length <= 0) {
      this.setState({
        passwordError: 'Invalid Password: \nPassword empty or all whitespace!',
      });
      return false;
    }

    return true;
  };

  validate = () => {
    return this.validateUsername() && this.validatePassword();
  };

  onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { username, password } = this.state;

    if (!this.validate()) {
      return;
    }

    if (!username || !password) {
      return;
    }

    this.setState({ loading: true });

    const responseStatusCode: number = await authService.login(
      username,
      password
    );

    if (responseStatusCode === 200) {
      this.props.userHasAuthenticated(true);

      this.setState({
        loading: false,
        authenticationError: undefined,
      });
    } else {
      this.showError();

      this.setState({
        authenticationError: 'Invalid credentials. Please try again',
        loading: false,
        dirty: responseStatusCode !== 401,
      });
    }
  };

  showError = () => {
    const message: ToastMessageType = {
      severity: 'error',
      summary: 'Error Message',
      detail: 'Validation failed',
    };
    this.toast?.show(message);
  };

  /*===============================================*/

  render() {
    const {
      loading,
      dirty,
      chainData,
      authenticationError,
      username,
      usernameError,
      password,
      passwordError,
    } = this.state;

    if (!chainData) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <ProgressSpinner />
        </div>
      );
    }

    const isOnTestNet = !chainData.isMainNet;

    const maxHeight = window.innerHeight + 'px';

    return (
      <div className="login-body">
        <Toast ref={(el) => (this.toast = el)} />
        <div className="login-panel"></div>
        <div className="login-content" style={{ maxHeight, overflow: 'auto' }}>
          <BrowserView>
            <img src="assets/layout/images/logo.png" alt="ArchiveCore Logo" />
            <h1>
              <span>SIGN IN{isOnTestNet && ' - Testnet'}</span>
            </h1>
            {isOnTestNet && (
              <p style={{ width: '40%' }}>
                Transactions made on this site will be sent to{' '}
                {chainData.chainName}, not the Ethereum MainNet.
              </p>
            )}
          </BrowserView>

          {authenticationError && (
            <Message
              severity="error"
              text="Invalid Credentials"
              style={{ float: 'left' }}
            />
          )}

          <form onSubmit={(e) => this.onSubmit(e)}>
            <div className="login-input-wrapper">
              {usernameError && (
                <Message
                  severity="error"
                  text="Invalid Login"
                  style={{ float: 'right' }}
                />
              )}
              <span className="p-float-label">
                <InputText
                  id="username"
                  type="text"
                  autoComplete="username"
                  value={username}
                  onChange={(event) => {
                    this.handleChange('username', event.target.value);
                  }}
                  onFocus={() => {
                    this.handleFocus('usernameError', '');
                  }}
                  className={usernameError ? 'p-error' : ''}
                />
                <label htmlFor="username" style={{ display: 'block' }}>
                  Your Login
                </label>
              </span>
            </div>

            <div className="login-input-wrapper">
              {passwordError && (
                <Message
                  severity="error"
                  text={passwordError}
                  style={{ float: 'right' }}
                />
              )}
              <span className="p-float-label">
                <InputText
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(event) => {
                    this.handleChange('password', event.target.value);
                  }}
                  onFocus={() => {
                    this.handleFocus('passwordError', '');
                  }}
                  className={passwordError ? 'p-error' : ''}
                />
                <label htmlFor="password" style={{ display: 'block' }}>
                  Your Password
                </label>
              </span>
            </div>

            <div>
              <Link to="/forgotPassword" className="p-button-icon">
                Forgot Password?
              </Link>
            </div>

            <div>
              <Button
                type="submit"
                style={{ marginTop: 20 + 'px' }}
                disabled={!dirty}
                label="Submit"
              >
                {loading && <ProgressSpinner />}
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
