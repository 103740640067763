import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { institutionService } from '../../services/InstitutionServices';
import { AxiosResponse } from 'axios';
import { Accordion, AccordionTab } from 'primereact/accordion';

interface IProps {
  onSuccess?: Function;
  onFailure?: Function;
}

interface IState {
  name: string;
  taxID: string;
  response?: AxiosResponse;
}

export class AddInstitution extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      name: '',
      taxID: '',
    };
  }

  handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const result = await institutionService.addInstitution(
      this.state.name.trim(),
      this.state.taxID.trim()
    );

    if (result?.status === 200) {
      if (this.props.onSuccess !== undefined) {
        this.props.onSuccess();
      }
    } else {
      if (this.props.onFailure !== undefined) {
        this.props.onFailure();
      }
    }

    this.setState({
      name: '',
      taxID: '',
    });
  };

  checkFormComplete = () => {
    const name = this.state.name.trim();
    const taxID = this.state.taxID.trim();
    if (name.length <= 0 || taxID.length !== 9 || !/^[0-9]+$/.test(taxID)) {
      return false;
    }
    return true;
  };

  onHide = () => {
    this.setState({
      name: '',
      taxID: '',
    });
  };

  render() {
    const style = { display: 'block', marginTop: '20px', width: '175px' };
    const isFormComplete = this.checkFormComplete();
    const { name, taxID } = this.state;

    return (
      <Accordion>
        <AccordionTab header="Add New Institution">
          <div style={{ display: 'inline-block' }}>
            <span className="p-float-label">
              <InputText
                style={style}
                id="name"
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.setState({
                    name: event.target.value,
                  });
                }}
              ></InputText>
              <label htmlFor="name">Name</label>
            </span>
          </div>
          <div style={{ display: 'inline-block' }}>
            <span className="p-float-label">
              <InputText
                style={style}
                id="taxID"
                value={taxID}
                keyfilter="pint"
                maxLength={9}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  this.setState({
                    taxID: event.target.value,
                  });
                }}
              ></InputText>
              <label htmlFor="taxID">{'TaxID (9-digit number)'}</label>
            </span>
          </div>
          <Button
            className="p-button-icon"
            icon="pi pi-check"
            disabled={!isFormComplete}
            onClick={this.handleClick}
            label="Add Institution"
          />
        </AccordionTab>
      </Accordion>
    );
  }
}
