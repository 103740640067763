import { Component, ReactNode } from 'react';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import {
  DataTable,
  DataTableExpandedRows,
  DataTableProps,
  DataTableRowEventParams,
  DataTableRowExpansionTemplate,
} from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IProvider, ISite } from '../../types/DTOTypes';
import { Link } from 'react-router-dom';
import { ProviderDetails } from '../ProviderDetails';
import { institutionService } from '../../services/InstitutionServices';

export interface ProviderTableProps extends DataTableProps {
  providers: IProvider[];
  onEdit?: Function;
  hideFilters?: boolean;
}

interface IState {
  expandedRows: DataTableExpandedRows;
  sites: ISite[] | null;
}

export class ProviderTable extends Component<ProviderTableProps, IState> {
  constructor(props: ProviderTableProps) {
    super(props);
    this.state = {
      expandedRows: {},
      sites: null,
    };
  }

  async componentDidMount() {
    const sites = await institutionService.getSites();
    this.setState({ sites });
  }

  birthDateTemplate = (provider: IProvider) => {
    const { birthDate } = provider;
    // ignore null and empty date
    if (!birthDate || birthDate === '0001-01-01T00:00:00') {
      return undefined;
    }
    return birthDate.substring(0, 10);
  };

  onRowExpand = (e: DataTableRowEventParams) => {
    const provider: IProvider = e.data;
    this.setState({ expandedRows: { [provider.uuid]: true } });
  };

  onRowCollapse = (e: DataTableRowEventParams) => {
    this.setState({ expandedRows: {} });
  };

  rowExpansionTemplate = (
    rowData: IProvider,
    options: DataTableRowExpansionTemplate
  ): ReactNode => {
    return (
      <>
        <Link
          to={'/providers/details/' + rowData.id}
          style={{ color: '#0c546a' }}
        >
          <Button
            label="View Full Details"
            iconPos="right"
            icon="pi pi-bars"
            tooltip="View Full Details"
          />
        </Link>
        <ProviderDetails
          provider={rowData}
          sites={this.state.sites ?? []}
          onSuccessfulEdit={this.props.onEdit}
        />
      </>
    );
  };

  render() {
    const {
      providers,
      onEdit,
      hideFilters,
      ...passedInProps
    }: ProviderTableProps = this.props;

    if (passedInProps.loading) {
      return <ProgressSpinner />;
    }

    // set deafult props and passed in props
    const dataTableProps: DataTableProps = {
      id: 'providers',
      dataKey: 'uuid',
      value: providers,
      paginatorPosition: 'bottom',
      header: <h1 style={{ display: 'table' }}>Providers</h1>,
      paginator: true,
      rows: 15,
      responsiveLayout: 'stack',
      autoLayout: true,
      resizableColumns: true,
      emptyMessage: 'No records found',
      sortMode: 'multiple',
      filterDisplay: 'row',
      expandedRows: this.state.expandedRows,
      onRowExpand: this.onRowExpand,
      onRowCollapse: this.onRowCollapse,
      rowExpansionTemplate: this.rowExpansionTemplate,
      ...passedInProps,
    };

    return (
      <DataTable {...dataTableProps}>
        {dataTableProps.rowExpansionTemplate && <Column expander />}
        <Column
          header="Full Name"
          field="fullNameWDegrees"
          filter={!hideFilters}
          filterPlaceholder="Filter by Full Name"
          filterMatchMode="contains"
          filterField="fullNameWDegrees"
        />
        <Column
          header="Position"
          field="position"
          filter={!hideFilters}
          filterPlaceholder="Filter by Position"
          filterMatchMode="contains"
          filterField="position"
        />
        <Column
          header="Birth Date"
          field="birthDate"
          body={this.birthDateTemplate}
          filter={!hideFilters}
          filterPlaceholder="YYYY-MM-DD"
          filterMatchMode="contains"
          filterField="birthDate"
        />
        <Column
          header="NPI"
          field="npi"
          filter={!hideFilters}
          filterPlaceholder="Filter by NPI"
          filterMatchMode="contains"
          filterField="npi"
        />
      </DataTable>
    );
  }
}
