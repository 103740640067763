import React, { Component } from 'react';
import { Permission } from '../../types/DTOTypes';
import { PermissionsTable } from '../PermissionsTable';

interface IProps {
  permissions: Permission[];
  loading?: boolean;
  onRemoveSuccess?: () => any;
  onRemoveFailure?: () => any;
}

interface IState {}

export class OtherPermissionsTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  nameTemplate(rowData: Permission) {
    return (
      <div style={{ textAlign: 'center' }}>{rowData.permissionTypeName}</div>
    );
  }

  render() {
    const { loading, permissions, onRemoveSuccess, onRemoveFailure } =
      this.props;

    return (
      <PermissionsTable
        id="other"
        header="Other Permissions"
        permissions={permissions}
        nameTemplate={this.nameTemplate}
        onRemoveSuccess={onRemoveSuccess}
        onRemoveFailure={onRemoveFailure}
        loading={loading}
      />
    );
  }
}
