import React, { Component } from 'react';
import { Certificate } from '../Certificate';
import { Button } from 'primereact/button';
import { archiveCoreService } from '../../services/ArchiveCoreServices';
import { ProgressSpinner } from 'primereact/progressspinner';
import { BlockChainInfo, Registration } from '../../types/DTOTypes';
import { Toast } from 'primereact/toast';

interface IProps {
  certInfo?: Registration;
  requester?: string;
}

interface IState {
  certLoading?: boolean;
  certLoaded?: boolean;
  certInfo?: Registration | null;
  blockConfirmed?: boolean;
  blockPending?: boolean;
  notSubmitted?: boolean;
  blockQryError?: boolean;
  blockChainInfo?: BlockChainInfo;
}

export class RegistrationStatus extends Component<IProps, IState> {
  toast: Toast | null;

  constructor(props: IProps) {
    super(props);
    this.state = {
      certLoading: false,
      certLoaded: false,
      certInfo: undefined,
      blockConfirmed: false,
      blockPending: false,
      notSubmitted: false,
      blockQryError: false,
      blockChainInfo: undefined,
    };

    this.toast = null;
  }

  componentDidMount = async () => {
    await this.refreshStatus();
  };

  refreshStatus = async () => {
    this.setState({
      certLoading: true,
    });

    var transactionStatus = 'Submission Pending';

    const transactionHash = this.props?.certInfo?.transactionHash;
    if (this.props.certInfo && transactionHash) {
      transactionStatus = await archiveCoreService.getTransactionStatus(
        transactionHash
      );
    }

    let blockConfirmed = false;
    let pending = false;
    let notSubmitted = false;
    let error = false;

    if (transactionStatus === 'Complete') {
      //If transaction is not pending
      blockConfirmed = true;
    } else if (transactionStatus === 'Submitted to Blockchain') {
      pending = true;
    } else if (transactionStatus === 'Submission Pending') {
      notSubmitted = true;
    } else {
      console.log('Error in querying blockchain');
      error = true;
    }

    var blockChainInfo = null;
    if (blockConfirmed && transactionHash) {
      blockChainInfo = await archiveCoreService.queryBlockChainInfo(
        transactionHash
      );
    }

    this.setState({
      blockChainInfo: blockChainInfo,
      certLoaded: true,
      certLoading: false,
      blockConfirmed: blockConfirmed,
      blockPending: pending,
      notSubmitted: notSubmitted,
      blockQryError: error,
    });
  };

  copyToClipBoard = (data: string) => {
    window.navigator.clipboard.writeText(data);
    this.toast?.show({
      severity: 'success',
      summary: 'Copied to Clipboard',
      detail: 'Registration String Copied',
    });
  };

  private getTxnLink(blockConfirmed?: boolean, certInfo?: Registration) {
    if (
      blockConfirmed &&
      certInfo &&
      certInfo.txLink &&
      certInfo.transactionHash
    ) {
      return certInfo.txLink + certInfo.transactionHash;
    }

    return undefined;
  }

  render() {
    const certInfo = this.props.certInfo;
    const blockConfirmed = this.state.blockConfirmed;
    const blockPending = this.state.blockPending;
    const notSubmitted = this.state.notSubmitted;
    const blockQryError = this.state.blockQryError;

    const txnLink = this.getTxnLink(blockConfirmed, certInfo);

    return (
      <React.Fragment>
        <iframe
          id="iframeforprinting"
          title="iframeforprinting"
          style={{ height: 0, width: 0, position: 'absolute' }}
        ></iframe>
        {this.state.certLoading && (
          <ProgressSpinner style={{ display: 'block' }} />
        )}

        {blockPending &&
          !this.state.certLoading && ( //Transaction pending
            <div className="user-card card" style={{ margin: '1em' }}>
              <Toast ref={(el) => (this.toast = el)}></Toast>
              <h1>
                <i className="pi pi-clock" />
                Transaction Pending
              </h1>
              <p>
                Don't worry, the registration was recorded in the ArchiveCore
                database and sent to the Ethereum Blockchain. Transactions on
                the blockchain are not instantaneous. In most cases they take
                only a few minutes, but sometimes can take 30 minutes or more.
              </p>
              <Button
                label="Refresh to check transaction status"
                icon="pi pi-replay"
                onClick={this.refreshStatus}
                style={{ display: 'block' }}
              />
              <p>Registration Details:</p>
              <table className="card" width="90%">
                <tbody>
                  <tr className="dataTitle">
                    <th>Parameter</th>
                    <th>Value</th>
                  </tr>
                  <tr>
                    <td className="dataHeader">File Hash</td>
                    <td className="dataBody">{certInfo?.fileHash}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Version</td>
                    <td className="dataBody">{certInfo?.version}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Provider</td>
                    <td className="dataBody">
                      {certInfo?.providerFullNameWTitle}
                    </td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Position</td>
                    <td className="dataBody">{certInfo?.providerPosition}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Submitter</td>
                    <td className="dataBody">{certInfo?.submittedBy}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Site</td>
                    <td className="dataBody">{certInfo?.site ?? 'NO SITE'}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Filename</td>
                    <td className="dataBody">{certInfo?.filename}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Document Type</td>
                    <td className="dataBody">{certInfo?.documentType}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Description</td>
                    <td className="dataBody">{certInfo?.description}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Timestamp</td>
                    <td className="dataBody">{certInfo?.submitTime}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Registration String</td>
                    <td className="dataBody">
                      {certInfo?.registrationString}
                      <span style={{ float: 'right', width: '5%' }}>
                        <Button
                          id="clipboardCopy"
                          type="button"
                          icon="pi pi-clone"
                          tooltip="Copy to clipboard"
                          style={{
                            color: '#000000',
                            backgroundColor: 'transparent',
                            border: 'none',
                          }}
                          onClick={() =>
                            this.copyToClipBoard(
                              certInfo?.registrationString ?? ''
                            )
                          }
                        ></Button>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Registration Hash</td>
                    <td className="dataBody">{certInfo?.registrationHash}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Blockchain</td>
                    <td className="dataBody">{certInfo?.blockchain}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Chain ID</td>
                    <td className="dataBody">{certInfo?.chainId}</td>
                  </tr>
                  <tr>
                    <td className="dataHeader">Transaction Hash</td>
                    <td className="dataBody">
                      {certInfo?.transactionHash}
                      <a
                        href={txnLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i
                          style={{ marginLeft: '3px' }}
                          className="fa fa-external-link fa-lg"
                        ></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

        {notSubmitted &&
          !this.state.certLoading && ( //Transaction not submitted yet
            <div className="user-card card" style={{ margin: '1em' }}>
              <Toast ref={(el) => (this.toast = el)}></Toast>
              <h1>
                <i className="pi pi-clock" />
                Transaction Not Submitted
              </h1>
              <p>
                Your registration is currently enqueued and has not yet been
                submitted to the blockchain or saved to the database. This is
                because blockchain transactions must be submitted sequentially
                and one at a time. Your transaction will be processed as soon as
                all other transactions enqueued before it have completed. This
                page will be unable to access the transaction's status from this
                point onward. If you wish to check the status of your
                transaction, upload your file to the{' '}
                <a href="/verify"> verify page </a>
              </p>
            </div>
          )}

        {blockQryError && !this.state.certLoading && (
          <React.Fragment>
            <p style={{ fontWeight: 'bold' }}>
              <i
                className="pi pi-times"
                style={{ fontSize: '2em', color: 'red' }}
              ></i>
              An error occurred when querying the blockchain and the status of
              this transaction could not be determined.
            </p>
            <p>
              Your document was still registered in the ArchiveCore system and
              on the blockchain. Check the
              <a href="/verify"> verify page </a>to see the Certificate of
              Authenticity for this document, or click the "Refresh Status"
              button. This is likely a server error, if it persists and the
              Certificate of Authenticity is not found on the
              <a href="/verify"> verify page, </a>contact support.
            </p>
            <p style={{ fontWeight: 'bold' }}>
              {`Error Code: ${this.state.blockChainInfo}`}
            </p>
            <Button
              label="Refresh Status"
              icon="pi pi-replay"
              onClick={this.refreshStatus}
              style={{ display: 'block' }}
            />
          </React.Fragment>
        )}

        {blockConfirmed &&
          !this.state.certLoading && ( //Transaction confirmed
            <>
              <Button
                id="printButton"
                label="Print"
                style={{ margin: '0.25em' }}
                onClick={() => {
                  var content = document.getElementById('certificate');
                  if (!content) return;
                  var printWindow = (
                    document.getElementById(
                      'iframeforprinting'
                    ) as HTMLIFrameElement
                  )?.contentWindow;
                  if (!printWindow) return;
                  printWindow.document.open().write(content.innerHTML);
                  printWindow.document.close();
                  printWindow.focus();
                  printWindow.print();
                }}
              />
              <div id="certificate">
                <Certificate
                  certInfo={this.props.certInfo}
                  blockChainInfo={this.state.blockChainInfo}
                  requester={this.props.requester}
                />
              </div>
            </>
          )}
      </React.Fragment>
    );
  }
}
