import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { userService } from '../../services/UserServices';

interface IProps {}

interface IState {
  value: string;
  submitted: boolean;
}

export default class ForgotPassword extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      value: '',
      submitted: false,
    };
  }

  handleSubmit = (event: React.MouseEvent<HTMLFormElement>) => {
    event.preventDefault();
    const link = userService.requestResetToken(this.state.value);
    this.setState({ submitted: true });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value });
  };

  render() {
    if (!this.state.submitted) {
      return (
        <div className="exception-body" style={{ background: '#12befc' }}>
          <div className="exception-panel" />

          <div className="exception-content">
            <img src="assets/layout/images/logo.png" alt="babylon-layout" />
            <h1>
              <span className="exception-name">FORGOT</span> PASSWORD
            </h1>
            <p>Please enter your email</p>
            <form onSubmit={this.handleSubmit}>
              <div className="login-input-wrapper">
                <span className="p-float-label">
                  <InputText
                    name="email"
                    value={this.state.value}
                    onChange={this.handleChange}
                  />
                </span>
              </div>
              <br />
              <Button type="submit" label="Send Password Reset Link" />
            </form>
          </div>
        </div>
      );
    } else {
      return (
        <div className="exception-body" style={{ background: '#12befc' }}>
          <div className="exception-panel" />

          <div className="exception-content">
            <img src="assets/layout/images/logo.png" alt="babylon-layout" />
            <h1>
              <span className="exception-name">FORGOT</span> PASSWORD
            </h1>
            <p>
              If an account associated with the given email exists, a link to
              reset the account has been sent to that email.
              <br />
              Please check your spam folder if it cannot be found within a few
              minutes.
            </p>
          </div>
        </div>
      );
    }
  }
}
