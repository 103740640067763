import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface IProps {
  header: string;
  buttonText: string;
  onButtonClick: any;
  visible: boolean;
  closeOnEscape: boolean;
  onHide: any;
  message: string;
}

interface IState {}

export class InfoDialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const {
      header,
      buttonText,
      onButtonClick,
      visible,
      closeOnEscape,
      onHide,
      message,
    } = this.props;

    return (
      <Dialog
        header={header}
        footer={
          <div>
            <Button
              label={buttonText}
              icon="pi pi-check"
              onClick={onButtonClick}
            />
          </div>
        }
        visible={visible}
        closeOnEscape={closeOnEscape}
        onHide={onHide}
      >
        {message}
      </Dialog>
    );
  }
}
