import axios, { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import { AxiosRequestConfig } from 'axios';

export const axiosService = {
  getUserSessionToken,
  callAxios,
  transformErrors,
  justError,
};

function getUserSessionToken(): string {
  const sessionUser = sessionStorage.getItem('user');

  if (!sessionUser) {
    throw new Error('👤 Unable to retrieve user session from storage');
  }

  const sessionUserJson = JSON.parse(sessionUser);

  if ('token' in sessionUserJson) {
    return sessionUserJson.token;
  }

  throw new Error('💰 Stored User Session missing token');
}

function callAxios<Type>(
  configOrUrl: string | AxiosRequestConfig,
  config?: AxiosRequestConfig
): AxiosPromise<Type> {
  if (typeof configOrUrl === 'string') {
    config = config || {};
    config.url = configOrUrl;
  } else {
    config = configOrUrl || {};
  }

  const token = getUserSessionToken();

  const defaultConfig: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };

  const c = { ...defaultConfig, ...config };

  if (!c.url) {
    throw new Error('📞 Axios call missing url');
  }
  if (!c.method) {
    throw new Error('📞 Axios call missing method');
  }

  const result = axios(c);

  return result;
}

function transformErrors(error: AxiosError): AxiosResponse<Error> {
  if (error.response) {
    console.error('💥', error, error.response);
    return error.response;
  }

  console.error('🚫', error, error.toJSON());
  throw error;
}

function justError<Type>(error: AxiosError): AxiosResponse<Type> {
  if (error.response) {
    console.error('💥', error, error.response);
    throw error;
  }

  console.error('🚫', error, error.toJSON());
  throw error;
}
