import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Message } from 'primereact/message';
import { InfoDialog } from '../../components/InfoDialog';
import { Password } from 'primereact/password';
import { RouteComponentProps } from 'react-router-dom';
import { authService } from '../../services/AuthServices';
import NotFound from '../NotFound';
import { IUser, ISite, IProvider } from '../../types/DTOTypes';
import AdminBackButton from '../../components/AdminBackButton';
import { userService } from '../../services/UserServices';
import { institutionService } from '../../services/InstitutionServices';
import { providerService } from '../../services/ProviderServices';

interface IState {
  originalUser?: IUser;
  firstName: string;
  middleName: string | null;
  lastName: string;
  email: string;
  password: string | null;
  passwordConfirm: string;
  site?: ISite;
  selectedProvider: IProvider | null;
  passwordError: boolean;
  emailError: boolean;
  sites: ISite[];
  providers: IProvider[];
  failureMessage?: string | null;
}

interface MatchParams {
  userId?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

export class EditUser extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      passwordError: false,
      emailError: false,
      sites: [],
      providers: [],
      selectedProvider: null,
    };
  }

  handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const { password, site, selectedProvider } = this.state;
    const firstName = this.state.firstName.trim();
    const middleName = this.state.middleName?.trim() ?? null;
    const lastName = this.state.lastName.trim();
    const email = this.state.email.trim();

    const originalUser = this.state.originalUser as IUser;

    const result = await userService.updateUser(
      originalUser.id,
      lastName,
      firstName,
      middleName,
      email,
      password,
      site ? site.id.toString() : '',
      selectedProvider
    );

    if (result.status === 200) {
      this.setState({
        failureMessage: null,
      });
    } else {
      const failureMessage: string =
        result?.data?.toString() ??
        'An unknown error occurred while trying to add user.';

      this.setState({
        failureMessage,
      });
    }
  };

  async initProviders() {
    // Get the list of all providers in the system TODO: maybe there should be a different call for SuperUsers that gets ALL providers
    const allProviders = await providerService.getProvidersToView();

    // filter them by which providers match the first and last name of the current user
    const linkableProviders = allProviders.filter((provider: IProvider) => {
      return (
        provider.firstName?.toLowerCase().trim() ===
          this.state.firstName.toLowerCase().trim() &&
        provider.lastName?.toLowerCase().trim() ===
          this.state.lastName.toLowerCase().trim()
      );
    });
    // set the state to reflect the new list of providers (and clear the current selection)

    this.setState({
      providers: linkableProviders,
      selectedProvider: null,
    });
  }

  async componentDidMount() {
    const userIdText = this.props.match.params.userId;

    if (!userIdText) {
      console.warn('Missing this.props.match.params.userId');
      return;
    }

    const userId = parseInt(userIdText);

    const userObj = await userService.getUserFromId(userId);

    const site = await institutionService.getSite(userObj.siteId);

    if (userObj.providerId <= 0) {
      this.setState({
        originalUser: userObj,
        firstName: userObj.firstName,
        middleName: userObj.middleName,
        lastName: userObj.lastName,
        email: userObj.email,
        site,
        selectedProvider: null,
      });
    } else {
      const providerResponse = await providerService.getProvider(
        userObj.providerId
      );

      const validProvider =
        providerResponse.status === 200 && userObj.providerId > 0;

      const selectedProvider: IProvider | null = validProvider
        ? providerResponse.data
        : null;

      this.setState({
        originalUser: userObj,
        firstName: userObj.firstName,
        middleName: userObj.middleName,
        lastName: userObj.lastName,
        email: userObj.email,
        site,
        selectedProvider,
      });
    }

    const authorizedSites = await institutionService.getSites();
    this.setState({
      sites: authorizedSites,
    });

    await this.initProviders();
  }

  handleChange =
    (name: keyof IState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const eventValue = event.target.value;
      this.setState(
        (state) => ({
          ...state,
          [name]: eventValue,
        }),
        () => {
          if (name === 'password' || name === 'passwordConfirm') {
            this.checkPassword();
          }
          // If the component changes is one of the names, refresh the linkable providers list
          if (
            name === 'firstName' ||
            name === 'middleName' ||
            name === 'lastName'
          ) {
            this.initProviders();
          }
        }
      );
    };

  checkPassword() {
    if (this.state.passwordConfirm !== this.state.password) {
      this.setState({ passwordError: true });
    } else {
      this.setState({ passwordError: false });
    }
  }

  checkEmail = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value.indexOf('@') === -1) {
      this.setState({ emailError: true });
    } else {
      this.setState({ emailError: false });
    }
  };

  checkComplete = () => {
    const {
      password,
      passwordConfirm,
      selectedProvider,
      passwordError,
      emailError,
    } = this.state;
    const firstName = this.state.firstName.trim();
    const middleName = this.state.middleName?.trim() ?? null;
    const lastName = this.state.lastName.trim();
    const email = this.state.email.trim();
    const originalUser = this.state.originalUser as IUser;
    const site = this.state.site as ISite | null;
    let providerId = selectedProvider ? selectedProvider.id : 0;

    return (
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      site !== null &&
      !emailError &&
      !passwordError &&
      ((password !== '' && passwordConfirm !== '') ||
        !(
          firstName === originalUser.firstName.trim() &&
          lastName === originalUser.lastName.trim() &&
          middleName === originalUser.middleName?.trim() &&
          email === originalUser.email.trim() &&
          site.id === originalUser.siteId &&
          providerId === originalUser.providerId
        ))
    );
  };

  onHide = () => {
    const success = this.state.failureMessage === null;

    this.setState({
      failureMessage: undefined,
    });

    if (success) {
      this.props.history.push('/administration');
    }
  };

  render() {
    const style = { display: 'block', marginTop: '20px', width: '175px' };

    const complete = this.checkComplete();
    const isAuthZ = authService.isAuthorized('SuperUser');

    const success = this.state.failureMessage === null;

    if (this.state.originalUser != null) {
      return (
        <div>
          {isAuthZ && (
            <div className="flexi-grid">
              <div className="p-col-12">
                <div className="card">
                  <AdminBackButton />
                  <h1>Edit User</h1>
                  <div>
                    <span className="p-float-label">
                      <InputText
                        style={style}
                        id="first"
                        value={this.state.firstName}
                        onChange={this.handleChange('firstName')}
                      ></InputText>
                      <label htmlFor="first">First Name</label>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        style={style}
                        id="middle"
                        value={this.state.middleName ?? undefined}
                        onChange={this.handleChange('middleName')}
                      ></InputText>
                      <label htmlFor="middle">Middle Name (Optional)</label>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        style={style}
                        id="last"
                        value={this.state.lastName}
                        onChange={this.handleChange('lastName')}
                      ></InputText>
                      <label htmlFor="last">Last Name</label>
                    </span>
                    <span
                      style={{ marginTop: '20px' }}
                      className="p-float-label"
                    >
                      <InputText
                        style={{ width: '175px' }}
                        id="email"
                        keyfilter="email"
                        value={this.state.email}
                        onBlur={this.checkEmail}
                        onChange={this.handleChange('email')}
                      ></InputText>
                      <label htmlFor="email">Email</label>
                      {this.state.emailError && (
                        <Message
                          severity="error"
                          text="Invalid Email"
                          style={{ marginLeft: '10px' }}
                        />
                      )}
                    </span>
                    <span className="p-float-label">
                      <Password
                        style={style}
                        id="password"
                        type="password"
                        autoComplete="new-password"
                        value={this.state.password ?? undefined}
                        onChange={this.handleChange('password')}
                      ></Password>
                      <label htmlFor="password">Password</label>
                    </span>
                    <span
                      style={{ marginTop: '20px' }}
                      className="p-float-label"
                    >
                      <InputText
                        style={{ width: '175px' }}
                        id="passwordConfirm"
                        type="password"
                        autoComplete="new-password"
                        value={this.state.passwordConfirm}
                        onChange={this.handleChange('passwordConfirm')}
                      ></InputText>
                      <label htmlFor="passwordConfirm">Repeat Password</label>
                      {this.state.passwordError && (
                        <Message
                          severity="error"
                          text="Passwords don't match"
                          style={{ marginLeft: '10px' }}
                        />
                      )}
                    </span>
                    <Dropdown
                      tooltip="Site"
                      optionLabel="name"
                      placeholder="Choose Site"
                      onChange={(e) => {
                        this.setState({ site: e.value });
                      }}
                      value={this.state.site}
                      options={this.state.sites}
                    ></Dropdown>
                    <Dropdown
                      tooltip="Provider"
                      optionLabel="fullNameWDegrees"
                      placeholder="Choose Linked Provider"
                      onChange={(e) => {
                        this.setState({ selectedProvider: e.value });
                      }}
                      value={this.state.selectedProvider}
                      options={this.state.providers}
                    ></Dropdown>
                    <Button
                      style={{ display: 'block', marginTop: '20px' }}
                      className="p-button-icon"
                      icon="pi pi-check"
                      disabled={false}
                      onClick={() => {
                        this.setState({ selectedProvider: null });
                      }}
                      label="Reset Linked Provider"
                    />
                    <Button
                      style={{ display: 'block', marginTop: '20px' }}
                      className="p-button-icon"
                      icon="pi pi-check"
                      disabled={!complete}
                      onClick={this.handleClick}
                      label="Submit"
                    />
                    <InfoDialog
                      header="Success!"
                      buttonText="Ok"
                      onButtonClick={this.onHide}
                      visible={success}
                      closeOnEscape={true}
                      onHide={this.onHide}
                      message="User Successfully Updated"
                    />
                    <InfoDialog
                      header="Error"
                      buttonText="Ok"
                      onButtonClick={this.onHide}
                      visible={!!this.state.failureMessage}
                      closeOnEscape={true}
                      onHide={this.onHide}
                      message={this.state.failureMessage ?? 'Unknown Error'}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isAuthZ && <NotFound />}
        </div>
      );
    } else {
      return (
        <div>
          {isAuthZ && (
            <div className="flexi-grid">
              <div className="p-col-12">
                <div className="card">
                  <AdminBackButton />
                  <h1>User Not Found</h1>
                </div>
              </div>
            </div>
          )}
          {!isAuthZ && <NotFound />}
        </div>
      );
    }
  }
}
