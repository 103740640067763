import React, { Component } from 'react';
import NotFound from '../NotFound';
import { authService } from '../../services/AuthServices';
import { IProvider } from '../../types/DTOTypes';
import { providerService } from '../../services/ProviderServices';
import { ProviderTable } from '../../components/ProviderTable';
import { Tooltip } from 'primereact/tooltip';

interface IProps {}

interface IState {
  providers: IProvider[];
  loading: boolean;
  dataTableSelection?: unknown;
}

export class Providers extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      providers: [],
      loading: true,
    };
  }

  componentDidMount = async () => {
    var providers = await providerService.getProvidersToView();
    this.setState({
      providers: providers,
      loading: false,
    });
  };

  render() {
    const isAuthZ =
      authService.isAuthorized('ViewCertificate') ||
      authService.isAuthorized('Register');

    const { loading, providers } = this.state;

    if (!isAuthZ) {
      return <NotFound />;
    }

    return (
      <div className="card">
        <ProviderTable
          loading={loading}
          providers={providers}
          onEdit={() => this.componentDidMount()}
          header={
            <h3 style={{ display: 'table' }}>
              Providers{' '}
              <Tooltip
                target=".document-type-tooltip"
                style={{ maxWidth: '20rem' }}
              />
              <i
                className="document-type-tooltip pi pi-question-circle"
                data-pr-tooltip="
                          This table includes all providers you are permitted to view.
                        "
                data-pr-position="right"
                data-pr-my="left center-2"
                style={{ cursor: 'pointer' }}
              />
            </h3>
          }
        />
      </div>
    );
  }
}
