import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

export enum Icons {
  userPlus = 'pi pi-user-plus',
  arrowLeft = 'pi pi-arrow-left',
}

interface Props {
  to: string;
  label: string;
  icon?: Icons;
}

const LinkButton: FunctionComponent<Props> = (props) => (
  <Link to={props.to}>
    <Button label={props.label} icon={props.icon} />
  </Link>
);
export default LinkButton;
