import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { Component } from 'react';
import { archiveCoreService } from '../../services/ArchiveCoreServices';
import { IUser, Registration } from '../../types/DTOTypes';
import { RegistrationStatus } from '../RegistrationStatus';

interface IProps {
  fileList: File[];
}

interface IState {
  activeIndex?: number;
  user: IUser;
  certificateMap: Map<File, Registration>;
}

export class CertificateAccordion extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const userJSON = sessionStorage.getItem('user');
    const user = userJSON ? JSON.parse(userJSON) : undefined;
    this.state = {
      user,
      certificateMap: new Map(),
    };
  }

  // Get the certificaton information for a file that has been registered
  getCertInfo = async (file: File) => {
    const tempMap = new Map(this.state.certificateMap);
    // fetch the verification of the file
    const response = await archiveCoreService.fetchVerificationFile(file);
    if (!response) {
      console.error('Could not find verification for file: ' + file.name);
      return;
    }
    const certInfo = response[0];
    // Update the registerForm with the most recent certification
    tempMap.set(file, certInfo);
    this.setState({ certificateMap: tempMap });
  };

  certAccordionTab = (file: File) => {
    const { user, certificateMap } = this.state;
    const certInfo = certificateMap.get(file);
    return (
      <AccordionTab
        header={`Show certificate for ${file.name}`}
        key={file.name}
      >
        <Button
          label="Save Document"
          icon="pi pi-download"
          onClick={() => {
            archiveCoreService.sendFile(file, file.name);
          }}
          style={{
            marginBottom: '15px',
            marginRight: '10px',
          }}
        />
        {!certInfo && <ProgressSpinner style={{ display: 'block' }} />}
        {certInfo && (
          <>
            <p>
              You do not need to save this certificate. It can be accessed
              anytime by going to the <a href="/verify">verify page </a>
              and uploading either the origial document or this document.
            </p>
            <RegistrationStatus
              requester={user?.firstName + ' ' + user?.lastName}
              certInfo={certInfo}
            />
          </>
        )}
      </AccordionTab>
    );
  };

  render() {
    const accordionTabsJSX: JSX.Element[] = [];
    const { fileList } = this.props;

    for (const file of fileList) {
      accordionTabsJSX.push(this.certAccordionTab(file));
    }

    return (
      <>
        <Button
          label="Save All Documents"
          icon="pi pi-download"
          onClick={() => {
            for (const file of fileList) {
              archiveCoreService.sendFile(file, file.name);
            }
          }}
          style={{
            marginBottom: '15px',
            marginRight: '10px',
          }}
        />
        <Accordion
          activeIndex={this.state.activeIndex}
          onTabChange={(e) => this.setState({ activeIndex: e.index })}
          onTabOpen={(e) => {
            this.getCertInfo(fileList[e.index]);
          }}
        >
          {accordionTabsJSX}
        </Accordion>
      </>
    );
  }
}
