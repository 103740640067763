import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ISite } from '../../types/DTOTypes';
import { institutionService } from '../../services/InstitutionServices';
import { InputText } from 'primereact/inputtext';
import { ProviderTable } from '../ProviderTable';

interface IProps {
  sites: ISite[];
  loading: boolean;
  isSuperUser?: boolean;
  onSuccess?: Function;
  onFailure?: Function;
}

interface IState {
  targetSite?: ISite;
  expandedRows: any[];
}

export class SiteTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      expandedRows: [],
    };
  }

  siteFieldTemplate = (rowData: ISite, field: keyof ISite) => {
    const { targetSite } = this.state;
    if (field === 'providers') {
      return;
    }
    if (rowData.id !== targetSite?.id) {
      return <div>{rowData[field]}</div>;
    }
    return (
      <InputText
        id={`targetSite_${field}`}
        value={targetSite[field] ?? undefined}
        onChange={(e) => {
          if (!targetSite) {
            return;
          }
          if (field === 'zipCode' && !/^[0-9\b]{0,5}$/.test(e.target.value)) {
            return;
          }

          this.setState({
            targetSite: {
              ...targetSite,
              [field]: e.target.value,
            },
          });
        }}
      ></InputText>
    );
  };

  checkEditSite = (rowData: ISite) => {
    const { targetSite } = this.state;
    if (!targetSite || !rowData || targetSite.name.trim().length <= 0) {
      return false;
    } else if (
      targetSite.name.trim() === rowData.name.trim() &&
      targetSite.city?.trim() === rowData.city?.trim() &&
      targetSite.state?.trim() === rowData.state?.trim() &&
      targetSite.zipCode?.trim() === rowData.zipCode?.trim()
    ) {
      return false;
    }
    return true;
  };

  saveTargetSite = async () => {
    const targetSite = this.state.targetSite;
    if (!targetSite) {
      return;
    }

    const { name, city, state, zipCode } = targetSite;

    const result = await institutionService.updateSite({
      ...targetSite,
      name: name.trim(),
      city: city?.trim() ?? null,
      state: state?.trim() ?? null,
      zipCode: zipCode?.trim() ?? null,
    });

    if (result) {
      if (this.props.onSuccess) {
        this.props.onSuccess();
      }
      this.setState({
        targetSite: undefined,
      });
    } else {
      if (this.props.onFailure) {
        this.props.onFailure();
      }
    }
  };

  editSiteButtonTemplate = (rowData: ISite) => {
    const { targetSite } = this.state;
    if (rowData.id === targetSite?.id) {
      return (
        <Button
          label="Save"
          onClick={this.saveTargetSite}
          disabled={!this.checkEditSite(rowData)}
        />
      );
    } else {
      return (
        <Button
          label="Edit Site"
          onClick={() => {
            this.setState({
              targetSite: { ...rowData },
            });
          }}
        />
      );
    }
  };

  cancelEditSiteButtonTemplate = (rowData: ISite) => {
    const { targetSite } = this.state;
    if (rowData.id === targetSite?.id) {
      return (
        <Button
          label="Cancel Editing"
          onClick={() => {
            this.setState({
              targetSite: undefined,
            });
          }}
        />
      );
    } else {
      return <Button disabled label="Cancel Editing" />;
    }
  };

  rowExpansionTemplate = (rowData: ISite) => {
    return (
      <ProviderTable
        providers={rowData.providers ?? []}
        header=""
        rowExpansionTemplate={undefined}
      />
    );
  };

  render() {
    const { loading, sites, isSuperUser } = this.props;

    return (
      <>
        {loading ? (
          <ProgressSpinner />
        ) : (
          <>
            <DataTable
              id="sites"
              value={sites}
              paginatorPosition="bottom"
              header={() => {
                return <h1 style={{ display: 'table' }}>Sites</h1>;
              }}
              paginator
              rows={10}
              responsiveLayout="scroll"
              resizableColumns
              emptyMessage="No records found"
              sortMode="multiple"
              loading={loading}
              filterDisplay={'row'}
              rowExpansionTemplate={this.rowExpansionTemplate}
              expandedRows={this.state.expandedRows}
              onRowToggle={(e: { data: any[] }) =>
                this.setState({ expandedRows: e.data })
              }
              itemID="name"
            >
              <Column expander />
              <Column
                body={(rowData) => {
                  return this.siteFieldTemplate(rowData, 'name');
                }}
                header="Site Name"
                field="name"
                filter
                filterPlaceholder="Filter by Name"
                filterMatchMode="contains"
              />
              <Column
                body={(rowData) => {
                  return this.siteFieldTemplate(rowData, 'city');
                }}
                header="City"
                field="city"
                filter
                filterPlaceholder="Filter by City"
                filterMatchMode="contains"
              />
              <Column
                body={(rowData) => {
                  return this.siteFieldTemplate(rowData, 'state');
                }}
                header="State"
                field="state"
                filter
                filterPlaceholder="Filter by State"
                filterMatchMode="contains"
              />
              <Column
                body={(rowData) => {
                  return this.siteFieldTemplate(rowData, 'zipCode');
                }}
                header="Zip Code"
                field="zipcode"
                filter
                filterPlaceholder="Filter by Zip Code"
                filterMatchMode="contains"
              />
              {isSuperUser && (
                <Column
                  body={this.editSiteButtonTemplate}
                  header=""
                  field="editSiteButton"
                />
              )}
              {isSuperUser && (
                <Column
                  body={this.cancelEditSiteButtonTemplate}
                  header=""
                  field="cancelEditSiteButton"
                />
              )}
            </DataTable>
          </>
        )}
      </>
    );
  }
}
