import { Fieldset } from 'primereact/fieldset';
import { Component } from 'react';
import { FloatLabel } from '../FloatLabel';
import { ISite } from '../../types/DTOTypes';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { ACConfig } from '../../services/ACConfig';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';

export interface UserData {
  email: string;
  password: string;
  passwordConfirm: string;
  site: ISite | null;
  valid: boolean;
}

export const EmptyUserData: UserData = {
  email: '',
  password: '',
  passwordConfirm: '',
  site: null,
  valid: false,
};

interface IProps {
  sites: ISite[];
  userData: UserData | null;
  setUserData: (userData: UserData | null) => void;
}

interface IState {
  emailError: string;
  passwordError: string;
}

export class AddUserDetails extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      emailError: '',
      passwordError: '',
    };
  }

  componentDidMount(): void {
    this.onExpand();
  }

  checkEmail = (userData: UserData): boolean => {
    this.setState({ emailError: '' });
    if (!userData || !userData.email) {
      return false;
    }
    if (!ACConfig.emailRegex.test(userData.email.trim())) {
      this.setState({ emailError: 'Invalid email!' });
      return false;
    }
    return true;
  };

  checkPassword = (userData: UserData): boolean => {
    this.setState({ passwordError: '' });
    if (!userData) {
      return false;
    }
    const password = userData.password.trim();
    const passwordConfirm = userData.passwordConfirm.trim();
    if (password.length <= 0 || passwordConfirm.length <= 0) {
      return false;
    }
    if (password !== passwordConfirm) {
      this.setState({ passwordError: "Passwords don't match!" });
      return false;
    }
    return true;
  };

  validate = (userData: UserData): boolean => {
    if (!userData) {
      return false;
    }
    // put into consts so the fuctions all run
    const validEmail = this.checkEmail(userData);
    const validPassword = this.checkPassword(userData);
    if (!validEmail || !validPassword || !userData.site) {
      return false;
    }
    return true;
  };

  handleChange = (name: keyof UserData, value: UserData[keyof UserData]) => {
    if (!this.props.userData) {
      return;
    }
    const userData: UserData = {
      ...this.props.userData,
      [name]: value,
    };
    userData.valid = this.validate(userData);
    this.props.setUserData(userData);
  };

  onExpand = () => {
    this.props.setUserData(EmptyUserData);
  };

  onCollapse = () => {
    this.props.setUserData(null);
  };

  render() {
    const { sites } = this.props;
    const userData = this.props.userData ?? EmptyUserData;
    return (
      <Fieldset
        style={{ display: 'block', marginTop: '20px' }}
        legend={userData ? 'Remove User Details' : 'Add User Details'}
        toggleable
        onExpand={this.onExpand}
        onCollapse={this.onCollapse}
      >
        <div style={{ display: 'inline-block' }}>
          <Dropdown
            tooltip="Site"
            aria-label="siteDropdown"
            optionLabel="name"
            placeholder="Select Site"
            onChange={(e) => {
              this.handleChange('site', e.target.value);
            }}
            value={userData?.site}
            options={sites}
            filter
          />
        </div>
        <FloatLabel label="Email">
          <InputText
            id="email"
            aria-label="Email"
            keyfilter="email"
            type="email"
            value={userData?.email}
            onChange={(event) => this.handleChange('email', event.target.value)}
          />
        </FloatLabel>
        <FloatLabel label="Password">
          <Password
            style={{
              display: 'block',
              marginTop: '20px',
              width: '175px',
            }}
            id="password"
            aria-label="Password"
            type="password"
            autoComplete="new-password"
            value={userData?.password}
            onChange={(event) =>
              this.handleChange('password', event.target.value?.trim())
            }
          />
        </FloatLabel>
        <FloatLabel label="Confirm Password">
          <InputText
            id="passwordConfirm"
            aria-label="Confirm Password"
            type="password"
            value={userData?.passwordConfirm}
            onChange={(event) =>
              this.handleChange('passwordConfirm', event.target.value?.trim())
            }
          />
        </FloatLabel>
        <div style={{ display: 'inline-block' }}>
          {this.state.emailError && (
            <Message
              severity="error"
              text={this.state.emailError}
              style={{ marginLeft: '8px' }}
            />
          )}
          <br />
          {this.state.passwordError && (
            <Message
              severity="error"
              text={this.state.passwordError}
              style={{ marginLeft: '8px' }}
            />
          )}
        </div>
      </Fieldset>
    );
  }
}
