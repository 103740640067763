import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import { FileUpload, FileUploadHandlerParam } from 'primereact/fileupload';
import { Component, RefObject } from 'react';
import { RegistrationTable } from '../../components/RegistrationTable';
import { archiveCoreService } from '../../services/ArchiveCoreServices';
import { userService } from '../../services/UserServices';
import { Registration } from '../../types/DTOTypes';
import { ISiteDeptPair, NO_SITE_DEPT } from '../../types/SiteDeptPair';
import { Tooltip } from 'primereact/tooltip';

interface IProps {
  ref?: RefObject<FileSelection>;
  siteDeptPairList: ISiteDeptPair[] | null;
  selectedSiteDeptPair?: ISiteDeptPair | null;
  onChange(e: DropdownChangeParams): void;
  uploadHandler(e: FileUploadHandlerParam): void;
}

interface IState {
  registrationList?: Registration[] | null;
  documents?: string[] | null;
}

export class FileSelection extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      registrationList: undefined,
      documents: undefined,
    };
  }

  componentDidMount = async () => {
    const userJSON = sessionStorage.getItem('user');
    if (!userJSON) {
      console.error('sessionStorage did not contain user data!');
      return;
    }
    const user = JSON.parse(userJSON);
    const getRegistrationsResponse = await userService.getRegistrations(
      user.id
    );

    const registrationList: Registration[] =
      getRegistrationsResponse?.status === 200
        ? getRegistrationsResponse.data
        : undefined;

    if (registrationList) {
      // Get a set of provider IDs this user has registered for
      const providerIdSet: Set<number> = new Set<number>(
        registrationList.map((reg) => reg.providerId)
      );

      // Use the set of provider IDs to get a list of all documents registered by all providers listed
      const responses = new Set<string>();

      for await (const providerId of providerIdSet) {
        const response = await archiveCoreService.listingObjectsAsync(
          providerId
        );

        response.forEach((doc) => {
          responses.add(doc);
        });
      }

      const documents = Array.from(responses);

      console.assert(
        Array.isArray(documents),
        '🐎 we expect processed data from server to be an array',
        documents
      );
      console.assert(
        documents.every((item) => typeof item === 'string'),
        '🐎 we expect the processed array from server to contain strings',
        documents
      );

      this.setState({
        documents,
        registrationList,
      });
    } else {
      this.setState({
        documents: null,
        registrationList: null,
      });
    }
  };

  render() {
    const siteDeptPairList = this.props.siteDeptPairList ?? [];
    const { selectedSiteDeptPair, onChange, uploadHandler } = this.props;

    const { documents, registrationList } = this.state;

    return (
      <div className="user-card card">
        <h1>Register New Documents</h1>
        <Dropdown
          optionLabel="name"
          options={[NO_SITE_DEPT, ...siteDeptPairList]}
          value={
            selectedSiteDeptPair === null ? NO_SITE_DEPT : selectedSiteDeptPair
          }
          placeholder={
            this.props.siteDeptPairList === null
              ? 'Loading Departments and Sites...'
              : 'Select Department and Site to upload'
          }
          onChange={onChange}
        />
        <FileUpload
          name="assets"
          customUpload={true}
          multiple={true}
          chooseLabel="Click to add documents"
          uploadLabel="Upload documents"
          cancelLabel="Clear documents"
          disabled={selectedSiteDeptPair === undefined}
          progressBarTemplate={<></>}
          uploadHandler={uploadHandler}
        />
        <h1>My Registrations: </h1>
        <RegistrationTable
          registrations={registrationList}
          documents={documents}
          onRemove={this.componentDidMount}
        />
      </div>
    );
  }
}
