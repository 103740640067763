import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { userService } from '../../services/UserServices';

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      newPass: '',
      newPassConfirm: '',
      success: 0,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.email) {
      console.log('Token invalid or expired');
      this.setState({ success: -1 });
    } else if (this.state.newPass === this.state.newPassConfirm) {
      var result = userService.updatePassword(
        this.props.match.params.token,
        this.state.newPass
      );
      if (result === -1) {
        this.setState({ success: -1 });
      } else {
        this.setState({ success: 1 });
      }
    } else {
      console.log("Passwords don't match.");
      this.setState({ success: -2 });
    }
  };

  handleChange = (event) => {
    if (event.target.name === 'password') {
      this.setState({ newPass: event.target.value });
    } else if (event.target.name === 'passwordConfirm') {
      this.setState({ newPassConfirm: event.target.value });
    }
  };

  async componentDidMount() {
    try {
      const response = await userService.getUserFromToken(
        this.props.match.params.token
      );
      console.log(response);
      this.setState({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
      });
      console.log(this.state);
    } catch (error) {
      console.log('error matching token to user');
      console.log(error);
    }
  }

  render() {
    if (!this.state.email || this.state.success === -1) {
      return (
        <div className="exception-body" style={{ background: '#528AA8' }}>
          <div className="exception-panel"></div>

          <div className="exception-content">
            <img
              src="assets/layout/images/logo-black.png"
              alt="babylon-layout"
            />
            <h1>
              <span className="exception-name">RESET</span> PASSWORD
            </h1>
            <p>
              An error occurred while resetting your password. The token you
              used may be invalid or expired.
            </p>
          </div>
        </div>
      );
    } else if (this.state.success === 0) {
      return (
        <div className="exception-body" style={{ background: '#528AA8' }}>
          <div className="exception-panel"></div>

          <div className="exception-content">
            <img
              src="assets/layout/images/logo-black.png"
              alt="babylon-layout"
            />
            <h1>
              <span className="exception-name">RESET</span> PASSWORD
            </h1>
            <p>
              Update Password for {this.state.firstName} {this.state.lastName}
              <br></br>
              Please enter your new password
            </p>
            <form onSubmit={this.handleSubmit}>
              <InputText
                type="password"
                autoComplete="new-password"
                name="password"
                value={this.state.newPass}
                onChange={this.handleChange}
                style={{ marginBottom: 10 }}
              />
              <br></br>
              <InputText
                type="password"
                autoComplete="new-password"
                name="passwordConfirm"
                value={this.state.newPassConfirm}
                onChange={this.handleChange}
                style={{ marginBottom: 10 }}
              />
              <br></br>
              <Button type="submit" label="Reset Password" />
            </form>
          </div>
        </div>
      );
    } else if (this.state.success === -2) {
      return (
        <div
          className="exception-body notfound"
          style={{ background: '#528AA8' }}
        >
          <div className="exception-panel"></div>

          <div className="exception-content">
            <img
              src="assets/layout/images/logo-black.png"
              alt="babylon-layout"
            />
            <h1>
              <span className="exception-name">RESET</span> PASSWORD
            </h1>
            <p>
              Update Password for {this.state.firstName} {this.state.lastName}
            </p>
            <p>Please enter your new password</p>
            <form onSubmit={this.handleSubmit}>
              <InputText
                type="password"
                autoComplete="new-password"
                name="password"
                value={this.state.newPass}
                onChange={this.handleChange}
                style={{ marginBottom: 10 }}
              />
              <br></br>
              <InputText
                type="password"
                autoComplete="new-password"
                name="passwordConfirm"
                value={this.state.newPassConfirm}
                onChange={this.handleChange}
                style={{ marginBottom: 10 }}
              />
              <br></br>
              <Button type="submit" label="Reset Password" />
            </form>
            <p>Passwords don't match. Please try again.</p>
          </div>
        </div>
      );
    } else if (this.state.success === 1) {
      return (
        <div
          className="exception-body notfound"
          style={{ background: '#528AA8' }}
        >
          <div className="exception-panel"></div>

          <div className="exception-content">
            <img
              src="assets/layout/images/logo-black.png"
              alt="babylon-layout"
            />
            <h1>
              <span className="exception-name">RESET</span> PASSWORD
            </h1>
            <p>
              Update Password for {this.state.firstName} {this.state.lastName}
            </p>
            <p>
              Your password has been reset. Please login with your new password.
            </p>
          </div>
        </div>
      );
    }
  }
}
