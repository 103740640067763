import { ACConfig } from './ACConfig';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { IProvider, ISite, IUser } from '../types/DTOTypes';
import { axiosService } from './AxiosService';

export const userService = {
  addDownloadPermission,
  addRegisterProviderPermission,
  addRegisterDeptSitePermission,
  addViewCertificateProviderPermission,
  addViewCertificateDeptSitePermission,
  addUserProvider,
  fetchAvaliablePermissions,
  fetchCurrentPermissions,
  fetchUsers,
  getRegistrations,
  getUserFromId,
  getUserFromToken,
  getUsers,
  postUser,
  removePermission,
  requestResetToken,
  setUserActivity,
  updatePassword,
  updateUser,
};

async function removePermission(
  permissionId: number
) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  return await axios({
    method: 'DELETE',
    headers: {
      mode: 'cors',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + sessionUserJson.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    url: ACConfig.apiUrl + '/Users/permissions/' + permissionId,
  }).catch((error: AxiosError) => {
    if (!error.response) {
      return null;
    }
    return error.response;
  });
}

async function addDownloadPermission(
  userId: number,
) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionUserJson.token,
    },
    data: userId,
  };

  return await axios(
    ACConfig.apiUrl + '/users/permissions/download/add',
    requestOptions
  ).catch((error: AxiosError) => {
    if (!error.response) {
      return null;
    }
    return error.response;
  });
}

async function addRegisterProviderPermission(
  userId: number,
  providerId: number
) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionUserJson.token,
    },
    data: { userId: userId, providerId: providerId },
  };

  try {
    const response = await axios(
      ACConfig.apiUrl + '/users/permissions/register/add',
      requestOptions
    );
    return response.status;
  } catch (error: any) {
    return error.response.status;
  }
}

async function addRegisterDeptSitePermission(
  userId: number,
  siteId: number,
  deptId: number
) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionUserJson.token,
    },
    data: { userId: userId, siteId: siteId, departmentId: deptId },
  };

  try {
    const response = await axios(
      ACConfig.apiUrl + '/users/permissions/register/add',
      requestOptions
    );
    return response.status;
  } catch (error: any) {
    return error.response.status;
  }
}

async function addViewCertificateProviderPermission(
  userId: number,
  providerId: number
) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionUserJson.token,
    },
    data: { userId: userId, providerId: providerId },
  };

  try {
    const response = await axios(
      ACConfig.apiUrl + '/users/permissions/viewCertificate/add',
      requestOptions
    );
    return response.status;
  } catch (error: any) {
    return error.response.status;
  }
}

async function addViewCertificateDeptSitePermission(
  userId: number,
  siteId: number,
  deptId: number
) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionUserJson.token,
    },
    data: { userId: userId, siteId: siteId, departmentId: deptId },
  };

  try {
    const response = await axios(
      ACConfig.apiUrl + '/users/permissions/viewCertificate/add',
      requestOptions
    );
    return response.status;
  } catch (error: any) {
    return error.response.status;
  }
}

async function fetchAvaliablePermissions(userId: string | number) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + sessionUserJson.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      url: ACConfig.apiUrl + '/Users/getNotGrantedPermissions/' + userId,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function fetchCurrentPermissions(userId: string | number) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');

  return await axios({
    method: 'GET',
    headers: {
      mode: 'cors',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + sessionUserJson.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    url: ACConfig.apiUrl + `/Users/${userId}/permissions/`,
  }).catch((error: AxiosError) => {
    if (!error.response) {
      return null;
    }
    return error.response;
  });
}

async function fetchUsers() {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + sessionUserJson.token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      url: ACConfig.apiUrl + '/Users',
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

async function getRegistrations(userId: string | number) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  const requestOptions: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      mode: 'cors',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + sessionUserJson.token,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return await axios(
    `${ACConfig.apiUrl}/users/${userId}/registrations`,
    requestOptions
  ).catch((error: AxiosError) => {
    console.error(error);
    return error.response;
  });
}

async function getUsers() {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  try {
    const response = await axios({
      method: 'get',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + sessionUserJson.token,
      },
      url: ACConfig.apiUrl + '/Users',
    });
    return response.data;
  } catch (error) {
    return false;
  }
}

async function getUserFromId(userId: number): Promise<IUser> {
  const response: AxiosResponse<IUser> = await axiosService
    .callAxios<IUser>({
      method: 'get',
      url: ACConfig.apiUrl + '/Users/' + userId,
    })
    .catch(axiosService.justError<IUser>);

  return response.data;
}

async function getUserFromToken(token: string) {
  try {
    const response = await axios({
      method: 'get',
      headers: { mode: 'cors', 'Access-Control-Allow-Origin': '*' },
      url: ACConfig.apiUrl + '/Users/resetPassword/' + token,
    });
    return response;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function postUser(
  lastName: string,
  firstName: string,
  middleName: string,
  email: string,
  password: string,
  siteId: number,
  provider: IProvider | null
): Promise<AxiosResponse> {
  const userDTO = {
    lastName,
    firstName,
    middleName,
    email,
    password,
    siteId,
    providerId: provider?.id ?? -1,
    active: true,
  };

  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionUserJson.token,
    },
    data: JSON.stringify(userDTO),
  };

  return await axios(ACConfig.apiUrl + '/users/', requestOptions).catch(
    (error: AxiosError) => {
      console.error(error);
      if (!error.response) {
        throw error;
      }
      return error.response;
    }
  );
}

async function requestResetToken(email: string) {
  try {
    const response = await axios({
      method: 'post',
      headers: { mode: 'cors', 'Access-Control-Allow-Origin': '*' },
      url: ACConfig.apiUrl + '/Users/forgotPassword',
      data: {
        email,
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

async function setUserActivity(userId: number, active: boolean) {
  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  const userDTO = {
    id: userId,
    active: active,
  };

  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionUserJson.token,
    },
    data: JSON.stringify(userDTO),
  };

  try {
    const response = await axios(
      ACConfig.apiUrl + '/users/setUserActivity',
      requestOptions
    );
    return response.status;
  } catch (error: any) {
    return error.response.status;
  }
}

async function updatePassword(token: string, pass: string) {
  try {
    var formData = new FormData();
    formData.append('token', token);
    formData.append('pass', pass);
    const response = await axios({
      method: 'post',
      headers: { mode: 'cors', 'Access-Control-Allow-Origin': '*' },
      url: ACConfig.apiUrl + '/Users/resetPassword/',
      data: formData,
    });
    return response;
  } catch (error) {
    console.error(error);
    return -1;
  }
}

async function updateUser(
  userId: number,
  lastName: string,
  firstName: string,
  middleName: string | null,
  email: string,
  password: string | null,
  siteId: string,
  provider: IProvider | null
): Promise<AxiosResponse<IUser | Error>> {
  const length = password?.trim()?.length ?? -1;
  const pass = length > 0 ? password : undefined;

  const userDTO = {
    id: userId,
    lastName: lastName,
    firstName: firstName,
    middleName: middleName,
    email: email,
    password: pass,
    siteId: siteId,
    providerId: provider ? provider.id : 0,
  };

  const requestOptions: AxiosRequestConfig = {
    method: 'PUT',
    data: JSON.stringify(userDTO),
  };

  return await axiosService
    .callAxios<IUser>(ACConfig.apiUrl + '/users/', requestOptions)
    .catch(axiosService.transformErrors);
}

async function addUserProvider(
  firstName: string,
  middleName: string | null,
  lastName: string,
  email: string,
  password: string,
  userSiteId: number,
  npi: string | null,
  position: string,
  degree: string,
  birthDate: Date,
  providerSites: ISite[]
) {
  const userDTO = {
    firstName,
    middleName,
    lastName,
    email,
    password,
    siteId: userSiteId,
    active: true
  }
  const providerDTO = {
    firstName,
    middleName,
    lastName,
    npi,
    position,
    degrees: [{ degreeName: degree }],
    birthDate: birthDate.toJSON(),
    sites: providerSites,
  };

  const sessionUserJson = JSON.parse(sessionStorage.getItem('user') ?? '');
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + sessionUserJson.token,
    },
    data: {Item1: userDTO, Item2: providerDTO},
  };

  return await axios(ACConfig.apiUrl + '/users/SelfUpload', requestOptions).catch(
    (error: AxiosError) => {
      console.error(error);
      if (!error.response) {
        throw error;
      }
      return error.response;
    }
  );
}
