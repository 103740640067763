import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { institutionService } from '../../services/InstitutionServices';
import { Accordion, AccordionTab } from 'primereact/accordion';

interface IProps {
  onSuccess: () => void;
  onFailure: (message: string) => void;
}

interface IState {
  name: string;
  shortName: string;
}

export class AddDocumentType extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      name: '',
      shortName: '',
    };
  }

  handleClick = async () => {
    const result = await institutionService.addDocumentType(
      this.state.name.trim(),
      this.state.shortName.trim()
    );

    if (result.status !== 200) {
      this.props.onFailure?.(result.data);
    } else {
      this.props.onSuccess?.();
    }

    this.setState({
      name: '',
      shortName: '',
    });
  };

  handleChange = <P extends keyof IState>(prop: P, value: IState[P]) => {
    this.setState({ ...this.state, [prop]: value });
  };

  checkComplete = () => {
    const name = this.state.name.trim();
    const shortName = this.state.shortName.trim();
    return name && name.length > 1 && shortName && shortName.length > 1;
  };

  render() {
    const style = { display: 'block', marginTop: '20px', width: '175px' };
    const { name, shortName } = this.state;

    return (
      <Accordion>
        <AccordionTab header="Add New Document Type">
          <div style={{ display: 'inline-block' }}>
            <span className="p-float-label">
              <InputText
                style={style}
                id="name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleChange('name', e.target.value);
                }}
              ></InputText>
              <label htmlFor="name">Document Name</label>
            </span>
          </div>
          <div style={{ display: 'inline-block' }}>
            <span className="p-float-label">
              <InputText
                style={style}
                id="shortName"
                value={shortName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleChange('shortName', e.target.value);
                }}
              ></InputText>
              <label htmlFor="shortName">Short Name</label>
            </span>
          </div>
          <br />
          <Button
            className="p-button-icon"
            icon="pi pi-check"
            disabled={!this.checkComplete()}
            onClick={this.handleClick}
            label="Submit"
          />
        </AccordionTab>
      </Accordion>
    );
  }
}
