import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { archiveCoreService } from '../services/ArchiveCoreServices';
import { ChainData } from '../types/DTOTypes';

interface OnTopbarItemClickArgs {
  originalEvent: React.MouseEvent<HTMLLIElement, MouseEvent>;
  item: string;
}

interface IProps {
  onTopbarItemClick: (onTopbarItemClickArgs: OnTopbarItemClickArgs) => void;
  onMenuButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
  onTopbarMenuButtonClick: (event: React.MouseEvent<HTMLElement>) => void;
  topbarMenuActive: boolean;
  activeTopbarItem: string | null;
}

interface IState {
  firstName?: string;
  lastName?: string;
  site?: string;
  chainData?: ChainData;
}

export class AppTopbar extends Component<IProps, IState> {
  static defaultProps = {
    onMenuButtonClick: null,
    onTopbarMenuButtonClick: null,
    onTopbarItemClick: null,
    topbarMenuActive: false,
    activeTopbarItem: null,
    profileMode: null,
    horizontal: null,
  };

  static propTypes = {
    onMenuButtonClick: PropTypes.func.isRequired,
    onTopbarMenuButtonClick: PropTypes.func.isRequired,
    onTopbarItemClick: PropTypes.func.isRequired,
    topbarMenuActive: PropTypes.bool.isRequired,
    activeTopbarItem: PropTypes.string,
    profileMode: PropTypes.string,
    horizontal: PropTypes.bool,
  };

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const userStorage = sessionStorage.getItem('user');
    if (userStorage) {
      const user = JSON.parse(userStorage);

      this.setState({
        firstName: user.firstName,
        lastName: user.lastName,
        site: user.siteName,
      });
    }

    const chainData = await archiveCoreService.getChain();

    this.setState({
      chainData,
    });
  }

  onTopbarItemClick(
    originalEvent: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item: string
  ) {
    this.props.onTopbarItemClick({
      originalEvent,
      item,
    });
  }

  render() {
    let topbarItemsClassName = classNames('topbar-menu fadeInDown', {
      'topbar-menu-visible': this.props.topbarMenuActive,
    });

    const chainData = this.state.chainData;
    const chainName = chainData?.chainName || '❓';
    const barNote = chainData?.isMainNet ? '' : `(${chainName})`;

    return (
      <div className="layout-topbar clearfix">
        <button className="layout-topbar-logo p-link">
          <Link style={{ display: 'block', height: '100%' }} to="/">
            <img
              id="layout-topbar-logo"
              alt="ArchiveCore Logo"
              src="assets/layout/images/logo2.png"
            />
          </Link>
        </button>
        <div style={{ display: 'inline', fontWeight: 'bold', color: 'white' }}>
          {barNote}
        </div>

        <button
          className="layout-menu-button p-link"
          onClick={this.props.onMenuButtonClick}
        >
          <i className="pi pi-bars" />
        </button>

        <button
          id="topbar-menu-button"
          className="p-link"
          onClick={this.props.onTopbarMenuButtonClick}
        >
          <i className="pi pi-ellipsis-v" />
        </button>

        <ul className={topbarItemsClassName}>
          <MobileView>
            <li
              className={classNames({
                'active-topmenuitem': this.props.activeTopbarItem === 'logout',
              })}
              onClick={(e) => this.onTopbarItemClick(e, 'logout')}
            >
              <div style={{ fontWeight: 'bold', fontSize: '90%' }}>
                {this.state.firstName} {this.state.lastName} @ {this.state.site}
              </div>
              <button className="p-link" style={{ margin: 0, padding: 0 }}>
                <span style={{ fontWeight: 'bold' }}>
                  Sign out
                  <i
                    className="topbar-icon pi pi-sign-out"
                    title="Logout"
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: '1.5em',
                      verticalAlign: 'bottom',
                    }}
                  />
                </span>
              </button>
            </li>
          </MobileView>
          <BrowserView>
            <li
              className={classNames({
                'active-topmenuitem': this.props.activeTopbarItem === 'logout',
              })}
              onClick={(e) => this.onTopbarItemClick(e, 'logout')}
            >
              <button className="p-link">
                <i
                  className="topbar-icon pi pi-sign-out"
                  title="Sign out"
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: '2.5em',
                    color: 'white',
                  }}
                />
              </button>
            </li>
          </BrowserView>
        </ul>
        {!isMobile && (
          <span className="userAtSite">
            {this.state.firstName} {this.state.lastName} @ {this.state.site}
          </span>
        )}
      </div>
    );
  }
}
