import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Route } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IInstitution } from '../../types/DTOTypes';

interface IProps {
  institutions: IInstitution[];
  loading: boolean;
  isSuperUser?: boolean;
}

interface IState {}

export class InstitutionTable extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  deleteButtonTemplate = (rowData: IInstitution) => {};

  editButtonTemplate = (rowData: IInstitution) => {
    return (
      <Route
        render={({ history }) => (
          <Button
            label="Edit Institution"
            onClick={() => {
              history.push('/administration/editInstitution/' + rowData.id);
            }}
          />
        )}
      />
    );
  };

  render() {
    const { loading, institutions, isSuperUser } = this.props;

    return (
      <>
        {loading ? (
          <ProgressSpinner />
        ) : (
          <>
            <DataTable
              id="providers"
              value={institutions}
              paginatorPosition="bottom"
              header={<h1 style={{ display: 'table' }}>Institutions</h1>}
              paginator
              rows={10}
              responsiveLayout="scroll"
              resizableColumns
              emptyMessage="No records found"
              sortMode="multiple"
              loading={loading}
              filterDisplay="row"
            >
              <Column
                header="Institution Name"
                field="name"
                filter
                filterPlaceholder="Filter by Name"
                filterMatchMode="contains"
              />
              <Column
                header="Tax ID"
                field="taxID"
                filter
                filterPlaceholder="Filter by Tax ID"
                filterMatchMode="contains"
              />
              {isSuperUser && (
                <Column
                  body={this.editButtonTemplate}
                  header=""
                  field="editInstitutionButton"
                />
              )}
            </DataTable>
          </>
        )}
      </>
    );
  }
}
