import React, { Component } from 'react';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { IDepartment, IUser } from '../../types/DTOTypes';
import { ISite } from '../../types/DTOTypes';
import { IInstitution } from '../../types/DTOTypes';
import { userService } from '../../services/UserServices';
import { institutionService } from '../../services/InstitutionServices';
import { InfoDialog } from '../InfoDialog';

interface IProps {
  user?: IUser;
  onSuccess?: Function;
}

interface IState {
  institutions: IInstitution[];
  selectedInstitution: IInstitution | null;
  sites: ISite[];
  selectedSite: ISite | null;
  departments: IDepartment[];
  selectedDepartment: IDepartment | null;
  failure: boolean;
  success: boolean;
  exists: boolean;
}

export class GrantRegisterDeptSitePermission extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      institutions: [],
      selectedInstitution: null,
      sites: [],
      selectedSite: null,
      departments: [],
      selectedDepartment: null,
      failure: false,
      success: false,
      exists: false,
    };
  }

  componentDidMount = async () => {
    const institutions = await institutionService.getInstitutions();
    this.setState({
      institutions: institutions,
    });
  };

  onHide = () => {
    this.setState({
      selectedInstitution: null,
      failure: false,
      success: false,
      exists: false,
    });
  };

  institutionChange = async (e: DropdownProps) => {
    this.setState({
      selectedInstitution: e.value,
    });

    const departments = await institutionService.getDepartmentsForInstitution(
      e.value.id
    );
    const sites = await institutionService.getSitesForInstitution(e.value.id);

    this.setState({
      selectedSite: null,
      selectedDepartment: null,
      departments: departments,
      sites: sites,
    });
  };

  submit = async () => {
    const { selectedSite, selectedDepartment } = this.state;
    const { user } = this.props;
    if (user && selectedSite && selectedDepartment) {
      const result = await userService.addRegisterDeptSitePermission(
        user.id,
        selectedSite.id,
        selectedDepartment.id
      );
      switch (result) {
        case 201:
          this.setState({ success: true });
          this.props.onSuccess?.();
          break;
        case 409:
          this.setState({ exists: true });
          break;
        default:
          this.setState({ failure: true });
      }
    }
  };

  render() {
    return (
      <div>
        <h3>Grant Register Permission</h3>
        <p>
          Grant a user permission register documents at a specific site and
          department
        </p>
        <div style={{ display: 'block', marginBottom: '1rem' }}>
          <label>Choose a Institution:</label>
          <Dropdown
            style={{ marginLeft: '1rem' }}
            tooltip="Institution"
            optionLabel="name"
            placeholder="Please Select"
            onChange={this.institutionChange}
            value={this.state.selectedInstitution}
            options={this.state.institutions}
            filter
          />
        </div>
        {this.state.selectedInstitution && (
          <div style={{ display: 'block' }}>
            <label>Choose a Site:</label>
            <Dropdown
              style={{ marginLeft: '1rem' }}
              tooltip="Site"
              optionLabel="name"
              placeholder="Please Select"
              onChange={(e) => {
                this.setState({ selectedSite: e.value });
              }}
              value={this.state.selectedSite}
              options={this.state.sites}
              filter
            />
            <label style={{ marginLeft: '1rem' }}>Choose a Department:</label>
            <Dropdown
              style={{ marginLeft: '1rem' }}
              tooltip="Department"
              optionLabel="name"
              placeholder="Please Select"
              onChange={(e) => {
                this.setState({ selectedDepartment: e.value });
              }}
              value={this.state.selectedDepartment}
              options={this.state.departments}
              filter
            />

            <Button
              style={{ marginTop: '1rem', display: 'block' }}
              label="Apply"
              icon="pi pi-check"
              onClick={this.submit}
              disabled={
                !this.props.user ||
                !this.state.selectedInstitution ||
                !this.state.selectedSite ||
                !this.state.selectedDepartment
              }
            />
          </div>
        )}
        <InfoDialog
          header="Success!"
          message="Permissions Successfully Updated"
          buttonText="Ok"
          onButtonClick={this.onHide}
          visible={this.state.success}
          closeOnEscape
          onHide={this.onHide}
        />
        <InfoDialog
          header="Already Exists"
          message={`User already has permission to register documents at ${this.state.selectedSite?.name} in the ${this.state.selectedDepartment?.name} department`}
          buttonText="Ok"
          onButtonClick={this.onHide}
          visible={this.state.exists}
          closeOnEscape
          onHide={this.onHide}
        />
        <InfoDialog
          header="Error"
          message="An error occurred, permissions were not updated"
          buttonText="Ok"
          onButtonClick={this.onHide}
          visible={this.state.failure}
          closeOnEscape
          onHide={this.onHide}
        />
      </div>
    );
  }
}
