import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import {
  DataTable,
  DataTableExpandedRows,
  DataTableRowToggleParams,
} from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import React, { Component } from 'react';
import { archiveCoreService } from '../../services/ArchiveCoreServices';
import { providerService } from '../../services/ProviderServices';
import { IUser, Registration } from '../../types/DTOTypes';
import { authService } from '../../services/AuthServices';
import { RegistrationStatus } from '../RegistrationStatus';

interface IProps {
  registrations?: Registration[] | null;
  documents?: string[] | null;
  onRemove?: Function;
  customExpansionTemplate?: (data: Registration) => JSX.Element;
}

interface IState {
  expandedRows?: any[] | DataTableExpandedRows;
  targetRegistration?: Registration;
}

export class RegistrationTable extends Component<IProps, IState> {
  toast: Toast | null = null;

  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  handleClick = async () => {
    const { targetRegistration } = this.state;
    if (!targetRegistration) {
      return;
    }

    this.setState({ targetRegistration: undefined });

    const providerResponse: AxiosResponse = await providerService.getProvider(
      targetRegistration.providerId
    );

    if (providerResponse.status === 200) {
      const provider = providerResponse.data;

      var key = provider.uuid + '/' + targetRegistration.fileHash;
      const response = await archiveCoreService.removeDocumentStorage(key);

      if (response.status === 200) {
        // success
        this.toast?.show({
          severity: 'success',
          summary: 'Storage Removal Succeeded',
          detail: `Storage for ${targetRegistration.filename} was removed.`,
        });

        this.props.onRemove?.();
      } else {
        // error
        this.toast?.show({
          severity: 'error',
          summary: 'Storage Removal Failed',
          detail: `An error occurred when removing file storage.`,
        });
        console.error(response);
      }
    }
  };

  rowExpansionTemplate = (data: Registration) => {
    // Get the user from session storage and add their name to state
    let user = sessionStorage.getItem('user');
    if (!user) {
      return;
    }
    let userData: IUser = JSON.parse(user);
    return (
      <RegistrationStatus
        requester={userData.firstName + ' ' + userData.lastName}
        certInfo={data}
      />
    );
  };

  registrationHasDownload = (registration: Registration) => {
    const fileHashes = this.props.documents?.map((a) =>
      a.slice(a.indexOf('/') + 1)
    );
    return fileHashes?.includes(registration.fileHash);
  };

  institutionNameTemplate = (rowData: Registration) => {
    return rowData.institutionName ?? 'NO INSTITUTION';
  };

  removeStorageTemplate = (rowData: Registration) => {
    if (!authService.isAuthorized('SuperUser')) {
      return;
    }
    if (!this.registrationHasDownload(rowData)) {
      return;
    }
    return (
      <Button
        onClick={async () => {
          this.setState({ targetRegistration: rowData });
        }}
        label="Remove Storage"
      />
    );
  };

  downloadTemplate = (rowData: Registration) => {
    if (!this.registrationHasDownload(rowData)) {
      return;
    }
    return (
      <Button
        onClick={async () => {
          const response = await archiveCoreService.getFile(rowData.fileHash);
          if (!response) {
            console.log(
              'No response for archiveCoreService.getFile',
              rowData.fileHash
            );
            return;
          }
          window.open(response.data, '_blank');
        }}
        label="Download"
      />
    );
  };

  selfUploadTemplate = (rowData: Registration) => {
    return (
      <>
        {rowData.isSelfUpload ? (
          <i
            className="fa fa-user"
            aria-hidden="true"
            title="Self-Uploaded"
          ></i>
        ) : (
          ''
        )}
      </>
    );
  };

  submitTimeTemplate = (rowData: Registration) => {
    const submitTime = rowData.submitTime;
    const date = new Date(submitTime);
    const dateLocale = date.toLocaleString();
    return <div data-testid={submitTime}>{dateLocale}</div>;
  };

  confirmDialog = () => {
    return (
      <Dialog
        visible={this.state.targetRegistration !== undefined}
        header={'Confirm removal of document storage?'}
        footer={
          <>
            <Button
              className="p-button-secondary"
              label="Cancel"
              icon="pi pi-times"
              onClick={() => this.setState({ targetRegistration: undefined })}
            />
            <Button onClick={this.handleClick} label="Confirm Removal" />
          </>
        }
        onHide={() => this.setState({ targetRegistration: undefined })}
      >
        Are you sure you want to remove storage for{' '}
        {this.state.targetRegistration?.filename}? Clicking "Confirm Removal"
        will not delete the file from the blockchain, but will remove its
        download link!
      </Dialog>
    );
  };

  render() {
    const { documents, registrations, customExpansionTemplate } = this.props;
    const isSuperUser = authService.isAuthorized('SuperUser');
    const isAuthDownload = authService.isAuthorized('Download');
    const isError = documents === null || registrations === null;
    if (isError) {
      return '⚠ Unable to load Registration List';
    }
    const isLoadingView =
      !isError && (documents === undefined || registrations === undefined);

    return (
      <>
        <DataTable
          value={registrations}
          header={
            <div
              className="p-col p-sm-12 p-lg-10"
              style={{ textAlign: 'left' }}
            >
              <span>Total Registrations: {registrations?.length}</span>
            </div>
          }
          sortMode="multiple"
          emptyMessage="No records found"
          expandedRows={this.state.expandedRows}
          onRowToggle={(e: DataTableRowToggleParams) => {
            this.setState({ expandedRows: e.data });
          }}
          rowExpansionTemplate={
            customExpansionTemplate ?? this.rowExpansionTemplate
          }
          dataKey="id"
          loading={isLoadingView}
          autoLayout
          filterDisplay="row"
        >
          <Column expander style={{ width: '2em' }} />
          <Column
            field="providerFullNameWTitle"
            header="Provider"
            sortable
            filter
            filterPlaceholder="Filter by Provider"
            filterMatchMode="contains"
          />
          <Column
            field="documentType"
            header="Document Type"
            sortable
            filter
            filterPlaceholder="Filter by Document Type"
            filterMatchMode="contains"
          />
          <Column
            field="institutionName"
            header="Institution"
            sortable
            filter
            filterPlaceholder="Filter by Institution Name"
            filterMatchMode="contains"
            body={this.institutionNameTemplate}
          />
          <Column
            field="submitTime"
            header="Submission Time"
            filter
            filterPlaceholder="Filter by Submission Time"
            filterMatchMode="contains"
            body={this.submitTimeTemplate}
          />
          <Column
            body={this.selfUploadTemplate}
            header=""
            sortable
            filter={false}
            style={{ textAlign: 'center', width: '4%' }}
          />
          {isAuthDownload && (
            <Column
              body={this.downloadTemplate}
              header="Download"
              style={{ textAlign: 'center', width: '12%' }}
            />
          )}
          {isSuperUser && (
            <Column
              body={this.removeStorageTemplate}
              header={'Remove Storage'}
              style={{ textAlign: 'center', width: '12%' }}
            />
          )}
        </DataTable>
        <this.confirmDialog />
        <Toast
          ref={(el) => {
            this.toast = el;
          }}
        />
      </>
    );
  }
}
