import React, { Component } from 'react';
import Logo from '../../logo.png';
import { BlockChainInfo, Registration } from '../../types/DTOTypes';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

interface IProps {
  certInfo?: Registration;
  blockChainInfo?: BlockChainInfo;
  requester?: string;
}

interface IState {}

export class Certificate extends Component<IProps, IState> {
  toast: Toast | null;

  constructor(props: IProps) {
    super(props);
    this.state = {};
    this.toast = null;
  }

  getCurrentDate(separator = '-') {
    const newDate = new Date();
    const day = String(newDate.getDate()).padStart(2, '0');
    const year = newDate.getFullYear();
    const monthName = newDate.toLocaleString('default', { month: 'long' });
    const currentDate = `${day}${separator}${monthName}${separator}${year}`;
    return currentDate;
  }

  getCurrentTime(separator = ':') {
    const newDate = new Date();
    const hour = String(newDate.getUTCHours()).padStart(2, '0');
    const minutes = String(newDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(newDate.getUTCSeconds()).padStart(2, '0');
    const currentTime = `${hour}${separator}${minutes}${separator}${seconds}`;
    return currentTime;
  }

  copyToClipBoard = (data: string) => {
    window.navigator.clipboard.writeText(data);
    this.toast?.show({
      severity: 'success',
      summary: 'Copied to Clipboard',
      detail: 'Registration String Copied',
    });
  };

  render() {
    const { certInfo, blockChainInfo, requester } = this.props;

    if (!certInfo) {
      return <ProgressSpinner />;
    }
    const txLink =
      certInfo.txLink && certInfo.transactionHash
        ? certInfo.txLink + certInfo.transactionHash
        : undefined;
    const blockLink =
      certInfo.blockLink && blockChainInfo?.blockNumber
        ? certInfo.blockLink + blockChainInfo?.blockNumber
        : undefined;

    return (
      <div className="user-card card">
        <Toast ref={(el) => (this.toast = el)}></Toast>
        <span style={{ float: 'right', verticalAlign: 'top' }}>
          <img src={Logo} alt="logo" />
        </span>
        <h1>Certificate of Authenticity</h1>
        <table>
          <colgroup>
            <col span={1} style={{ width: '10em' }} />
            <col span={1} />
          </colgroup>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold', marginRight: '1em' }}>
                Current Date:
              </td>
              <td>{this.getCurrentDate()}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', marginRight: '1em' }}>
                Current Time:
              </td>
              <td>{this.getCurrentTime()} UTC</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', marginRight: '1em' }}>
                Requested By:
              </td>
              <td>{requester}</td>
            </tr>
          </tbody>
        </table>
        <p>
          A document has been uploaded to{' '}
          <span style={{ color: '#DE3B21' }}>ArchiveCore</span> for
          verification. The hash (SHA256) was calculated. Based on this hash,
          the following registration details have been identified:
        </p>
        <table className="card" width="90%">
          <colgroup>
            <col span={1} style={{ width: '10em' }} />
            <col span={1} />
          </colgroup>
          <tbody>
            <tr>
              <td className="dataHeader">Filename</td>
              <td className="dataBody">{certInfo?.filename}</td>
            </tr>
            <tr>
              <td className="dataHeader">Submitter</td>
              <td className="dataBody">{certInfo?.submittedBy}</td>
            </tr>
            <tr>
              <td className="dataHeader">Provider</td>
              <td className="dataBody">{certInfo?.providerFullNameWTitle}</td>
            </tr>
            <tr>
              <td className="dataHeader">Position</td>
              <td className="dataBody">{certInfo?.providerPosition}</td>
            </tr>
            <tr>
              <td className="dataHeader">Site</td>
              <td className="dataBody">{certInfo?.site ?? 'NO SITE'}</td>
            </tr>
            <tr>
              <td className="dataHeader">Document Type</td>
              <td className="dataBody">{certInfo?.documentType}</td>
            </tr>
            <tr>
              <td className="dataHeader">Description</td>
              <td className="dataBody">{certInfo?.description}</td>
            </tr>
            <tr>
              <td className="dataHeader">Timestamp</td>
              <td className="dataBody">{certInfo?.submitTime}</td>
            </tr>
            <tr>
              <td className="dataHeader">Version</td>
              <td className="dataBody">{certInfo?.version}</td>
            </tr>
            <tr>
              <td className="dataHeader">File Hash</td>
              <td className="dataBody">{certInfo?.fileHash}</td>
            </tr>
            <tr>
              <td className="dataHeader">Registration Hash</td>
              <td className="dataBody">{certInfo?.registrationHash}</td>
            </tr>
            {/* <tr>
              <td className="dataHeader">Registration String</td>
              <td className="dataBody">
                {certInfo?.registrationString}
                <span style={{ float: 'right', width: '5%' }}>
                  <Button
                    id="clipboardCopy"
                    type="button"
                    icon="pi pi-clone"
                    tooltip="Copy to clipboard"
                    style={{
                      color: '#000000',
                      backgroundColor: 'transparent',
                      border: 'none',
                    }}
                    onClick={() =>
                      this.copyToClipBoard(certInfo?.registrationString || '')
                    }
                  ></Button>
                </span>
              </td>
            </tr> */}
          </tbody>
        </table>
        <p>
          At the present time, a query of the blockchain transaction produced
          the following information:
        </p>
        <table className="card" width="90%">
          <colgroup>
            <col span={1} style={{ width: '10em' }} />
            <col span={1} />
          </colgroup>
          <tbody>
            <tr>
              <td className="dataHeader">Blockchain</td>
              <td className="dataBody">{certInfo?.blockchain}</td>
            </tr>
            <tr>
              <td className="dataHeader">Chain ID</td>
              <td className="dataBody">{certInfo?.chainId}</td>
            </tr>
            <tr>
              <td className="dataHeader">Transaction Hash</td>
              <td className="dataBody">
                {certInfo?.transactionHash}
                <a href={txLink} target="_blank" rel="noopener noreferrer">
                  <i
                    style={{ marginLeft: '3px' }}
                    className="fa fa-external-link fa-lg"
                  ></i>
                </a>
              </td>
            </tr>
            <tr>
              <td className="dataHeader">Block Number</td>
              <td className="dataBody">
                {blockChainInfo?.blockNumber}
                <a href={blockLink} target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-external-link fa-lg"></i>
                </a>
              </td>
            </tr>
            <tr>
              <td className="dataHeader">Block Timestamp</td>
              <td className="dataBody">
                {blockChainInfo?.blockDate} at {blockChainInfo?.blockTime} UTC
              </td>
            </tr>
            <tr>
              <td className="dataHeader">From</td>
              <td className="dataBody">{blockChainInfo?.sender}</td>
            </tr>
            <tr>
              <td className="dataHeader">To</td>
              <td className="dataBody">{blockChainInfo?.receiver}</td>
            </tr>
          </tbody>
        </table>
        <p>
          As the document hash and the registration hash exactly match the
          transaction data that was added to the blockchain on{' '}
          {blockChainInfo?.blockDate} at {blockChainInfo?.blockTime} UTC,{' '}
          <span style={{ color: '#DE3B21' }}>ArchiveCore</span> certifies that:
        </p>
        <ul className="fa-ul">
          <li>
            <i className="pi pi-check" style={{ fontSize: '1em' }} /> The
            document uploaded at the present time exactly matches the document
            that existed on {blockChainInfo?.blockDate} at{' '}
            {blockChainInfo?.blockTime} UTC.
          </li>
          <li>
            <i className="pi pi-check" style={{ fontSize: '1em' }} /> The
            registration details identified at the present time exactly match
            the registration details that existed on {blockChainInfo?.blockDate}{' '}
            at {blockChainInfo?.blockTime} UTC.
          </li>
        </ul>
        <p>
          The SHA256 hash for the document and the registration string may be
          calculated using independent methods, including online hash
          calculators such as:
        </p>
        <ul className="fa-ul" style={{ margin: '0' }}>
          <li>
            <a
              href="https://defuse.ca/checksums.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://defuse.ca/checksums.htm
            </a>
          </li>
          <li>
            <a
              href="https://www.conversion-tool.com/sha256/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.conversion-tool.com/sha256/
            </a>
          </li>
        </ul>
        <p>
          The blockchain transaction data may be determined using independent
          methods, including online blockchain query tools such as:
        </p>
        <ul className="fa-ul" style={{ margin: '0' }}>
          <li>
            <a href={txLink} target="_blank" rel="noopener noreferrer">
              {txLink}
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
