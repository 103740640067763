import { ACConfig } from './ACConfig';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { institutionService } from './InstitutionServices';
import {
  IDepartment,
  ISite,
  Permission,
  RegisterPermission,
} from '../types/DTOTypes';

export const authService = {
  login,
  logout,
  authUser,
  isAuthorized,
  authorizedSiteDeptPairs,
  setPermissionsSessionStorage,
  canViewHcp,
};

async function login(email: string, password: string): Promise<number> {
  const requestOptions: AxiosRequestConfig = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    data: JSON.stringify({ email, password }),
  };

  const response = await axios(
    ACConfig.apiUrl + '/users/authenticate',
    requestOptions
  ).catch((error: AxiosError) => {
    if (error.response) {
      console.error('🔒 /users/authenticate', error, error.response);
      return error.response;
    }

    console.error('🚫🎾', error);
    throw error;
  });

  const status = response.status;

  const success = status === 200;
  if (success) {
    sessionStorage.setItem('user', JSON.stringify(response.data));
  }

  return status;
}

function logout() {
  sessionStorage.removeItem('user');
  return true;
}

function authUser() {
  let user = sessionStorage.getItem('user');
  if (!user) {
    return false;
  }
  let userData = JSON.parse(user);
  if (!userData.token) {
    return false;
  }
  return true;
}

function isAuthorized(permission: string) {
  const permissionData = sessionStorage.getItem('permissions');
  if (!permissionData) {
    return false;
  }
  const permissions = JSON.parse(permissionData);
  for (let i = 0; i < permissions.length; i++) {
    if (
      permissions[i].permissionTypeName === permission ||
      permissions[i].permissionTypeName === 'SuperUser'
    ) {
      return true;
    }
  }
  return false;
}

function canViewHcp(hcpId: number | string | undefined) {
  if (hcpId === undefined) {
    return false;
  }
  const permissionData = sessionStorage.getItem('permissions') || '';
  const permissions = JSON.parse(permissionData);
  if (!permissions) {
    return false;
  }
  for (let i = 0; i < permissions.length; i++) {
    if (permissions[i].permissionTypeName !== 'ViewCertificate') {
      continue;
    }
    if (permissions[i].providerId === hcpId) {
      // use == not === here, because hcpId could be number or string
      return true;
    }
  }
  return false;
}

async function authorizedSiteDeptPairs() {
  // Get user permissions from sessionStorage
  const permissionData = sessionStorage.getItem('permissions');
  // If user has no permissions, they have no authorized sites
  if (!permissionData) {
    throw `If user has no permissions, they have no authorized sites`;
  }

  const permissions: Permission[] = JSON.parse(permissionData);
  let authorizedPairs: {
    site: ISite;
    department: IDepartment;
    name: string;
  }[] = [];

  if (isAuthorized('SuperUser')) {
    // If user has SuperUser permissions, make them an array of all possible site/dept pairs
    let siteResponse: ISite[] = await institutionService.getSites();
    let deptResponse: IDepartment[] = await institutionService.getDepartments();
    if (!siteResponse || !deptResponse) {
      console.warn('No Authorized SiteDeptPair on SuperUser!');
      throw 'No Authorized SiteDeptPair on SuperUser!';
    }
    for (const site of siteResponse) {
      for (const department of deptResponse) {
        if (department.institutionId === site.institutionId) {
          authorizedPairs.push({
            site,
            department,
            name: department.name + ' at ' + site.name,
          });
        }
      }
    }
  } else {
    // If they don't have admin permissions, read their permissions to get authorized sites and departments
    for (const perm of permissions) {
      if (perm.permissionTypeName === 'Register') {
        const registerPermission = (perm as RegisterPermission);
        if (registerPermission.siteId === null || registerPermission.departmentId === null) {
          continue;
        }
        const site = await institutionService.getSite(
          registerPermission.siteId
        );
        const department = await institutionService.getDepartment(
          registerPermission.departmentId
        );
        if (!site || !department) {
          console.warn(
            `No pair found for site: ${
              (perm as RegisterPermission).siteId
            }, department: ${(perm as RegisterPermission).siteId}`
          );
          continue;
        }
        authorizedPairs.push({
          site,
          department,
          name: department.name + ' at ' + site.name,
        });
      }
    }
  }
  return authorizedPairs;
}

async function setPermissionsSessionStorage() {
  let x = JSON.parse(sessionStorage.getItem('user') ?? '');
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        mode: 'cors',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + x.token,
      },
      url: ACConfig.apiUrl + '/users/' + x.id + '/permissions',
    });
    sessionStorage.setItem('permissions', JSON.stringify(response.data));
  } catch (error) {
    console.error(error);
  }
}
