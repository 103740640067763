import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { IProvider } from '../../types/DTOTypes';
import { IUser } from '../../types/DTOTypes';
import { userService } from '../../services/UserServices';
import { providerService } from '../../services/ProviderServices';
import { InfoDialog } from '../InfoDialog';

interface IProps {
  user?: IUser;
  onSuccess?: Function;
}

interface IState {
  providers?: IProvider[];
  selectedProvider?: IProvider | null;
  failure?: boolean;
  success?: boolean;
  exists?: boolean;
}

export class GrantViewCertificateProviderPermission extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      providers: [],
      selectedProvider: null,
      failure: false,
      success: false,
      exists: false,
    };
  }

  componentDidMount = async () => {
    const providers =
      (await providerService.getProvidersToView()) as IProvider[];

    this.setState({
      providers,
    });
  };

  onHide = () => {
    this.setState({
      selectedProvider: null,
      failure: false,
      success: false,
      exists: false,
    });
  };

  submit = async () => {
    const { selectedProvider } = this.state;
    const { user } = this.props;

    if (user && selectedProvider) {
      const result = await userService.addViewCertificateProviderPermission(
        user.id,
        selectedProvider.id
      );
      switch (result) {
        case 201:
          this.setState({ success: true });
          this.props.onSuccess?.();
          break;
        case 409:
          this.setState({ exists: true });
          break;
        default:
          this.setState({ failure: true });
      }
    }
  };

  render() {
    const {
      selectedProvider,
      providers = [],
      exists,
      success,
      failure,
    } = this.state;
    const { user } = this.props;
    return (
      <div>
        <h3>Grant View Certificate Permission</h3>
        <p>
          Grant a user permission to view documents and certifications for a
          provider
        </p>
        <label>Choose a Provider:</label>
        <Dropdown
          style={{ marginLeft: '1rem' }}
          tooltip="Provider"
          optionLabel="fullNameWDegrees"
          placeholder="Please Select"
          onChange={(e) => {
            this.setState({ selectedProvider: e.value });
          }}
          value={selectedProvider}
          options={providers}
          filter
        />

        <Button
          style={{ marginTop: '1rem', display: 'block' }}
          label="Apply"
          icon="pi pi-check"
          onClick={this.submit}
          disabled={!user || !selectedProvider}
        />
        <InfoDialog
          header="Success!"
          message="Permissions Successfully Updated"
          buttonText="Ok"
          onButtonClick={this.onHide}
          visible={success ?? false}
          closeOnEscape
          onHide={this.onHide}
        />
        <InfoDialog
          header="Already Exists"
          message={`User already has permission to view certificates for ${selectedProvider?.fullNameWDegrees}`}
          buttonText="Ok"
          onButtonClick={this.onHide}
          visible={exists ?? false}
          closeOnEscape
          onHide={this.onHide}
        />
        <InfoDialog
          header="Error"
          message="An error occurred, permissions were not updated"
          buttonText="Ok"
          onButtonClick={this.onHide}
          visible={failure ?? false}
          closeOnEscape
          onHide={this.onHide}
        />
      </div>
    );
  }
}
