import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { IInstitution } from '../../types/DTOTypes';
import { institutionService } from '../../services/InstitutionServices';
import { Accordion, AccordionTab } from 'primereact/accordion';

interface IProps {
  onSuccess?: Function;
  onFailure?: Function;
  institution: IInstitution;
}

interface IState {
  name: string;
  city: string;
  state: string;
  zipCode: string;
}

export class AddSite extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      name: '',
      city: '',
      state: '',
      zipCode: '',
    };
  }

  handleClick = async () => {
    const { name, city, state, zipCode } = this.state;

    const result = await institutionService.addSite(
      name.trim(),
      state.trim(),
      city.trim(),
      zipCode.trim(),
      this.props.institution
    );

    if (result) {
      if (this.props.onSuccess) {
        this.props.onSuccess();
      }
    } else {
      if (this.props.onFailure) {
        this.props.onFailure();
      }
    }
    this.setState({
      name: '',
      city: '',
      state: '',
      zipCode: '',
    });
  };

  handleChange = <P extends keyof IState>(prop: P, value: IState[P]) => {
    this.setState({ ...this.state, [prop]: value });
  };

  render() {
    const style = { display: 'block', marginTop: '20px', width: '175px' };
    const { name, city, state, zipCode } = this.state;

    return (
      <Accordion>
        <AccordionTab header="Add New Site">
          <div style={{ display: 'inline-block' }}>
            <span className="p-float-label">
              <InputText
                style={style}
                id="name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleChange('name', e.target.value);
                }}
              ></InputText>
              <label htmlFor="name">Site Name</label>
            </span>
          </div>
          <div style={{ display: 'inline-block' }}>
            <span className="p-float-label">
              <InputText
                style={style}
                id="city"
                value={city}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleChange('city', e.target.value);
                }}
              ></InputText>
              <label htmlFor="city">Site City</label>
            </span>
          </div>
          <div style={{ display: 'inline-block' }}>
            <span className="p-float-label">
              <InputText
                style={style}
                id="state"
                value={state}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleChange('state', e.target.value);
                }}
              ></InputText>
              <label htmlFor="state">Site State</label>
            </span>
          </div>
          <div style={{ display: 'inline-block' }}>
            <span className="p-float-label">
              <InputText
                style={style}
                id="zipCode"
                value={zipCode}
                keyfilter="pint"
                maxLength={5}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleChange('zipCode', e.target.value);
                }}
              ></InputText>
              <label htmlFor="zipCode">Site Zip Code</label>
            </span>
          </div>
          <Button
            className="p-button-icon"
            icon="pi pi-check"
            disabled={!(name.trim() && this.props.institution.id !== -1)}
            onClick={this.handleClick}
            label="Add Site"
          />
        </AccordionTab>
      </Accordion>
    );
  }
}
