import React, { Component } from 'react';
import NotFound from '../NotFound';
import { authService } from '../../services/AuthServices';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { IInstitution, IProvider, ISite, IUser } from '../../types/DTOTypes';
import { ProviderTable } from '../../components/ProviderTable';
import { providerService } from '../../services/ProviderServices';
import { institutionService } from '../../services/InstitutionServices';
import { AddInstitution } from '../../components/AddInstitution';
import { InstitutionTable } from '../../components/InstitutionTable';
import { userService } from '../../services/UserServices';
import { UserTable } from '../../components/UserTable';
import { Toast } from 'primereact/toast';
import { AddUsersAndProviders } from '../../components/AddUsersAndProviders';
import { AddProviderCSV } from '../../components/AddProviderCSV';
import { RouteComponentProps } from 'react-router-dom';

interface IState {
  activeIndex?: number;
  providers: IProvider[] | null;
  institutions: IInstitution[] | null;
  users: IUser[] | null;
  sites: ISite[] | null;
}
interface MatchParams {
  activeIndex?: string;
}

interface IProps extends RouteComponentProps<MatchParams> {}

export class Administration extends Component<IProps, IState> {
  toast: Toast | null;

  constructor(props: IProps) {
    super(props);
    const activeIndex = Number.isInteger(this.props.match.params.activeIndex)
      ? Number(this.props.match.params.activeIndex)
      : undefined;
    this.state = {
      providers: null,
      institutions: null,
      users: null,
      sites: null,
      activeIndex,
    };
    this.toast = null;
  }

  async componentDidMount() {
    this.loadProviders();
    this.loadInstitutions();
    this.loadUsers();
    this.loadSites();
  }

  loadSites = async () => {
    this.setState({ sites: null });
    const sites = await institutionService.getSites();
    this.setState({
      sites,
    });
  };

  loadProviders = async () => {
    this.setState({ providers: null });
    var providers = await providerService.getProvidersToView();
    this.setState({
      providers,
    });
  };

  loadInstitutions = async () => {
    this.setState({ institutions: null });
    var institutions = await institutionService.getInstitutions();
    this.setState({
      institutions,
    });
  };

  loadUsers = async () => {
    this.setState({ users: null });
    var users = await userService.getUsers();
    this.setState({
      users,
    });
  };

  institutionOnSuccess = () => {
    this.toast?.show({
      severity: 'success',
      summary: 'Institution Added',
      detail: 'The new institution has successfully been added!',
    });
    this.loadInstitutions();
  };

  institutionOnFailure = () => {
    this.toast?.show({
      severity: 'error',
      summary: 'Error Adding Institution',
      detail: 'An error occurred and the institution could not be added!',
    });
    this.loadInstitutions();
  };

  providerOnSuccess = (message?: string) => {
    this.toast?.show({
      severity: 'success',
      summary: 'Provider(s) Added',
      detail: message || 'The new provider(s) have successfully been added!',
    });
    this.loadProviders();
  };

  providerOnFailure = (message?: string) => {
    this.toast?.show({
      severity: 'error',
      summary: 'Error Adding Provider(s)',
      detail:
        message || 'An error occurred and the provider(s) could not be added!',
    });
    this.loadProviders();
  };

  comboOnSuccess = (message: string) => {
    this.toast?.show({
      severity: 'success',
      summary: 'User/Provider Added',
      detail: message,
    });
    this.loadUsers();
    this.loadProviders();
  };

  comboOnFailure = (message: string) => {
    this.toast?.show({
      severity: 'error',
      summary: 'Error Adding User/Provider',
      detail: message,
    });
    this.loadUsers();
    this.loadProviders();
  };

  render() {
    const { institutions, users, providers, sites, activeIndex } = this.state;
    const isAuthZ = authService.isAuthorized('SuperUser');

    if (!isAuthZ) {
      return <NotFound />;
    }

    return (
      <div className="flexi-grid p-col-12 card">
        <Toast ref={(el) => (this.toast = el)} />
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => this.setState({ activeIndex: e.index })}
        >
          {/* Manage Institutions */}
          <TabPanel header={<Button label={'Manage Institutions'} />}>
            <AddInstitution
              onSuccess={this.institutionOnSuccess}
              onFailure={this.institutionOnFailure}
            />
            <InstitutionTable
              loading={!institutions}
              institutions={institutions ?? []}
              isSuperUser={authService.isAuthorized('SuperUser')}
            />
          </TabPanel>
          {/* Manage Users */}
          <TabPanel header={<Button label={'Manage Users'} />}>
            <UserTable
              loading={!users}
              users={users ?? []}
              isSuperUser={authService.isAuthorized('SuperUser')}
              onEdit={this.loadUsers}
            />
          </TabPanel>
          {/* Manage Providers */}
          <TabPanel header={<Button label={'Manage Providers'} />}>
            <AddProviderCSV
              onSuccess={this.providerOnSuccess}
              onFailure={this.providerOnFailure}
            />
            <ProviderTable
              loading={!providers}
              providers={providers ?? []}
              onEdit={this.loadProviders}
            />
          </TabPanel>
          {/* Add Users & Providers */}
          <TabPanel header={<Button label={'Add Users & Providers'} />}>
            <AddUsersAndProviders
              onSuccess={this.comboOnSuccess}
              onFailure={this.comboOnFailure}
              sites={sites ?? []}
            />
          </TabPanel>
        </TabView>
      </div>
    );
  }
}
