import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { IInstitution } from '../../types/DTOTypes';
import { institutionService } from '../../services/InstitutionServices';
import { Accordion, AccordionTab } from 'primereact/accordion';

interface IProps {
  onSuccess?: Function;
  onFailure?: Function;
  institution: IInstitution;
}

interface IState {
  name: string;
}

export class AddDepartment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      name: '',
    };
  }

  handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const result = await institutionService.addDepartment(
      this.state.name.trim(),
      this.props.institution
    );
    if (result) {
      if (this.props.onSuccess) {
        this.props.onSuccess();
      }
    } else {
      if (this.props.onFailure) {
        this.props.onFailure();
      }
    }
    this.setState({
      name: '',
    });
  };

  handleChange = <P extends keyof IState>(prop: P, value: IState[P]) => {
    this.setState({ ...this.state, [prop]: value });
  };

  render() {
    const style = { display: 'block', marginTop: '20px', width: '175px' };

    return (
      <Accordion>
        <AccordionTab header="Add New Department">
          <div style={{ display: 'inline-block' }}>
            <span className="p-float-label">
              <InputText
                style={style}
                id="name"
                value={this.state.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleChange('name', e.target.value);
                }}
              ></InputText>
              <label htmlFor="name">Department Name</label>
            </span>
          </div>
          <Button
            className="p-button-icon"
            icon="pi pi-check"
            disabled={
              !(this.state.name.trim() && this.props.institution.id !== -1)
            }
            onClick={this.handleClick}
            label="Add Department"
          />
        </AccordionTab>
      </Accordion>
    );
  }
}
