import { Fieldset } from 'primereact/fieldset';
import { Component } from 'react';
import { FloatLabel } from '../FloatLabel';
import { InputText } from 'primereact/inputtext';

export interface NameData {
  firstName: string;
  middleName: string;
  lastName: string;
  valid: boolean;
}

export const EmptyNameData: NameData = {
  firstName: '',
  middleName: '',
  lastName: '',
  valid: false,
};

interface IProps {
  nameData: NameData;
  setNameData: (nameData: NameData) => void;
}

export class AddNameDetails extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  validate = (nameData: NameData): boolean => {
    const firstName = nameData.firstName.trim();
    const lastName = nameData.lastName.trim();
    return firstName.length > 0 && lastName.length > 0;
  };

  handleChangeName = (
    name: keyof NameData,
    value: NameData[keyof NameData]
  ) => {
    const nameData: NameData = {
      ...this.props.nameData,
      [name]: value,
    };
    nameData.valid = this.validate(nameData);
    this.props.setNameData(nameData);
  };

  render() {
    const { nameData } = this.props;
    return (
      <Fieldset style={{ display: 'block', marginTop: '20px' }} legend="Shared">
        <FloatLabel label="First Name">
          <InputText
            id="firstName"
            aria-label="First Name"
            value={nameData.firstName}
            onChange={(event) =>
              this.handleChangeName('firstName', event.target.value)
            }
          />
        </FloatLabel>
        <FloatLabel label="Middle Name (Optional)">
          <InputText
            id="middleName"
            aria-label="Middle Name (optional)"
            value={nameData.middleName}
            onChange={(event) =>
              this.handleChangeName('middleName', event.target.value)
            }
          />
        </FloatLabel>
        <FloatLabel label="Last Name">
          <InputText
            id="lastName"
            aria-label="Last Name"
            value={nameData.lastName}
            onChange={(event) =>
              this.handleChangeName('lastName', event.target.value)
            }
          />
        </FloatLabel>
      </Fieldset>
    );
  }
}
